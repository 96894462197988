import React from 'react';
import { Field } from '../../../components/field';
import { Head } from '../../../components/head';
import { Input, useForm } from '../../../hooks/form';
import { useModal } from '../../../hooks/modal/Modal';
import { useNotification } from '../../../hooks/notification/Notification';
import Api from '../../../services/Api';

function UpdateInformations({ observer, onUpdated }) {
  const notification = useNotification();
  const modal = useModal();
  const form = useForm(observer.user, (data) => {
    Api.post(`/observers/${observer.id}/informations`, data)
      .then(response => {
        notification.notify('Les informations ont été mise à jour !');
        onUpdated(response.data);
        modal.close();
      })
      .catch(() => {
        alert('Une erreur est survenue');
      });
  });

  return (
    <div className="ressource-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Modifier les informations" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        <Input label="Prénom" {...form.field('firstname')} />
        <br/>
        <Input label="Nom" {...form.field('lastname')} />
        <br/>
        <Input label="Email" {...form.field('email')} />
        <br/>
        <button className="btn full">Enregistrer</button>
      </form>
    </div>
  );
}

export default function ObserverRessourceOverview({ route, observer, setObserver }) {
  const modal = useModal();

  function onUpdated(observer) {
    setObserver(observer);
  }

  return (
    <div className="ressource-page" style={{ padding: '20px' }}>
      <h3 style={{ margin: '0px', marginBottom: '10px' }}>
        Informations
        <small style={{ color: '#007bff', cursor: 'pointer', fontWeight: 500 }} onClick={() => modal.open(<UpdateInformations observer={observer} onUpdated={observer => onUpdated(observer)} />)}> • modifier</small>
      </h3>
      <Field label="Prénom">
        {observer.user.firstname || 'n/a'}
      </Field>
      <Field label="Nom">
        {observer.user.lastname.toUpperCase() || 'n/a'}
      </Field>
      <Field label="Email">
        {observer.user.email || 'n/a'}
      </Field>
    </div>
  );
}
