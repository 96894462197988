import React from 'react';
import { Link } from '../../hooks/router';

export default function Tab({ to, params, routeName, route, children, onClick, style }) {
  if (to === undefined) {
    return (
      <div style={style} onClick={onClick} className={`tab-component${route.name === routeName || route.name === routeName.slice(0, -1) ? ' active' : ''}`}>
        {children}
      </div>
    );
  }
  return (
    <Link style={style} to={to} className={`tab-component${route.name === routeName || route.name === routeName.slice(0, -1) ? ' active' : ''}`} params={params}>{children}</Link>
  )
};
