import React, { useState } from 'react';

import { Route, useRouter } from '../../hooks/router';

import { Tabs, Tab } from '../../components/tabs';
import { Head } from '../../components/head';
import { Loader } from '../../components/loader';
import useRessource from '../../hooks/ressource/useRessource';
import { useNotification } from '../../hooks/notification/Notification';
import Api from '../../services/Api';

export default function ClasseRessource({ route }) {
  const router = useRouter();
  const notification = useNotification();
  const { classe, isLoading, set } = useRessource('classe', route.params.id);
  const [ isDuplicating, setIsDuplicating ] = useState(false);

  function duplicate() {
    if (window.confirm('Êtes-vous certains de dupliquer ce cours ?')) {
      setIsDuplicating(true);
      Api.post(`/classes/${classe.id}/duplicate`)
        .then(response => {
          setIsDuplicating(false);
          notification.notify('Le cours a été dupliqué !');
          router.goTo('classe', { id: response.data.id });
        }).catch(() => {
          alert('Une erreur est survenue');
        });
    }
  }

  if (isDuplicating) {
    return (
      <div className="ressource-page" style={{ alignItems: 'center' }}>
        <Loader />
        <div>Duplication en cours ...</div>
      </div>
    );
  }

  return (
    <div className="ressource-page">
      <Head prefix="Cours" title={`${classe.name}`} isLoading={isLoading}>
        <button disabled={isLoading} className="btn" onClick={() => duplicate()}>Dupliquer</button>
      </Head>
      <Tabs route={route.subRoute} params={{ id: route.params.id }}>
        <Tab to="classe" routeName="_default">Aperçu</Tab>
        <Tab to="classe/students" routeName="students">Étudiants</Tab>
        <Tab to="classe/registrations" routeName="registrations">Inscriptions</Tab>
      </Tabs>
      { isLoading ? <Loader /> : <Route route={route.subRoute} classe={classe} setClasse={set} /> }
    </div>
  );
}
