import React from 'react';

import { Link } from '../../hooks/router';
import { Input } from '../../hooks/form';

import { Head } from '../../components/head';
import { List, useList } from '../../hooks/list';

export default function PayersList() {
  const { filters, items, isLoading, load } = useList('payers', {
    search: '',
  });

  return (
    <div className="list-page">
      <Head title="Payeurs">
        <Link to="createPayer" className="btn">Nouveau payeur institution</Link>
      </Head>
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
      </form>
      <List onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Nom', sort: 'lastname' }, { name: 'Email' }, { name: 'type' }]}>
        { items.map(payer => (
          <Link key={payer.id} className="item" to="payer" params={{ id: payer.id }}>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '10px', borderRadius: '10px', background: 'green', width: '10px', height: '10px', flex: '0 0 auto' }}></div>
              <span>
                {payer.companyName || (`${payer.user.firstname} ${payer.user.lastname.toUpperCase()}`)}
              </span>
            </div>
            <div className="prop">
              {payer.user.email}
            </div>
            <div className="prop">
              {{ company: 'Institution', people: 'Personne', student: 'Étudiant' }[payer.type]}
            </div>
          </Link>
        ))}
      </List>
    </div>
  );
};