import { useState } from "react";

export default function useSelection(defaultSelection = [], generateUniqId, selectable) {
  const [selection, setSelection] = useState(defaultSelection);

  function select(item) {
    if (selectable && !selectable(item)) {
      return;
    }
    setSelection(prevSelection => {
      const id = generateUniqId ? generateUniqId(item) : item.id;
      const index = prevSelection.findIndex(selectedItem => (generateUniqId ? generateUniqId(selectedItem) : selectedItem.id) === id);
      if (index === -1) {
        return [...prevSelection, item];
      } else {
        const tmp = [...prevSelection];
        tmp.splice(index, 1);
        return tmp;
      }
    });
  }

  function selectAll(items) {
    if (selection.length >= items.filter(item => selectable ? selectable(item) : item).length) {
      setSelection([]);
    } else {
      setSelection(items.filter(item => selectable ? selectable(item) : item));
    }
  }

  function unSelectAll() {
    setSelection([]);
  }

  function isSelected(item) {
    return selection.findIndex(selectedItem => (generateUniqId ? generateUniqId(selectedItem) : selectedItem.id) === (generateUniqId ? generateUniqId(item) : item.id)) !== -1;
  }

  function isAllSelected(items) {
    return items.filter(item => selectable ? selectable(item) : item).length > 0 && items.filter(item => selectable ? selectable(item) : item).length === selection.length;
  }

  /**
   * Used by <List> to disbaled the <Checkbox> on header
   * @param {*} items 
   */
  function isOneSelectable(items) {
    return items.filter(item => selectable ? selectable(item) : item).length > 0;
  }

  return {
    items: selection,
    select,
    selectAll,
    unSelectAll,
    isSelected,
    isAllSelected,
    isOneSelectable,
    isSelectable: (item) => selectable(item)
  };
}