import React from 'react';
import { Link, queryToUrl } from '../../../hooks/router';
import { Input } from '../../../hooks/form';

import { List, useList } from '../../../hooks/list';
import Api from '../../../services/Api';
import { useAuth } from '../../../hooks/auth/Auth';

export default function ClasseRessourceStudents({ classe }) {
  const auth = useAuth();
  const { filters, items, isLoading, load } = useList(`classes/${classe.id}/students`, {
    search: '',
  });
  const students = items.reduce((a, student) => {
    if (!a.some(item => item.id === student.id)) {
      a.push(student);
    }
    return a;
  }, []);


  return (
    <div className="list-page">
      <form className="filters" onSubmit={filters.onSubmit}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
        <div className="filter">
          <a href={`${Api.API_URL}/classes/${classe.id}/students.pdf${queryToUrl({ access_token: auth.token })}`} target="_blank" rel="noreferrer noopener" className="btn" style={{ display: 'block' }}>Fiche d'émargement</a>
        </div>
        <div className="filter">
          <a href={`${Api.API_URL}/classes/${classe.id}/students.xlsx${queryToUrl({ access_token: auth.token })}`} target="_blank" rel="noreferrer noopener" className="btn" style={{ display: 'block' }}>Export XLSX</a>
        </div>
      </form>
      <List onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Étudiant', sort: 'lastname' }, { name: 'Email' }]}>
        {
          students.map(student => (
            <Link key={student.id} className="item" to="student" params={{ id: student.id }}>
              <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
                {student.user.firstname} {student.user.lastname.toUpperCase()}
              </div>
              <div className="prop">
                {student.user.email}
              </div>
            </Link>
          ))
        }
      </List>
    </div>
  );
};