import React from 'react';
import { Field } from '../../../components/field';
import { Head } from '../../../components/head';
import { ProfilePicture } from '../../../components/profilePicture';
import { Input, Select, useForm } from '../../../hooks/form';
import { useModal } from '../../../hooks/modal/Modal';
import { useNotification } from '../../../hooks/notification/Notification';
import Api from '../../../services/Api';

function UpdateInformations({ professor, onUpdated }) {
  const notification = useNotification();
  const modal = useModal();
  const form = useForm({ ...professor.user, ...professor }, (data) => {
    Api.post(`/professors/${professor.id}/informations`, data)
      .then(response => {
        notification.notify('Les informations ont été mise à jour !');
        onUpdated(response.data);
        modal.close();
      })
      .catch(() => {
        alert('Une erreur est survenue');
      });
  });

  return (
    <div className="ressource-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Modifier les informations" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        <Select label="Civilité" noSearch {...form.field('civility')}>
          <option value={null}>Non définie</option>
          <option value="mr">M.</option>
          <option value="mrs">Mme</option>
          <option value="father">Père</option>
          <option value="sister">Soeur</option>
          <option value="brother">Frère</option>
          <option value="mgr">Mgr</option>
        </Select>
        <br/>
        <Input label="Prénom" {...form.field('firstname')} />
        <br/>
        <Input label="Nom" {...form.field('lastname')} />
        <br/>
        <Input label="Email" {...form.field('email')} />
        <br/>
        <Input label="Téléphone" {...form.field('phone')} />
        <br/>
        <Input label="Fonction" {...form.field('function')} />
        <br/>
        <Input label="Url profil" {...form.field('profileUrl')} />
        <br/>
        <button className="btn full">Enregistrer</button>
      </form>
    </div>
  );
}

export default function ProfessorRessourceOverview({ route, professor, setProfessor }) {
  const modal = useModal();

  function onUpdated(professor) {
    setProfessor(professor);
  }

  return (
    <div className="ressource-page" style={{ padding: '20px' }}>
      <h3 style={{ margin: '0px', marginBottom: '10px' }}>
        Informations
        <small style={{ color: '#007bff', cursor: 'pointer', fontWeight: 500 }} onClick={() => modal.open(<UpdateInformations professor={professor} onUpdated={professor => onUpdated(professor)} />)}> • modifier</small>
      </h3>
      <Field label="Photo">
        <ProfilePicture profilePicture={professor.profilePicture} onUploaded={professor => setProfessor(professor)} url={`/professors/${professor.id}/profile-picture`} />
      </Field>
      <Field label="Civilité">
        {professor.civility ? {
          'mr': 'M.',
          'mrs': 'Mme',
          'father': 'Père',
          'sister': 'Soeur',
          'brother': 'Frère',
          'mgr': 'Mgr',
        }[professor.civility] : 'n/a'}
      </Field>
      <Field label="Prénom">
        {professor.user.firstname || 'n/a'}
      </Field>
      <Field label="Nom">
        {professor.user.lastname.toUpperCase() || 'n/a'}
      </Field>
      <Field label="Email">
        {professor.user.email || 'n/a'}
      </Field>
      <Field label="Téléphone">
        {professor.phone || 'n/a'}
      </Field>
      <Field label="Fonction">
        {professor.function || 'n/a'}
      </Field>
      <Field label="Url profil">
        {professor.profileUrl || 'n/a'}
      </Field>
    </div>
  );
}
