import React from 'react';

import { Link } from '../../../hooks/router';
import { Input } from '../../../hooks/form';

import { List, useList } from '../../../hooks/list';

export default function PackageRessourceRegistrations({ classePackage }) {
  const { filters, items, isLoading, load } = useList(`packages/${classePackage.id}/registrations`, {
    search: '',
  });

  return (
    <div className="list-page">
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
      </form>
      <List onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Étudiant' }, { name: 'Statut' }]}>
        { items.map(registration => (
          <Link key={registration.id} className="item" to="registration" params={{ id: registration.id }}>
            <div className="prop">
              {registration.student ? `${registration.student.user.firstname} ${registration.student.user.lastname.toUpperCase()}` : 'n/a'}
            </div>
            <div className="prop">
              {{
                'pending': 'En attente',
                'validated': 'Validée',
                'pendingPayment': 'En attente du paiement',
                'canceled': 'Annulée',
                'draft': 'Broullion',
              }[registration.status]}
            </div>
          </Link>
        ))}
      </List>
    </div>
  );
};