import React from 'react';

import { Head } from '../../components/head';
import { Input, Select, Textarea, useForm } from '../../hooks/form';
import { useNotification } from '../../hooks/notification/Notification';
import { useRouter } from '../../hooks/router';
import Api from '../../services/Api';

export default function ProductCreate() {
  const router = useRouter();
  const notification = useNotification();
  const form = useForm({
    type: 'other',
    name: '',
    description: '',
    prix: 10,
    department: null,
    code: '',
  }, (data) => {
    Api.post('/products', data)
      .then(response => {
        notification.notify('Le produit a bien été créé !');
        router.goTo('product', { id: response.data.id });
      }).catch((error) => {
        alert('Une erreur est survenue');
      })
  });

  return (
    <div className="ressource-page">
      <Head noTabs title="Nouveau produit" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px', maxWidth: '450px' }}>
        <Input label="Code" {...form.field('code')} explain="Sera utilisé sur les factures" />
        <br/>
        <Input label="Nom" {...form.field('name')} />
        <br/>
        <Textarea label="Description" {...form.field('description')} />
        { form.data.type === 'audio' &&
          <>
            <br/>
            <Input label="Url" {...form.field('url')} />
          </>
        }
        <br/>
        <Input type="number" label="Prix" {...form.field('price', { type : 'number' })} suffix="€" />
        <br />
        <Select label="Département programmateur" noSearch {...form.field('department')}>
          <option value={null}>Aucun département</option>
          <option value="public">Cours Publics</option>
          <option value="e-learning">E-learning</option>
          <option value="fnd">FND</option>
          <option value="issr">ISSR</option>
          <option value="fr">FEB</option>
          <option value="ccdej">CCDEJ</option>
          <option value="fcf">FCF</option>
          <option value="meal">Repas</option>
          <option value="other">Autre</option>
        </Select>
        <br />
        <button className="btn">Enregistrer</button>
      </form>
    </div>
  );
}
