import React from 'react';
import moment from 'moment';

import { Link } from '../../../hooks/router';
import { Input } from '../../../hooks/form';

import { List, useList } from '../../../hooks/list';

export default function ProfessorRessourceClasses({ professor }) {
  const { filters, items, isLoading, load } = useList(`professors/${professor.id}/classes`, {
    search: '',
  });

  return (
    <div className="list-page">
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
      </form>
      <List onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Nom', sort: 'name' }, { name: 'Créée le', sort: 'createdAt' }, { name: 'Année' }]}>
        { items.map(classe => (
          <Link key={classe.id} className="item" to="classe" params={{ id: classe.id }}>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '10px', borderRadius: '10px', background: 'green', width: '10px', height: '10px', flex: '0 0 auto' }}></div>
              <span>
                {classe.name}
              </span>
            </div>
            <div className="prop">
              {moment(classe.createdAt).format('DD/MM/YYYY HH:mm:ss')}
            </div>
            <div className="prop">
              {classe.year ? `${classe.year} - ${classe.year+1}` : 'n/a'}
            </div>
          </Link>
        ))}
      </List>
    </div>
  );
};