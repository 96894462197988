import React, { useRef, useEffect } from 'react';

export default function Textarea({ label, name, errors, noMinHeight, value, ...fieldProps }) {
  const inputRef = useRef();

  useEffect(() => {
    function autoresize(event) {
      event.target.style.height = 'auto';
      event.target.style.height = event.target.scrollHeight + 2 +'px';
      event.target.scrollTop = event.target.scrollHeight;
      window.scrollTo(window.scrollLeft,(event.target.scrollTop + event.target.scrollHeight));
    }

    const ref = inputRef.current; // eslint tell me to do this for the clean up callback
    ref.addEventListener('input', autoresize, false);
    return () => {
      ref.removeEventListener('input', autoresize, false);
    }
  }, []);

  return (
    <>
      { label &&
        <label htmlFor="">{label}</label>
      }
      <textarea ref={inputRef} {...fieldProps} value={value ? value : ''} />
      {errors && <div className="error-message">{errors.join('<br>')}</div>}
    </>
  )
};
