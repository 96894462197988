import React from 'react';

import { Route } from '../../hooks/router';

import { Head } from '../../components/head';
import { Loader } from '../../components/loader';
import useRessource from '../../hooks/ressource/useRessource';
import { Tab, Tabs } from '../../components/tabs';

export default function ProfessorRessource({ route }) {
  const { product, isLoading, set } = useRessource('product', route.params.id);

  return (
    <div className="ressource-page">
      <Head prefix="Produit" title={product.name} isLoading={isLoading} />
      <Tabs route={route.subRoute} params={{ id: route.params.id }}>
        <Tab to="product" routeName="_default">Aperçu</Tab>
        <Tab to="product/bills" routeName="bills">Achats</Tab>
      </Tabs>
      { isLoading ? <Loader /> : <Route route={route.subRoute} product={product} setProduct={set} /> }
    </div>
  );
}
