import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Loader } from '../../components/loader';

import './select.scss';

function isDescendant(parents, child) {
  let res = false;
  parents.forEach(function(element) {
    let node = child.parentNode;
    while (node != null) {
      if (node == element) {
        res = true;
      }
      node = node.parentNode;
    }
  });
  return res;
}

function affiliateData(children) {
  if (Array.isArray(children)) {
    return children.map(d => ({ _type: 'html', value: d.props.value, label: d.props.children }));
  }
}

function ShowValue({ multiple, inline, value, data, children, onUnselect, nullable, type }) {
  return (
    <>
      { multiple ? (
        <>
          { !inline ? (
            <>
              {
                value.map(item => (
                  <div key={item.id} className="value">
                    <div className="item">
                      {typeof children === 'function' ? children(item) : (type === 'html' ? data.find(i => i.value === item).label : item)}
                    </div>
                    <div className="unselect" onClick={event => onUnselect(item, event)}>
                      <svg version="1.1" viewBox="0 0 20 20" x="0px" y="0px"><g><path d="M8.5 10L4 5.5 5.5 4 10 8.5 14.5 4 16 5.5 11.5 10l4.5 4.5-1.5 1.5-4.5-4.5L5.5 16 4 14.5 8.5 10z"></path></g></svg>
                    </div>
                  </div>
                ))
              }
            </>
          ) : (
            <div className="value">
              <div className="item">
                ({value.length}) {value.join(', ')}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="value">
          <div className="item">
            {typeof children === 'function' ? children(value) : (type === 'html' ? (data.find(i => i.value === value) ? data.find(i => i.value === value).label : 'Aucun') : value)}
          </div>
          { nullable &&
            <div className="unselect" onClick={event => onUnselect(null, event)}>
              <svg version="1.1" viewBox="0 0 20 20" x="0px" y="0px"><g><path d="M8.5 10L4 5.5 5.5 4 10 8.5 14.5 4 16 5.5 11.5 10l4.5 4.5-1.5 1.5-4.5-4.5L5.5 16 4 14.5 8.5 10z"></path></g></svg>
            </div>
          }
          { !nullable &&
            <div className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 490.688 490.688" xmlSpace="preserve">
                <path style={{  fill: '#FFC107' }} d="M472.328,120.529L245.213,347.665L18.098,120.529c-4.237-4.093-10.99-3.975-15.083,0.262  c-3.992,4.134-3.992,10.687,0,14.82l234.667,234.667c4.165,4.164,10.917,4.164,15.083,0l234.667-234.667  c4.237-4.093,4.354-10.845,0.262-15.083c-4.093-4.237-10.845-4.354-15.083-0.262c-0.089,0.086-0.176,0.173-0.262,0.262  L472.328,120.529z"/>
                <path d="M245.213,373.415c-2.831,0.005-5.548-1.115-7.552-3.115L2.994,135.633c-4.093-4.237-3.975-10.99,0.262-15.083  c4.134-3.992,10.687-3.992,14.82,0l227.136,227.115l227.115-227.136c4.093-4.237,10.845-4.354,15.083-0.262  c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262L252.744,370.279  C250.748,372.281,248.039,373.408,245.213,373.415z"/>
              </svg>
            </div>
          }
        </div>
      )}
    </>
  );
}

export default function NewSelect({ label, name, children, errors, noSearch, nullable, load, value, onChange, multiple, inline, noSelectionMessage = 'Aucun', ...fieldProps }) {
  const [data, setData] = useState(affiliateData(children));
  const [type] = useState(Array.isArray(children) ? 'html' : 'object');
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const inputSelectRef = useRef();

  useEffect(() => {
    if (load) {
      (async () => {
        setIsLoading(true);
        const response = await load({ page: 1, search });
        setData(response.items.map(item => ({ _type: 'object', value: item})));
        setIsLoading(false);
      })();
    }
  }, [search]);

  useEffect(() => {
    if (isFocused) {
      document.addEventListener('click', memoizedCallback);
    } else {
      document.removeEventListener('click', memoizedCallback);
    }
  }, [isFocused]);

  // use to keep the function instance to be able to remove as listener from the DOM
  const memoizedCallback = useCallback((event) => {
    if (!isDescendant(document.querySelectorAll('.select-component .popin'), event.target)) {
      setIsFocused(false);
    }
  }, []);

  function onFocus(event) {
    event.stopPropagation();
    event.preventDefault();
    setIsFocused(prevState => !prevState);
  }

  function onSelect(item, event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (multiple) {
      if (!isSelected(item)) {
        onChange(prevItems => [...prevItems, item]);
      }
    } else {
      onChange(item);
      setIsFocused(false);
    }
  }

  function onUnselect(item, event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (multiple) {
      onChange(prevItems => {
        return prevItems.filter(i => {
          if (typeof item === 'object' && item !== null) {
            if (i === null) {
              return false;
            }
            return item.id !== i.id;
          } else {
            return item !== i;
          }
        });
      })
    } else {
      onChange(null);
    }
  }

  function onSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    setSearch(event.target.value);
  }

  function isSelected(item) {
    if (multiple) {
      return value.findIndex(val => {
        if (typeof item === 'object' && item !== null) {
          if (val === null) {
            return false;
          }
          return item.id === val.id;
        } else {
          return item === val;
        }
      }) !== -1;
    }
    
    if (typeof item === 'object' && item !== null) {
      if (value === null) {
        return false;
      }
      return item.id === value.id;
    } else {
      return item === value;
    }
  }

  function hasValue() {
    return ( 
      !multiple
      && value !== undefined
      && (
        (value === null && data && data.findIndex(({ value }) => value === null) !== -1)
        || (value !== null)
      )
    )
    || (multiple && value.length > 0);
  }
  
  return (
    <>
      { label &&
        <label htmlFor="">{label}</label>
      }
      <div className={`select-component${isFocused ? ' focused' : ''}${inline ? ' inline' : ''}`} onClick={onFocus}>
        { hasValue() ? (
          <ShowValue multiple={multiple} inline={inline} value={value} data={data} children={children} onUnselect={onUnselect} nullable={nullable} type={type} />
        ) : (
          <div className="value">
            <div className="item">
              {noSelectionMessage}
            </div>
            <div className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 490.688 490.688" xmlSpace="preserve">
                <path style={{  fill: '#FFC107' }} d="M472.328,120.529L245.213,347.665L18.098,120.529c-4.237-4.093-10.99-3.975-15.083,0.262  c-3.992,4.134-3.992,10.687,0,14.82l234.667,234.667c4.165,4.164,10.917,4.164,15.083,0l234.667-234.667  c4.237-4.093,4.354-10.845,0.262-15.083c-4.093-4.237-10.845-4.354-15.083-0.262c-0.089,0.086-0.176,0.173-0.262,0.262  L472.328,120.529z"/>
                <path d="M245.213,373.415c-2.831,0.005-5.548-1.115-7.552-3.115L2.994,135.633c-4.093-4.237-3.975-10.99,0.262-15.083  c4.134-3.992,10.687-3.992,14.82,0l227.136,227.115l227.115-227.136c4.093-4.237,10.845-4.354,15.083-0.262  c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262L252.744,370.279  C250.748,372.281,248.039,373.408,245.213,373.415z"/>
              </svg>
            </div>
          </div>
        )}
        { isFocused &&
          <>
            <div className="popin" ref={inputSelectRef}>
              { hasValue() ? (
                <ShowValue multiple={multiple} inline={inline} value={value} data={data} children={children} onUnselect={onUnselect} nullable={nullable} type={type} />
              ) : (
                <div className="value">
                  <div className="item">
                    {noSelectionMessage}
                  </div>
                </div>
              )}
              { ( noSearch === undefined || noSearch === false ) &&
                <input autoFocus type="text" value={search} onChange={onSearch} placeholder="Rechercher.." onClick={event => event.stopPropagation()} />
              }
              { isLoading ? (
                <div className="loading">
                  <Loader />
                </div>
              ) : (
                <>
                  { data.length > 0 ? (
                    <div className="data">
                      {data.map(({ type, value: dataValue, label }) => typeof children === 'function' ? <React.Fragment key={dataValue.id}>{children(dataValue, event => onSelect(dataValue, event))}</React.Fragment> : (
                        <div key={dataValue} className={`value${isSelected(dataValue) ? ' selected' : ''}`} onClick={event => onSelect(dataValue, event)}>
                          <div className="dataValue">
                            {label}
                          </div>
                          { multiple && isSelected(dataValue) &&
                            <div className="unselect" onClick={event => onUnselect(dataValue, event)}>
                              <svg version="1.1" viewBox="0 0 20 20" x="0px" y="0px"><g><path d="M8.5 10L4 5.5 5.5 4 10 8.5 14.5 4 16 5.5 11.5 10l4.5 4.5-1.5 1.5-4.5-4.5L5.5 16 4 14.5 8.5 10z"></path></g></svg>
                            </div>
                          }
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="no-item">
                      Aucun résultat
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        }
      </div>
      { errors && <div className="error-message">{Array.isArray(errors) ? errors.join('<br>') : errors}</div> }
    </>
  )
};
