import { useEffect, useState } from "react";

import Api from "../../services/Api";

export default function useRessource(entityName, id, options = {}) {
  const [isLoading, setIsLoading] = useState(true);
  const [entity, setEntity] = useState({});

  useEffect(() => {
    const source = Api.createRequestToken();
    (async () => {
      setIsLoading(true);
      setEntity((await Api.findOne(entityName, id, { source }))[entityName]);
      setIsLoading(false);
    })();

    return () => {
      source.cancel();
    }
  }, [id]);

  return {
    [options.entityReturnName || entityName]: entity,
    isLoading,
    set: setEntity
  };
}