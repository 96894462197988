import React, { useEffect, useState, useRef, useCallback } from 'react';
import moment from 'moment';

function isDescendant(parents, child) {
  let res = false;
  parents.forEach(function (element) {
    let node = child.parentNode;
    while (node != null) {
      if (node == element) {
        res = true;
      }
      node = node.parentNode;
    }
  });
  return res;
}

function DateRangeMonth({ year, month, selection, onSelect }) {
  let firstDay = parseInt(moment(`${month}/${year}`, 'MM/YYYY').startOf('month').format('E'), 10) - 1;

  function daysInMonth(iMonth, iYear) {
    return parseInt(moment(`${iMonth}/${iYear}`, 'MM/YYYY').endOf('month').format('D'), 10);
  }

  const lines = 6; // If need to have exact lien to fit the mont : Math.ceil((daysInMonth(month, year) + firstDay) / 7);

  function isNotInMonth(wCpt, dCpt) {
    return ((wCpt === 0 && dCpt < firstDay) || (wCpt * 7 + dCpt - firstDay + 1 > daysInMonth(month, year)));
  }

  function isSelected(wCpt, dCpt) {
    const day = moment(`${wCpt * 7 + dCpt - firstDay + 1}/${month}/${year}`, 'DD/MM/YYYY');
    return (moment(selection.from).isSameOrBefore(day) && moment(selection.to).isSameOrAfter(day));
  }

  function isFirst(wCpt, dCpt) {
    const day = moment(`${wCpt * 7 + dCpt - firstDay + 1}/${month}/${year}`, 'DD/MM/YYYY');
    return (moment(selection.from).isSame(day));
  }

  function isLast(wCpt, dCpt) {
    const day = moment(`${wCpt * 7 + dCpt - firstDay + 1}/${month}/${year}`, 'DD/MM/YYYY');
    return (moment(selection.to).isSame(day));
  }

  function onDaySelect(wCpt, dCpt) {
    return (event) => {
      if (isNotInMonth(wCpt, dCpt)) {
        return;
      }
      event.stopPropagation();
      const day = moment(`${wCpt * 7 + dCpt - firstDay + 1}/${month}/${year}`, 'DD/MM/YYYY');
      onSelect(day);
    }
  }

  return (
    <>
      {
        Array.from(Array(lines)).map((i, wCpt) => (
          <div key={wCpt} className="week">
            {
              Array.from(Array(7)).map((i, dCpt) => (
                <div key={dCpt} className={`day${isNotInMonth(wCpt, dCpt) ? ' disabled' : (isSelected(wCpt, dCpt) ? ' selected' : '')}${isFirst(wCpt, dCpt) ? ' first' : ''}${isLast(wCpt, dCpt) ? ' last' : ''}`} onClick={onDaySelect(wCpt, dCpt)}>
                  {!isNotInMonth(wCpt, dCpt) ? (
                    <>
                      {wCpt * 7 + dCpt - firstDay + 1}
                    </>
                  ) : (
                    <>&nbsp;</>
                  )}
                </div>
              ))
            }
          </div>
        ))
      }
    </>
  )
}

export default function DateRangePicker({ label, name, errors, value, onChange, closeOnSelect = true }) {
  let [from, to] = value;
  if (typeof from === 'string') {
    // @example : if value is injected from url
    from = moment(from, 'DD-MM-YYYY');
  }
  if (typeof to === 'string') {
    // @example : if value is injected from url
    to = moment(to, 'DD-MM-YYYY');
  }

  const [showMonth, setShowMonth] = useState(moment(from || undefined));
  const [openDialog, setOpenDialog] = useState(false);

  const move = useRef('start');

  useEffect(() => {
    if (openDialog) {
      document.addEventListener('click', memoizedCallback);
    } else {
      document.removeEventListener('click', memoizedCallback);
    }
  }, [openDialog]);

  // use to keep the function instance to be able to remove as listener from the DOM
  const memoizedCallback = useCallback((event) => {
    if (!isDescendant(document.querySelectorAll('.date-range-picker'), event.target)) {
      setOpenDialog(false);
    }
  }, []);

  function onSelect(date) {
    let newFrom = moment(from || undefined).clone();
    let newTo = moment(to || undefined).clone();

    if (move.current === 'start') {
      newFrom = date;
      newTo = date;
      move.current = 'end';
    } else {
      if (date.isBefore(newFrom)) {
        newTo = newFrom
        newFrom = date;
      } else {
        newTo = date;
      }
      if (closeOnSelect) {
        setOpenDialog(false);
      }
      move.current = 'start';
    }

    onChange([newFrom, newTo]);
  }

  function preSelect(from, to) {
    return (event) => {
      event.preventDefault();
      onChange([from, to]);
      setShowMonth(moment(from));
      if (closeOnSelect) {
        setOpenDialog(false);
      }
    }
  }

  return (
    <>
      {label &&
        <label htmlFor="">{label}</label>
      }
      <div className="date-range-picker">
        <div className="value" onClick={() => setOpenDialog(opened => !opened)} style={{ textAlign: 'center' }}>
          { from === null && to === null ? (
            <>Toute la période</>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 1 }}>{from === null ? <>&infin;</> : moment(from).format('DD/MM/YYYY')}&nbsp;•&nbsp;{to === null ? <>&infin;</> : moment(to).format('DD/MM/YYYY')}</div>
              <div className="icon" onClick={(event) => { event.stopPropagation(); onChange([null, null]); }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-3px', marginBottom: '-3px' }}>
                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M17.25 6.75L6.75 17.25"></path>
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M6.75 6.75L17.25 17.25"></path>
                </svg>
              </div>
            </div>
          )}
        </div>
        {openDialog &&
          <div className="dialog">
            <div className="shortcuts">
              <button className="btn shortcut" onClick={preSelect(moment().startOf('day'), moment().startOf('day'))}>Aujourd'hui</button>
              <button className="btn shortcut" onClick={preSelect(moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').startOf('day'))}>Hier</button>
              <button className="btn shortcut" onClick={preSelect(moment().startOf('isoWeek'), moment().endOf('isoWeek').startOf('day'))}>Cette semaine</button>
              <button className="btn shortcut" onClick={preSelect(moment().startOf('month'), moment().endOf('month').startOf('day'))}>Ce mois</button>
              <div className="separator" />
              <button className="btn shortcut" onClick={preSelect(moment().subtract(7, 'day').startOf('day'), moment().startOf('day'))}>7 derniers jours</button>
              <button className="btn shortcut" onClick={preSelect(moment().subtract(30, 'day').startOf('day'), moment().startOf('day'))}>30 derniers jours</button>
              <div className="extensible" />
            </div>
            <div className="goTo up" onClick={() => setShowMonth(date => date.clone().subtract(1, 'month'))}>
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 256 256" xmlSpace="preserve">
                <polygon points="30.2,207.1 128,109.3 225.8,207.1 256,176.9 128,48.9 0,176.9   " />
              </svg>
            </div>
            <div className="months">
              <div style={{ flex: 1 }}>
                <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                  {showMonth.format('MMMM')} {showMonth.format('YYYY')}
                </div>
                <div style={{ background: '#E9E9E9', borderRadius: '3px', marginTop: '5px', overflow: 'hidden' }}>
                  <DateRangeMonth year={parseInt(showMonth.format('YYYY'), 10)} month={parseInt(showMonth.format('M'), 10)} selection={{ from, to }} onSelect={onSelect} />
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                  {showMonth.clone().add(1, 'month').format('MMMM')} {showMonth.clone().add(1, 'month').format('YYYY')}
                </div>
                <div style={{ background: '#E9E9E9', borderRadius: '3px', marginTop: '5px', overflow: 'hidden' }}>
                  <DateRangeMonth year={parseInt(showMonth.clone().add(1, 'month').format('YYYY'), 10)} month={parseInt(showMonth.clone().add(1, 'month').format('M'), 10)} selection={{ from, to }} onSelect={onSelect} />
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                  {showMonth.clone().add(2, 'month').format('MMMM')} {showMonth.clone().add(2, 'month').format('YYYY')}
                </div>
                <div style={{ background: '#E9E9E9', borderRadius: '3px', marginTop: '5px', overflow: 'hidden' }}>
                  <DateRangeMonth year={parseInt(showMonth.clone().add(2, 'month').format('YYYY'), 10)} month={parseInt(showMonth.clone().add(2, 'month').format('M'), 10)} selection={{ from, to }} onSelect={onSelect} />
                </div>
              </div>
            </div>
            <div className="goTo down" onClick={() => setShowMonth(date => date.clone().add(1, 'month'))}>
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 256 256" xmlSpace="preserve">
                <polygon points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093" />
              </svg>
            </div>
          </div>
        }
      </div>
    </>
  );
}
