import React, { useEffect, useState } from 'react';

import { Loader } from '../../components/loader';
import { useNotification } from '../../hooks/notification/Notification';
import { useModal } from '../../hooks/modal/Modal';
import Api from '../../services/Api';
import { Head } from '../../components/head';
import { Input, useForm } from '../../hooks/form';

export default function SelectPackageClassesModal({ registration, onAdded }) {
  const notification = useNotification();
  const modal = useModal();
  const [selectedClasses, setSelectedClasses] = useState([...registration.classes].map(sc => registration.id ? sc.classe : sc)); // If update mode, need to get the classe, not the registrationClasse
  const [classes, setClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const filters = useForm({
    search: '',
  });

  useEffect(() => {
    setClasses(prevClasses => prevClasses.map(classe => {
      const search = filters.data ? filters.data.search : '';
      return { ...classe, hide: (
        (classe.name ? classe.name.search(new RegExp(`${search.replace(/[ ]{*}/, '[ ]{*}')}`, 'i')) === -1 : true)
        && (classe.number ? classe.number.search(new RegExp(`${search.replace(/[ ]{*}/, '[ ]{*}')}`, 'i')) === -1 : true)
      )};
    }));
  }, [filters.data.search]);

  useEffect(() => {
    Api.get(`/packages/${registration.package.id}`)
      .then(response => {
        setClasses(response.data.classes.map(pClasse => pClasse.classe));
        setIsLoading(false);
      })
      .catch(error => {
        alert('Une erreur est survenue');
      });
  }, [registration.id]);
  
  function onSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    
    if (registration.id) {
      // On update
      Api.post(`/registrations/${registration.id}/classes`, selectedClasses)
      .then(response => {
        onAdded(response.data);
        notification.notify('La liste des cours a été modifiée !');
        modal.close();
      })
      .catch(error => {
        alert('Une erreur est survenue');
      });
    } else {
      // On create
      onAdded(selectedClasses);
      modal.close();
    }
  }

  function onClasseSelect(classe) {
    setSelectedClasses(prevSelectedClasses => {
      if (prevSelectedClasses.findIndex(sc => sc.id === classe.id) === -1) {
        return [ ...prevSelectedClasses, classe ];
      } else {
        return prevSelectedClasses.filter(sc => sc.id !== classe.id);
      }
    });
  }

  return (
    <div className="registration-add-classe-modal" style={{ flex: 1 }}>
      <Head sub noTabs title="Choisissez les cours souhaités">
      </Head>
      <form className="filters" onSubmit={filters.onSubmit} style={{ marginBottom: '0px', borderBottom: '1px solid #ccc', padding: '20px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
      </form>
      <form className="choose-list" onSubmit={onSubmit}>
        { isLoading &&
          <Loader />
        }
        <div className="classes">
          {
            classes.filter(cl => !cl.hide).map(classe => (
              <div key={classe.id} className={`classe${selectedClasses.findIndex(sc => sc.id === classe.id) !== -1? ' selected' : ''}`} onClick={() => onClasseSelect(classe)}>
                {classe.number} • {classe.name}
              </div>
            ))
          }
        </div>
        <br/>
        <button className="btn full">Enregistrer les {selectedClasses.length} cours</button>
      </form>
    </div>
  );
}