import React from 'react';

import { Link } from '../../../hooks/router';
import { Input } from '../../../hooks/form';

import { List, useList } from '../../../hooks/list';

export default function StudentRessourceClasses({ student }) {
  const { filters, items, isLoading, load } = useList(`students/${student.id}/classes`, {
    search: '',
  });

  return (
    <div className="list-page">
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
        <button className="btn">Ajouter un cours</button>
      </form>
      <List onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Nom', sort: 'name' }, { name: 'Département programmateur' }, { name: 'Canal' }, { name: 'Séances' }]}>
        { items.map(classe => (
          <Link key={classe.id} className="item" to="classe" params={{ id: classe.id }}>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              <span>
                {classe.number} • {classe.name}
              </span>
            </div>
            <div className="prop">
              {classe.department ? ({
                'cp': 'Cours Publics',
                'fnd': 'FND',
                'issr': 'ISSR',
                'fr': 'FEB',
                'ccdej': 'CCDEJ',
                'fcf': 'FCF',
                'e-learning': 'ELEARNING',
                'other': 'Autre',
              }[classe.department]) :  (<div style={{ color: 'red', display: 'flex', alignItems: 'center', gap: '8px' }}>
                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.9522 16.3536L10.2152 5.85658C10.9531 4.38481 13.0539 4.3852 13.7913 5.85723L19.0495 16.3543C19.7156 17.6841 18.7487 19.25 17.2613 19.25H6.74007C5.25234 19.25 4.2854 17.6835 4.9522 16.3536Z"></path>
                  <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10V12"></path>
                  <circle cx="12" cy="16" r="1" fill="currentColor"></circle>
                </svg>
                Aucun
                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.9522 16.3536L10.2152 5.85658C10.9531 4.38481 13.0539 4.3852 13.7913 5.85723L19.0495 16.3543C19.7156 17.6841 18.7487 19.25 17.2613 19.25H6.74007C5.25234 19.25 4.2854 17.6835 4.9522 16.3536Z"></path>
                  <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10V12"></path>
                  <circle cx="12" cy="16" r="1" fill="currentColor"></circle>
                </svg>
              </div>)}
            </div>
            <div className="prop">
              {{ presentiel: 'Présentiel', distanciel: 'Distanciel' }[classe.channel]}
            </div>
            <div className="prop">
              {classe.period ? { annual: 'Annuel', 'first-semester': '1er semestre', 'second-semester':'2nd semestre'}[classe.period] : ''} {classe.period && classe.year && <>•</>} {classe.year ? `${classe.year} - ${classe.year+1}` : ''}
            </div>
          </Link>
        ))}
      </List>
    </div>
  );
};