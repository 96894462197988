import React from 'react';

import { Head } from '../../components/head';
import { Input, useForm } from '../../hooks/form';
import { useNotification } from '../../hooks/notification/Notification';
import { useRouter } from '../../hooks/router';
import Api from '../../services/Api';
import Validator from 'validator';

export default function AdminCreate() {
  const router = useRouter();
  const notification = useNotification();
  const form = useForm({
    firstname: '',
    lastname: '',
    email: '',
    group: 'admin',
  }, (data, validate) => {
    const result = validate(data, {
      email: { validator: (email) => Validator.isEmail(email), message: 'L\'email n\'est pas valide' },
      firstname: { validator: (firstname) => !Validator.isEmpty(firstname), message: 'Vous devez mettre un prénom' },
      lastname: { validator: (lastname) => !Validator.isEmpty(lastname), message: 'Vous devez mettre un nom' },
    });

    if (result.hasErrors) {
      form.setErrors(result.errors);
      return;
    }

    Api.post('/admins', data)
      .then(response => {
        notification.notify('L\'administrateur a bien été créé !');
        router.goTo('admin', { id: response.data.id });
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.code === 'email-exists') {
          form.setErrors({
            email: 'L\'email est déjà utilisé'
          });
        }
      });
  });

  return (
    <div className="ressource-page">
      <Head noTabs title="Nouvel administrateur" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px', maxWidth: '550px' }}>
        <Input label="Email" {...form.field('email')} />
        <br/>
        <Input label="Prénom" {...form.field('firstname')} />
        <br/>
        <Input  label="Nom" {...form.field('lastname')} />
        <br />
        <button className="btn">Enregistrer</button>
      </form>
    </div>
  );
}
