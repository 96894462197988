import React from 'react';
import moment from 'moment';

import { Link } from '../../hooks/router';
import { Input, Select } from '../../hooks/form';
import Api from '../../services/Api';
import { queryToUrl } from '../../hooks/router';

import { Head } from '../../components/head';
import { List, useList } from '../../hooks/list';
import { useAuth } from '../../hooks/auth/Auth';

export default function ClassesList() {
  const auth = useAuth();
  const { filters, items, isLoading, load } = useList('classes', {
    search: '',
    year: 2024,
    period: null,
    department: null
  });

  return (
    <div className="list-page">
      <Head title="Cours">
        <a href={`${Api.API_URL}/classes.xlsx${queryToUrl({ access_token: auth.token, search: filters.data.search, year: filters.data.year, period: filters.data.period, program: filters.data.program })}`} target="_blank" rel="noreferrer noopener" className="btn">Exporter les cours</a>
        <Link to="createClasse" className="btn">Nouveau</Link>
      </Head>
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
        <div className="filter" style={{ minWidth: '220px' }}>
          <Select noSearch {...filters.field('department')}>
            <option value={null}>Tous les départements</option>
            <option value="cp">Cours Publics</option>
            <option value="e-learning">E-learning</option>
            <option value="fnd">FND</option>
            <option value="issr">ISSR</option>
            <option value="fr">FEB</option>
            <option value="ccdej">CCDEJ</option>
            <option value="fcf">FCF</option>
          <option value="other">Autre</option>
          </Select>
        </div>
        <div className="filter" style={{ minWidth: '120px' }}>
          <Select noSearch {...filters.field('year')}>
            <option value={null}>Toutes années</option>
            <option value={2021}>2021 - 2022</option>
            <option value={2022}>2022 - 2023</option>
            <option value={2023}>2023 - 2024</option>
            <option value={2024}>2024 - 2025</option>
            <option value={2025}>2025 - 2026</option>
            <option value={2026}>2026 - 2027</option>
            <option value={2027}>2027 - 2028</option>
            <option value={2028}>2028 - 2029</option>
            <option value={2029}>2029 - 2030</option>
            <option value={2030}>2030 - 2031</option>
            <option value={2031}>2031 - 2032</option>
          </Select>
        </div>
        <div className="filter" style={{ minWidth: '120px' }}>
          <Select noSearch {...filters.field('period')}>
            <option value={null}>Toutes périodes</option>
            <option value="annual">Annuel</option>
            <option value="first-semester">1er semestre</option>
            <option value="second-semester">2nd semestre</option>
          </Select>
        </div>
      </form>
      <List onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Nom', sort: 'name' }, { name: 'Nombre d\'étudiant' }, { name: 'Jauge restante' }, { name: 'Département' }, { name: 'Séances' }, { name: 'Inscription' }]}>
        { items.map(classe => (
          <Link key={classe.id} className="item" to="classe" params={{ id: classe.id }}>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              <span>
                {classe.number} • {classe.name}
              </span>
            </div>
            <div className="prop">
              {classe.studentCount || 0} étudiant{classe.studentCount > 1 ? 's' : ''}
            </div>
            <div className="prop">
              {(classe.capacity || 0) - (classe.studentCount || 0)}
            </div>
            <div className="prop">
              {classe.department ? ({
                'cp': 'Cours Publics',
                'fnd': 'FND',
                'issr': 'ISSR',
                'fr': 'FEB',
                'ccdej': 'CCDEJ',
                'fcf': 'FCF',
                'e-learning': 'ELEARNING',
                'other': 'Autre',
              }[classe.department]) :  (<div style={{ color: 'red', display: 'flex', alignItems: 'center', gap: '8px' }}>
                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.9522 16.3536L10.2152 5.85658C10.9531 4.38481 13.0539 4.3852 13.7913 5.85723L19.0495 16.3543C19.7156 17.6841 18.7487 19.25 17.2613 19.25H6.74007C5.25234 19.25 4.2854 17.6835 4.9522 16.3536Z"></path>
                  <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10V12"></path>
                  <circle cx="12" cy="16" r="1" fill="currentColor"></circle>
                </svg>
                Aucun
                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.9522 16.3536L10.2152 5.85658C10.9531 4.38481 13.0539 4.3852 13.7913 5.85723L19.0495 16.3543C19.7156 17.6841 18.7487 19.25 17.2613 19.25H6.74007C5.25234 19.25 4.2854 17.6835 4.9522 16.3536Z"></path>
                  <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10V12"></path>
                  <circle cx="12" cy="16" r="1" fill="currentColor"></circle>
                </svg>
              </div>)}
              <div style={{ color: 'grey', fontSize: '0.8em' }}>{{ presentiel: 'Présentiel', distanciel: 'Distanciel' }[classe.channel]}</div>
            </div>
            <div className="prop">
              {classe.period ? { annual: 'Annuel', 'first-semester': '1er semestre', 'second-semester':'2nd semestre'}[classe.period] : ''} {classe.period && classe.year && <>•</>} {classe.year ? `${classe.year} - ${classe.year+1}` : ''}
            </div>
            <div className="prop">
              { classe.registrationOpen ? (
                <>
                  { classe.registrationOpenStartAt && moment().isBefore(moment(classe.registrationOpenStartAt), 'day') ?
                    <>
                      Pas encore ouvertes
                    </>
                  : <>
                    { classe.registrationOpenEndAt && moment().isAfter(moment(classe.registrationOpenEndAt), 'day') ?
                      <>
                        Fermées
                      </>
                    : <>
                      Ouvertes
                    </>}
                  </>}
                </>
              ) : (
                <>
                  Fermées
                </>
              )}
            </div>
          </Link>
        ))}
      </List>
    </div>
  );
};