import React from 'react';

import { Head } from '../../components/head';
import { Input, Select, useForm } from '../../hooks/form';
import { useNotification } from '../../hooks/notification/Notification';
import { useRouter } from '../../hooks/router';
import Api from '../../services/Api';
import Validator from 'validator';

export default function StudentCreate() {
  const router = useRouter();
  const notification = useNotification();
  const form = useForm({
    firstname: '',
    lastname: '',
    email: '',
    sendValidationEmail: true,
    civility: null,
    zipcode: '',
  }, (data, validate) => {
    const result = validate(data, {
      civility: { validator: (civility) => ['mr', 'mrs', 'father', 'sister', 'brother', 'mgr'].includes(civility), message: 'Veuillez choisir une civilité' },
      email: { validator: (email) => Validator.isEmail(email), message: 'L\'email n\'est pas valide' },
      firstname: { validator: (firstname) => !Validator.isEmpty(firstname), message: 'Vous devez mettre un prénom' },
      lastname: { validator: (lastname) => !Validator.isEmpty(lastname), message: 'Vous devez mettre un nom' },
    });

    if (result.hasErrors) {
      form.setErrors(result.errors);
      return;
    }

    Api.post('/students', data)
      .then(response => {
        notification.notify('L\'étudiant a bien été créé !');
        router.goTo('student', { id: response.data.id });
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.code === 'email-exists') {
          form.setErrors({
            email: 'L\'email est déjà utilisé'
          });
        }
      });
  });

  return (
    <div className="ressource-page">
      <Head noTabs title="Nouvel étudiant" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px', maxWidth: '550px' }}>
        <Input label="Email" {...form.field('email')} />
        <br/>
        <Input label="Prénom" {...form.field('firstname')} />
        <br/>
        <Input label="Nom" {...form.field('lastname')} />
        <br/>
        <Select label="Civilité" noSearch {...form.field('civility')}>
          <option value={null}>Non définie</option>
          <option value="mr">M.</option>
          <option value="mrs">Mme</option>
          <option value="father">Père</option>
          <option value="sister">Soeur</option>
          <option value="brother">Frère</option>
          <option value="mgr">Mgr</option>
        </Select>
        <br />
        <Input  label="Code postal" {...form.field('zipcode')} />
        <br/>
        <button className="btn">Enregistrer</button>
      </form>
    </div>
  );
}
