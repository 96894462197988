import React, { useRef } from 'react';

export default function Radio({ label, name, errors, value, checkedValue, checked, ghost, ...fieldProps }) {
  const inputRef = useRef();

  function onClick(event) {
    if (ghost) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    
    inputRef.current.click();
  }

  const internalChecked = checked !== undefined ? checked : (value === checkedValue);

  return (
    <>
      <div className="input-radio" onClick={onClick}>
        <input checked={internalChecked} value={checkedValue} ref={inputRef} {...fieldProps} style={{ display: 'none' }} />
        {internalChecked ? (
          <div className="input checked">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
              <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0    c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7    C514.5,101.703,514.499,85.494,504.502,75.496z"/>
            </svg>
          </div>
        ) : (
          <div className="input" />
        )}
        <div className="label">
          {label}
        </div>
      </div>
      {errors && <div className="error-message">{errors.join('<br>')}</div>}
    </>
  )
};
