import React from 'react';

import { Route } from '../../hooks/router';

import { Tabs, Tab } from '../../components/tabs';
import { Head } from '../../components/head';
import { Loader } from '../../components/loader';
import useRessource from '../../hooks/ressource/useRessource';

export default function ProfessorRessource({ route }) {
  const { payer, isLoading, set } = useRessource('payer', route.params.id);

  return (
    <div className="ressource-page">
      <Head prefix="Payeur" title={payer.type === 'company' ? payer.companyName : (`${payer.user ? payer.user.firstname : ''} ${(payer.user && payer.user.lastname) ? payer.user.lastname.toUpperCase() : ''}`)} isLoading={isLoading} />
      <Tabs route={route.subRoute} params={{ id: route.params.id }}>
        <Tab to="payer" routeName="_default">Aperçu</Tab>
        <Tab to="payer/bills" routeName="bills">Factures</Tab>
      </Tabs>
      { isLoading ? <Loader /> : <Route route={route.subRoute} payer={payer} setPayer={set} /> }
    </div>
  );
}
