import React from 'react';

export default function Input({ label, name, errors, prefix, suffix, explain, value, style, ...fieldProps }) {
  return (
    <>
      { label && 
        <label htmlFor="">{label}</label>
      }
      { (prefix || suffix) ? (
        <div style={{ display: 'flex', alignItems: 'center', ...style }}>
          { prefix && <div style={{ whiteSpace: 'nowrap', background: '#ececec', padding: '11px 10px', borderRadius: '8px 0px 0px 8px' }}>{prefix}</div> }
          <input value={value || ''} {...fieldProps} style={{ borderRadius: (prefix && suffix) ? '0px' : (prefix ? '0px 8px 8px 0px' : '8px 0px 0px 8px') }} />
          { suffix && <div style={{ whiteSpace: 'nowrap', background: '#ececec', padding: '11px 10px', borderRadius: '0px 8px 8px 0px' }}>{suffix}</div> }
        </div>
      ) : (
        <input value={value || ''} {...fieldProps} />
      )}
      { explain &&
        <div style={{ color: 'grey', fontSize: '0.9em', margin: '5px' }}>{explain}</div>
      }
      { errors && <div className="error-message">{Array.isArray(errors) ? errors.join('<br>') : errors}</div> }
    </>
  )
};
