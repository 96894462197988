import React from 'react';

import { useNotification } from '../../hooks/notification/Notification';
import { useModal } from '../../hooks/modal/Modal';
import Api from '../../services/Api';
import { Head } from '../../components/head';
import { Input, Select, useForm } from '../../hooks/form';

export default function CreatePayerModal({ registration, onCreated }) {
  const notification = useNotification();
  const modal = useModal();
  const form = useForm({
    // existing: true,
    payer: null,
    type: 'company',
    paymentMethod: 'web',
    amount: 0,
  }, (data) => {
    if (registration) {
      Api.post(`/registrations/${registration.id}/payers`, data)
        .then(response => {
          onCreated(response.data);
          notification.notify('Le payeur a été ajoutée !');
          modal.close();
        })
        .catch(error => {
          alert('Une erreur est survenue');
        });
    } else {
      onCreated(data);
      modal.close();
    }
  })
  return (
    <div className="list-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Ajouter un payeur" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        <Select
          label="Payeur"
          nullable
          load={async ({ page, search }) => {
            const response = await Api.get('/payers', { page, search });
            return { items: response.data };
          }}
          {...form.field('payer')}
          noSelectionMessage="Aucun payeur"
        >
            {(payer, onSelect) => (
              <div key={payer.id} onClick={onSelect}>
                { payer.type === 'company' ? (
                  <>{payer.companyName}</>
                ) : (
                  <>{payer.user.firstname} {payer.user.lastname.toUpperCase()}</>
                )}
              </div>
            )}
        </Select>
        <br/>
        { registration &&
          <>
            <Input suffix="€ TTC" label="Montant" {...form.field('amount', { float: true })} />
            <br/>
            <Select label="Mode de paiement" noSearch {...form.field('paymentMethod')}>
              <option value="web">Web</option>
              <option value="bankcard">CB</option>
              <option value="change">espèces</option>
              <option value="check">Chèque</option>
              <option value="bank-transfer">Virement</option>
            </Select>
            <br/>
          </>
        }
        <button className="btn full">Ajouter</button>
      </form>
    </div>
  );
}