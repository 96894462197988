import React from 'react';
import moment from 'moment';

import { List, useList } from '../../../hooks/list';
import BillModal from '../../bills/Ressource';
import { useModal } from '../../../hooks/modal/Modal';
import { useAuth } from '../../../hooks/auth/Auth';
import { queryToUrl } from '../../../hooks/router';
import Api from '../../../services/Api';

export default function ProductRessourceBills({ product }) {
  const auth = useAuth();
  const modal = useModal();
  const { items, isLoading, load } = useList(`products/${product.id}/bills`, {
    search: '',
  });

  return (
    <div className="list-page">
      <form className="filters" onSubmit={() => {}} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          &nbsp;
        </div>
        <div className="filter">
          <a href={`${Api.API_URL}/products/${product.id}/bills.xlsx${queryToUrl({ access_token: auth.token })}`} target="_blank" rel="noreferrer noopener" className="btn" style={{ display: 'block' }}>Export XLSX</a>
        </div>
      </form>
      <List onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Date d\'achat' }, { name: 'Étudiant' }]}>
        { items.map(bill => (
          <div key={bill.id} className="item" onClick={() => modal.open(<BillModal billId={bill.id} billNumber={bill.number} billType={bill.type} />)}>
            <div className="prop">
              {moment(bill.createdAt).format('DD/MM/YYYY HH:mm')}
            </div>
            <div className="prop">
              {bill.student ? `${bill.student.user.firstname} ${(bill.student.user.lastname ? bill.student.user.lastname.toUpperCase() : '')}` : 'n/a'}
            </div>
          </div>
        ))}
      </List>
    </div>
  );
};