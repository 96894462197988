import React, { useState } from 'react';
import moment from 'moment';

import { Input } from '../../hooks/form';

import { Head } from '../../components/head';
import { List, useList } from '../../hooks/list';
import Api from '../../services/Api';
import { useModal } from '../../hooks/modal/Modal';
import { queryToUrl } from '../../hooks/router';
import { useAuth } from '../../hooks/auth/Auth';
import { useNotification } from '../../hooks/notification/Notification';
import { useEffect } from 'react';
import DonationModal from './Ressource';

function ManageAmountModal() {
  const modal = useModal();
  const notification = useNotification();
  const [amounts, setAmounts] = useState([]);

  useEffect(() => {
    Api.get(`/amount-donations`)
      .then((response) => {
        setAmounts(response.data)
      }).catch(() => {
        alert('Une erreur est survenue');
      });
  }, []);
  
  function onSubmit(event) {
    event.stopPropagation();
    event.preventDefault();

    Api.post(`/amount-donations`, amounts)
      .then(() => {
        notification.notify('Les montants ont été enregistré !');
        modal.closeAll();
      }).catch(() => {
        alert('Une erreur est survenue');
      });
  };

  function onChange(amount) {
    return (event) => {
      const value = event.target.value;
      setAmounts(prevAmounts => ([ ...prevAmounts.map(a => ({ ...a, amount: (a.id === amount.id ? value : a.amount) })) ]))
    }
  }
  
  return (
    <div style={{ width: '100%' }}>
      <Head title={`Montants des dons`} noTabs />
      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        {
          amounts.map(amount => (
            <React.Fragment key={amount.id}>
              <Input label={`Choix ${amount.id}`} value={amount.amount} onChange={onChange(amount)} suffix="€" />
              <br/>
            </React.Fragment>
          ))
        }
        <button className="btn full">Enregistrer les montants</button>
      </form>
    </div>
  )
}

export default function DonationsList() {
  const auth = useAuth();
  const modal = useModal();
  const { items, load } = useList('donations', {
    search: '',
    status: null,
    type: null,
    createdAtFrom: null,
    createdAtTo: null,
    program: null,
  });

  return (
    <div className="list-page" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Head title="Dons">
        <button className="btn" onClick={() => modal.open(<ManageAmountModal />)}>Modifier les montants</button>
        <a href={`${Api.API_URL}/donations.xlsx${queryToUrl({ access_token: auth.token })}`} target="_blank" rel="noreferrer noopener" className="btn">Exporter les dons</a>
      </Head>
      <List onLoadMore={load} isLoading={false} headers={[
        { name: 'Créée le', sort: 'createdAt' },
        { name: 'Payeur', sort: 'payer' },
        { name: 'Type', sort: 'type' },
        { name: 'Montant payé' },
        { name: 'Mode de paiement' },
        { name: 'Nom de l\'inscrit' }
      ]}>
        { items.map(donation => (
          <div key={donation.id} className="item" onClick={() => modal.open(<DonationModal donation={donation} />)}>
            <div className="prop">
              {moment(donation.createdAt).format('DD/MM/YYYY')}
            </div>
            <div className="prop">
              {donation.student.user.lastname ? donation.student.user.lastname.toUpperCase() : ''} {donation.student.user.firstname}
            </div>
            <div className="prop">
              Don
            </div>
            <div className="prop">
              {donation.amount}€
            </div>
            <div className="prop">
              Web
            </div>
            <div className="prop">
              {donation.student.user.lastname ? donation.student.user.lastname.toUpperCase() : ''}
            </div>
          </div>
        ))}
      </List>
    </div>
  );
};