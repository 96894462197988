import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { Head } from '../../components/head';
import { Checkbox, Input, Radio, Select, useForm } from '../../hooks/form';
import { useList, List } from '../../hooks/list';
import { useModal } from '../../hooks/modal/Modal';
import { useNotification } from '../../hooks/notification/Notification';
import { Link } from '../../hooks/router';
import Api from '../../services/Api';
import CreatePayerModal from './CreatePayerModal';
import SelectPackageClassesModal from './SelectPackageClassesModal';
import BillModal from '../bills/Ressource';

import './create.scss';
import AddClasseModal from './AddClasseModal';
import { Loader } from '../../components/loader';
import { Alert } from '../../components/alert';

function SelectClasseModal({ onSelect }) {
  const modal = useModal();
  const { filters, items, isLoading, load } = useList('classes', {
    search: '',
    channel: null,
    year: null,
    period: null,
    type: null,
    department: null,
    openRegistrations: true,
  });

  function select(classe) {
    if (classe.year === 2021 && (classe.type === 'public' || classe.type === 'ccdej')) {
      return alert('Vous ne pouvez plus inscrire à des cours de 2021!');
    }

    onSelect(classe);
    modal.close();
  }

  return (
    <div className="list-page">
      <Head title="Choisissez un cours" />
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
        <div className="filter" style={{ minWidth: '220px' }}>
          <Select noSearch {...filters.field('department')}>
            <option value={null}>Tous les départements</option>
            <option value="cp">Cours Publics</option>
            <option value="e-learning">E-learning</option>
            <option value="fnd">FND</option>
            <option value="issr">ISSR</option>
            <option value="fr">FEB</option>
            <option value="ccdej">CCDEJ</option>
            <option value="fcf">FCF</option>
            <option value="other">Autre</option>
          </Select>
        </div>
        <div className="filter" style={{ minWidth: '120px' }}>
          <Select noSearch {...filters.field('year')}>
            <option value={null}>Toutes années</option>
            <option value={2021} disabled>2021 - 2022</option>
            <option value={2022}>2022 - 2023</option>
            <option value={2023}>2023 - 2024</option>
            <option value={2024}>2024 - 2025</option>
            <option value={2025}>2025 - 2026</option>
            <option value={2026}>2026 - 2027</option>
            <option value={2027}>2027 - 2028</option>
            <option value={2028}>2028 - 2029</option>
            <option value={2029}>2029 - 2030</option>
            <option value={2030}>2030 - 2031</option>
            <option value={2031}>2031 - 2032</option>
          </Select>
        </div>
        <div className="filter" style={{ minWidth: '120px' }}>
          <Select noSearch {...filters.field('period')}>
            <option value={null}>Toutes périodes</option>
            <option value="annual">Annuel</option>
            <option value="first-semester">1er semestre</option>
            <option value="second-semester">2nd semestre</option>
          </Select>
        </div>
        <div className="filter" style={{ minWidth: '120px', marginTop: '10px' }}>
          <Checkbox {...filters.field('openRegistrations', { type: 'checkbox' })} label="Inscriptions ouvertes uniquement" />
        </div>
      </form>
      <List scrollableClassName=".component-modal" onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Nom', sort: 'name' }, { name: 'Département programmateur' }, { name: 'Canal' }, { name: 'Séances' }, { name: 'Jauge restante' }]}>
        { items.map(classe => (
          <div key={classe.id} className="item" onClick={() => select(classe)}>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              <span>
                {classe.number} • {classe.name}
              </span>
            </div>
            <div className="prop">
              {classe.department ? ({
                'cp': 'Cours Publics',
                'fnd': 'FND',
                'issr': 'ISSR',
                'fr': 'FEB',
                'ccdej': 'CCDEJ',
                'fcf': 'FCF',
                'e-learning': 'ELEARNING',
                'other': 'Autre',
              }[classe.department]) :  (<div style={{ color: 'red', display: 'flex', alignItems: 'center', gap: '8px' }}>
                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.9522 16.3536L10.2152 5.85658C10.9531 4.38481 13.0539 4.3852 13.7913 5.85723L19.0495 16.3543C19.7156 17.6841 18.7487 19.25 17.2613 19.25H6.74007C5.25234 19.25 4.2854 17.6835 4.9522 16.3536Z"></path>
                  <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10V12"></path>
                  <circle cx="12" cy="16" r="1" fill="currentColor"></circle>
                </svg>
                Aucun
                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.9522 16.3536L10.2152 5.85658C10.9531 4.38481 13.0539 4.3852 13.7913 5.85723L19.0495 16.3543C19.7156 17.6841 18.7487 19.25 17.2613 19.25H6.74007C5.25234 19.25 4.2854 17.6835 4.9522 16.3536Z"></path>
                  <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10V12"></path>
                  <circle cx="12" cy="16" r="1" fill="currentColor"></circle>
                </svg>
              </div>)}
            </div>
            <div className="prop">
              {{ presentiel: 'Présentiel', distanciel: 'Distanciel' }[classe.channel]}
            </div>
            <div className="prop">
              {classe.period ? { annual: 'Annuel', 'first-semester': '1er semestre', 'second-semester':'2nd semestre'}[classe.period] : ''} {classe.period && classe.year && <>•</>} {classe.year ? `${classe.year} - ${classe.year+1}` : ''}
            </div>
            <div className="prop">
              {(classe.capacity || 0) - (classe.studentCount || 0)}
            </div>
          </div>
        ))}
      </List>
    </div>
  )
}

function SelectPackageModal({ onSelect }) {
  const modal = useModal();
  const { filters, items, isLoading, load } = useList('packages', {
    search: '',
    program: null
  });

  function select(classe) {
    onSelect(classe);
    modal.close();
  }

  return (
    <div className="list-page">
      <Head title="Choisissez un package" />
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
        <div className="filter" style={{ minWidth: '220px' }}>
          <Select noSearch {...filters.field('program')}>
            <option value={null}>Tous les départements</option>
            <option value="ccdej">CCDEJ</option>
            <option value="fnd">FND</option>
            <option value="fr">FEB</option>
            <option value="issr">ISSR</option>
            <option value="other">Autre</option>
          </Select>
        </div>
      </form>
      <List onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Nom', sort: 'name' }, { name: 'Créée le', sort: 'createdAt' }, { name: 'Programme' }]}>
        { items.map(classePackage => (
          <div key={classePackage.id} className="item" onClick={() => select(classePackage)}>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '10px', borderRadius: '10px', background: 'green', width: '10px', height: '10px', flex: '0 0 auto' }}></div>
              <span>
                {classePackage.name}
              </span>
            </div>
            <div className="prop">
              {moment(classePackage.createdAt).format('DD/MM/YYYY HH:mm:ss')}
            </div>
            <div className="prop">
              {classePackage.program ? classePackage.program.toUpperCase() : 'n/a'}
            </div>
          </div>
        ))}
      </List>
    </div>
  )
}

function SelectProductModal({ onSelect }) {
  const modal = useModal();
  const { filters, items, isLoading, load } = useList('products', {
    search: '',
  });

  function select(product) {
    onSelect(product);
    modal.close();
  }

  return (
    <div className="list-page">
      <Head title="Choisissez un produit" />
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
      </form>
      <List onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Nom', sort: 'name' }, { name: 'Prix' }]}>
        { items.map(produit => (
          <div key={produit.id} className="item" onClick={() => select(produit)}>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              {produit.name}
            </div>
            <div className="prop">
              {produit.price}€
            </div>
          </div>
        ))}
      </List>
    </div>
  )
}

function ShowSuccessModal({ data }) {
  const modal = useModal();

  return (
    <div style={{ width: '100%' }}>
      <Head title="Les inscriptions ont été créées !" noTabs />
      <div style={{ padding: '20px' }}>
        <div style={{ marginBottom: '10px', fontWeight: 700 }}>Inscriptions</div>
        { data.registrations.length === 0 &&
          <div style={{ textAlign: 'center', padding: '20px', paddingTop: '5px', color: 'grey' }}>Aucune inscription</div>
        }
        <div>
          {
            data.registrations.map(registration => (
              <div style={{ border: '1px solid #ccc', borderRadius: '8px', marginBottom: '10px', padding: '20px' }}>
                <Link to="registration" params={{ id: registration.id }} key={registration.id} >
                  #{registration.number}
                </Link>
              </div>
            ))
          }
        </div>
        <div style={{ marginBottom: '10px', fontWeight: 700 }}>Produits</div>
        { data.products.length === 0 &&
          <div style={{ textAlign: 'center', padding: '20px', paddingTop: '5px', color: 'grey' }}>Aucun produit</div>
        }
        <div>
          {
            data.products.map(product => (
              <div style={{ border: '1px solid #ccc', borderRadius: '8px', marginBottom: '10px', padding: '20px' }}>
                <Link to="product" params={{ id: product.id }} key={product.id} >
                  {product.product.name}
                </Link>
              </div>
            ))
          }
        </div>
        { data.bills.length > 0 &&
          <>
            <div style={{ marginBottom: '10px', fontWeight: 700, marginTop: '20px' }}>Factures</div>
            <div>
              {
                data.bills.map(bill => (
                  <div key={bill.id} onClick={() => modal.open(<BillModal billId={bill.id} billNumber={bill.number} billType={bill.type} />)} style={{ border: '1px solid #ccc', borderRadius: '8px', marginBottom: '10px', padding: '20px', cursor: 'pointer' }}>
                    #{bill.number} • {bill.amount ? bill.amount.toFixed(2) : 'n/a'}€
                    <br />
                    {bill.payer.user.firstname} {bill.payer.user.lastname.toUpperCase()}
                  </div>
                ))
              }
            </div>
          </>
        }

        <br />
        <Link to="registrations" className="btn">Revenir à la liste des inscriptions</Link>
        <br />
        <br />
      </div>
    </div>
  )
}

export default function RegistrationCreate() {
  const modal = useModal();
  const notification = useNotification();
  const [registrations, setRegistrations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [payers, setPayers] = useState([]);
  const form = useForm({
    tarifType: 'individualPriceTtc',
    student: null,
    joinClasse: true,
    noBillCreation: false,
    draft: false,
    department: null,
    noEmail: false,
    zipcode: '',
  }, (data) => {
    // Check if amount === 0, dont generate bill
    if (getTotalPrice() <= 0 && form.data.noBillCreation === false && form.data.draft === false) {
      alert(`Il n’est pas possible de créer une facture avec un montant égal à zéro. Pour effectuer une inscription gratuite vous devez cocher la case “Ne pas générer de facture”`);
      return;
    }

    setIsLoading(true);
    Api.post('/registrations', {
      ...data,
      registrations,
      payers,
    })
      .then(response => {
        notification.notify('L\'inscription a bien été créée !');
        modal.open(<ShowSuccessModal data={response.data} />, { closable: false });
        //router.goTo('registration', { id: response.data.id });
        setIsLoading(false);
      }).catch(() => {
        // @todo error when email is used
        alert('Une erreur est survenue');
        setIsLoading(false);
      })
  });

  useEffect(() => {
    form.setValue('zipcode', form?.data?.student?.zipcode);
  }, [form.data.student]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const studentId = urlSearchParams.get('studentId');
    if (studentId) {
      Api.get(`/students/${studentId}`).then(response => {
        form.setValue('student', response.data);
      }).catch(error => {
        console.log('ERROR', error)
      });
    }
  }, []);

  useEffect(() => {
    // Load presentielClasse if needed
    Promise.all(registrations.filter(registration => 
      registration.classe
      && registration.classe.channel === 'distanciel'
      && registration.classe.presentielClasse === undefined
      && registration.classe.presentielClasseId !== null
      && registration.classe.presentielClasseId !== ''
    ).map(registration => {
      return Api.get(`/classes/${registration.classe.presentielClasseId}`)
        .then(response => {
          return {
            registrationInternalId: registration._internalId,
            presentielClasse: response.data
          };
        });
    })).then((data) => {
      if (data.length > 0) {
        setRegistrations(prevRegistrations => {
          data.map(({ registrationInternalId, presentielClasse }) => {
            const index = prevRegistrations.findIndex(pR => registrationInternalId === pR._internalId);
            if (index !== -1) {
              prevRegistrations[index].classe.presentielClasse = presentielClasse;
            }
          });
          
          return [...prevRegistrations];
        });
      }
    });
  }, [registrations]);

  useEffect(() => {
    setRegistrations(prevRegistrations => {
      return prevRegistrations.map(prevRegistration => ({ ...prevRegistration, alreadyRegistered: undefined }));
    });
  }, [form.data.student]);

  useEffect(() => {
    if (registrations.filter(r => r.alreadyRegistered === undefined).length > 0 && form.data.student) {
      // Check if the student has a registration on classe
      const queries = {
        student: form.data.student.id,
        status: 'validated,pending,pendingPayment'
      };
      if (registrations.filter(r => r.classe).length > 0) {
        queries.classe = registrations.filter(r => r.classe).map(r => r.classe.id).join(',')
      }
      if (registrations.filter(r => r.package).length > 0) {
        queries.package = registrations.filter(r => r.package).map(r => r.package.id).join(',')
      }
      Api.get('/registrations', queries)
        .then(response => {
          setRegistrations(prevRegistrations => {
            prevRegistrations = prevRegistrations.map(prevRegistration => ({ ...prevRegistration, alreadyRegistered: false }));
            response.data.map((registration) => {
              const index = prevRegistrations.findIndex(pR => {
                if (pR.product) {
                  return false;
                }
                if (registration.classe && pR.classe) {
                  return registration.classe.id === pR.classe.id;
                } else if (registration.package && pR.package) {
                  return registration.package.id === pR.package.id;
                }
              });
              if (index !== -1) {
                prevRegistrations[index].alreadyRegistered = true;
              }
            });
            
            return [...prevRegistrations];
          });
        });
    }
  }, [registrations, form.data.student]);
  
  function onSelectClasse(classe) {
    setRegistrations(prevRegistrations => {
      if (prevRegistrations.findIndex(pR => pR.classe && pR.classe.id === classe.id) === -1) {
        return [...prevRegistrations, {
          classe,
          _internalId: uuidv4(),
          price: 0,
          payers: [],
        }]
      }
    });
  }

  function onSelectPackage(classePackage) {
    setRegistrations(prevRegistrations => {
      if (prevRegistrations.findIndex(pR => pR.package && pR.package.id === classePackage.id) === -1) {
        return [...prevRegistrations, {
          package: classePackage,
          _internalId: uuidv4(),
          classes: [],
          price: 0,
          payers: [],
        }]
      }
    });
  }

  function onSelectProduct(product) {
    setRegistrations(prevRegistrations => {
      if (prevRegistrations.findIndex(pR => pR.product && pR.product.id === product.id) === -1) {
        return [...prevRegistrations, {
          product,
          quantity: 1,
          _internalId: uuidv4(),
          price: 0,
          payers: [],
        }]
      }
    });
  }

  function onRemoveRegistration(registration) {
    setRegistrations(prevRegistrations => {
      return prevRegistrations.filter(pR => pR._internalId !== registration._internalId)
    });
  }

  function getTotalPrice() {
    if (form.data.tarifType === 'specific') {
      return registrations.reduce((price, registration) => price + (registration.price || 0), 0);
    }
    return Math.round(registrations.reduce((price, registration) => {
      if (registration.classe && registration.classe.channel === 'distanciel') {
        if (registration.presentielClasse) {
          return price + registration.classe.presentielClasse[form.data.tarifType] + 30;
        } else if (registration.classes && registration.classes.length === 1) {
          return price + 160;
        } else if (registration.classes && registration.classes.length === 2) {
          return price + 200;
        }
      }
      return price + (registration.classe ? registration.classe[form.data.tarifType] : (registration.package ? registration.package[form.data.tarifType] : (registration.product.price * registration.quantity)));
    }, 0) * 100, 1) / 100;
  }

  function onPayerCreated(payer) {
    setPayers(prevPayers => {
      return [...prevPayers, { ...payer, _internalId: uuidv4() }];
    });
  }

  function onPayerRemove(payer) {
    setRegistrations(prevRegistrations => {
      return prevRegistrations.map(prevRegistration => {
        const payers = prevRegistration.payers.filter(rPayer => rPayer.id !== payer.id);
        return {
          ...prevRegistration,
          payers,
        }
      });
    });
    setPayers(prevPayers => {
      return prevPayers.filter(pP => pP._internalId === payer._internalId);
    });
  }

  function onAddPackageClasses(registration, classes) {
    setRegistrations(prevRegistrations => {
      const index = prevRegistrations.findIndex(pR => registration._internalId === pR._internalId);
      if (index !== -1) {
        prevRegistrations[index] = {
          ...prevRegistrations[index],
          classes,
        }
      }

      return [...prevRegistrations];
    });
  }

  function onRegistrationPriceUpdate(registration, event) {
    const price = parseFloat(event.target.value.replace(',', '.'), 10);
    const rawPrice = event.target.value;
    setRegistrations(prevRegistrations => {
      const index = prevRegistrations.findIndex(pR => registration._internalId === pR._internalId);
      if (index !== -1) {
        prevRegistrations[index] = {
          ...prevRegistrations[index],
          price,
          rawPrice,
        }
      }

      return [...prevRegistrations];
    });
  }

  function onChangePayerAmount(event, payer, registration) {
    const amount = parseFloat(event.target.value.replace(',', '.'), 10);
    const rawAmount = event.target.value;
    setRegistrations(prevRegistrations => {
      const index = prevRegistrations.findIndex(pR => registration._internalId === pR._internalId);
      if (index !== -1) {
        const payers = registration.payers;
        const payerIndex = payers.findIndex(rPayer => rPayer.id === payer.payer.id);
        if (payerIndex === -1) {
          payers.push({
            id: payer.payer.id,
            amount,
            rawAmount,
          });
        } else {
          payers[payerIndex].amount = amount;
          payers[payerIndex].rawAmount = rawAmount;
        }

        prevRegistrations[index] = {
          ...prevRegistrations[index],
          payers,
        }
      }

      return [...prevRegistrations];
    });
  }

  function chooseDistancielPackageClasse(registration, many) {
    if (registration.classes && registration.classes.length === (many === 'two' ? 1 : 2)) {
      return setRegistrations(prevRegistrations => {
        const index = prevRegistrations.findIndex(pR => registration._internalId === pR._internalId);
        if (index !== -1) {
          prevRegistrations[index].classes = undefined;
          prevRegistrations[index].presentielClasse = false;
        }
  
        return [...prevRegistrations];
      });
    }
    modal.open(<AddClasseModal onAdded={classes => {
      setRegistrations(prevRegistrations => {
        const index = prevRegistrations.findIndex(pR => registration._internalId === pR._internalId);
        if (index !== -1) {
          prevRegistrations[index].classes = classes;
          prevRegistrations[index].presentielClasse = false;
        }
  
        return [...prevRegistrations];
      });
    }} many={many} excludeIds={[registration.classe.id]} excludeMooc />, { size: 'full' });
  }

  function chooseDistancielPresentielClasse(registration) {
    setRegistrations(prevRegistrations => {
      const index = prevRegistrations.findIndex(pR => registration._internalId === pR._internalId);
      if (index !== -1) {
        prevRegistrations[index].classes = undefined;
        prevRegistrations[index].presentielClasse = !prevRegistrations[index].presentielClasse;
      }

      return [...prevRegistrations];
    });
  }

  function onChangeRegistrationQuantity(registration) {
    return (event) => {
      const quantity = event.target.value;
      setRegistrations(prevRegistrations => {
        const index = prevRegistrations.findIndex(pR => registration._internalId === pR._internalId);
        if (index !== -1) {
          prevRegistrations[index].quantity = parseInt(quantity, 10) || 1;
        }
  
        return [...prevRegistrations];
      });
    }
  }

  return (
    <form className="create-registration-page" onSubmit={form.onSubmit}>
      <Head noTabs title="Nouvelle inscription et/ou achat de produit">
        { isLoading &&
          <div>
            <Loader />
          </div>
        }
        { !isLoading &&
          <>
            <Checkbox label="Brouillon" {...form.field('draft', { type: 'checkbox' })} />
            <button className="btn" disabled={form.data.student === null || form.data.department === null || registrations.length === 0}>Enregistrer</button>
          </>
        }
      </Head>
      <div className="parts">
        <div className="list">
          <div className="ctas">
            <span className="text">
              {registrations.length} inscription{registrations.length > 1 ? 's' : ''} • {getTotalPrice().toFixed(2)}€
            </span>
            <button className="btn icon" onClick={(event) => {event.preventDefault();modal.open(<SelectProductModal onSelect={onSelectProduct}/>, { size: 'full' });}}>
              <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M7.75 7.75H19.25L17.6128 14.7081C17.4002 15.6115 16.5941 16.25 15.666 16.25H11.5395C10.632 16.25 9.83827 15.639 9.60606 14.7618L7.75 7.75ZM7.75 7.75L7 4.75H4.75"></path>
                <circle cx="10" cy="19" r="1" fill="currentColor"></circle>
                <circle cx="17" cy="19" r="1" fill="currentColor"></circle>
              </svg>
              Produit
            </button>
            <button className="btn icon" onClick={(event) => {event.preventDefault();modal.open(<SelectClasseModal onSelect={onSelectClasse}/>, { size: 'full' });}}>
              <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4.75 6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V14.25C19.25 15.3546 18.3546 16.25 17.25 16.25H6.75C5.64543 16.25 4.75 15.3546 4.75 14.25V6.75Z"></path>
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M15.25 19.25L12 17.25L8.75 19.25"></path>
              </svg>
              Cours
            </button>
            <button className="btn icon" onClick={(event) => {event.preventDefault();modal.open(<SelectPackageModal onSelect={onSelectPackage}/>, { size: 'full' });}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5.75 8.87 12 12.25M5.75 8.87v7L12 19.25M5.75 8.87l3.125-1.56m9.375 1.56L12 5.75 8.875 7.31m9.375 1.56v7L12 19.25m6.25-10.38-3.125 1.69M12 12.25v7m0-7 3.125-1.69m0 0-6.25-3.25"></path>
              </svg>
              Package
            </button>
          </div>
          <div className="registrations">
            {
              registrations.map(registration => (
                <div key={registration._internalId} className="registration">
                  { registration.alreadyRegistered === true &&
                    <Alert type="red">L'étudiant a déjà une inscription à ce cours !</Alert>
                  }
                  { registration.classe && <>
                    { registration.classe.registrationOpen ? (
                      <>
                        { registration.classe.registrationOpenStartAt && moment().isBefore(moment(registration.classe.registrationOpenStartAt), 'day') ?
                          <Alert type="red">Les inscriptions ne sont pas encore ouvertes !</Alert>
                        : <>
                          { registration.classe.registrationOpenEndAt && moment().isAfter(moment(registration.classe.registrationOpenEndAt), 'day') ?
                            <Alert type="red">Les inscriptions sont fermées !</Alert>
                          : <>
                            
                          </>}
                        </>}
                      </>
                    ) : (
                      <Alert type="red">Les inscriptions sont fermées !</Alert>
                    )}
                  </>}
                  <div className={`head${registration.product ? ' product' : ''}`}>
                    <div className="name">
                      <span style={{ color: 'grey' }}>{registration.classe ? 'Cours' : (registration.package ? 'Package' : 'Produit')}</span> {registration.classe ? `${registration.classe.number} • ${registration.classe.name}` : (registration.package ? registration.package.name : registration.product.name)}
                    </div>
                    { registration.product &&
                      <div className="quantity">
                        <Input label="Quantité:" value={registration.quantity} onChange={onChangeRegistrationQuantity(registration)} min={1} max={9999} type="number" />
                      </div>
                    }
                    <div className="price">
                      { form.data.tarifType === 'specific' ?
                        <div style={{ margin: '-10px 0px' }}>
                          <Input suffix="€" value={registration.rawPrice} onChange={event => onRegistrationPriceUpdate(registration, event)} step="0,1" />
                        </div>
                      : (
                        <>
                          {registration.classe && registration.classe.channel === 'distanciel' && (registration.presentielClasse === true || (registration.classes && registration.classes.length > 0)) ? (
                            <>
                              {registration.presentielClasse === true &&
                                <>
                                  <span style={{ textDecoration: 'line-through red' }}>{registration.classe ? registration.classe[form.data.tarifType] : registration.package[form.data.tarifType]}€</span>&nbsp;{registration.classe.presentielClasse[form.data.tarifType] + 30}€
                                </>
                              }
                              {registration.classes && registration.classes.length === 1 &&
                                <><span style={{ textDecoration: 'line-through red' }}>{registration.classe ? registration.classe[form.data.tarifType] : registration.package[form.data.tarifType]}€</span> 160€</>
                              }
                              {registration.classes && registration.classes.length === 2 &&
                                <><span style={{ textDecoration: 'line-through red' }}>{registration.classe ? registration.classe[form.data.tarifType] : registration.package[form.data.tarifType]}€</span> 200€</>
                              }
                            </>
                          ) : (
                            <>{registration.classe ? registration.classe[form.data.tarifType] : ( registration.package ? registration.package[form.data.tarifType] : registration.product.price ) }€</>
                          )}
                        </>
                      )}
                    </div>
                    <div className="remove" onClick={() => onRemoveRegistration(registration)}>
                      <svg version="1.1" viewBox="0 0 20 20" x="0px" y="0px" style={{ width: '20px', height: '20px' }}><g><path d="M8.5 10L4 5.5 5.5 4 10 8.5 14.5 4 16 5.5 11.5 10l4.5 4.5-1.5 1.5-4.5-4.5L5.5 16 4 14.5 8.5 10z"></path></g></svg>
                    </div>
                  </div>
                  { registration.classe && registration.classe.channel === 'distanciel' && registration.classe.mooc !== true &&
                    <div>
                      Packages distanciels disponibles :
                      <div className="packages" style={{ marginTop: '5px', marginBottom: '20px' }}>
                        <div className="package" onClick={() => chooseDistancielPackageClasse(registration, 'two')}>
                          <form><Radio ghost checked={registration.classes !== undefined && registration.classes.length === 1} /></form>
                          <div>
                            "2&nbsp;<i>Libres Cours</i>" - Je m’inscris à deux cours en distanciel pour un montant de 160€
                            <br />
                            { registration.classes && registration.classes.length === 1 &&
                              <>
                                <br />
                                • <strong>{registration.classes[0].name}</strong>
                              </>
                            }
                          </div>
                        </div>
                        <div className="package" onClick={() => chooseDistancielPackageClasse(registration, 'three')}>
                          <form><Radio ghost checked={registration.classes !== undefined && registration.classes.length === 2} /></form>
                          <div>
                            "3&nbsp;<i>Libres Cours</i>" - Je m’inscris à trois cours en distanciel pour un montant de 200€
                            <br />
                            { registration.classes && registration.classes.length === 2 &&
                              <>
                                <br />
                                • <strong>{registration.classes[0].name}</strong>
                              </>
                            }
                            { registration.classes && registration.classes.length === 2 &&
                              <>
                                <br />
                                • <strong>{registration.classes[1].name}</strong>
                              </>
                            }
                          </div>
                        </div>
                        { registration.classe.presentielClasseId && registration.classe.presentielClasse === undefined &&
                          <div className="package" style={{ justifyContent: 'center' }}>
                            <Loader />
                          </div>
                        }
                        { registration.classe.presentielClasse &&
                          <div className="package" onClick={() => chooseDistancielPresentielClasse(registration)}>
                            <form><Radio ghost checked={registration.presentielClasse === true} /></form>
                            <div style={{ flex: 1 }}>
                              "Distanciel + Présentiel" - Je m’inscris au cours {registration.classe.presentielClasse.name} en distanciel ET en présentiel {form.data.tarifType !== 'specific' && <>pour {registration.classe.presentielClasse[form.data.tarifType]}€ (montant du cours en présentiel + 30€)</>}
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  }
                  { registration.package &&
                    <div className={`classes`}>
                      <div className="cta">
                        <span>Liste des classes • {registration.classes.length} séléctionnée{registration.classes.length > 1 ? 's' : ''}</span>
                      </div>
                      <div className="list">
                        <div className="classes">
                          {
                            registration.classes.map(classe => (
                              <div key={classe.id} className="classe">
                                <span className="name">{classe.number} • {classe.name}</span>
                              </div>
                            ))
                          }
                        </div>
                        { registration.classes.length === 0 &&
                          <div className="no-classes">Aucun cours sélectionnée</div>
                        }
                        <button className="btn full" onClick={(event) => {event.preventDefault();modal.open(<SelectPackageClassesModal registration={registration} onAdded={(classes) => onAddPackageClasses(registration, classes)} />);}}>Modifier les classes</button>
                      </div>
                    </div>
                  }
                  { payers.length > 0 &&
                    <>
                      <label style={{ marginTop: '20px' }}>Payeurs</label>
                      <div className="payers">
                        {
                          payers.sort(payer => payer.amount - payer.amount).map(payer => (
                            <div key={payer.id} style={{ borderRadius: '8px', border: '1px solid #ccc', padding: '20px', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                              <div style={{ flex: 1 }}>
                                <div style={{ fontWeight: 500, fontSize: '1.1em' }}>
                                  { payer.payer.type === 'company' ? (
                                    <>{payer.payer.companyName}</>
                                  ) : (
                                    <>{payer.payer.user.firstname} {payer.payer.user.lastname.toUpperCase()}</>
                                  )}
                                </div>
                                <div style={{ color: 'grey' }}>
                                  {payer.payer.user.email} • {{ company: 'Institution', people: 'Personne', student: 'Étudiant' }[payer.payer.type]}
                                </div>
                              </div>
                              <div>
                                <Input suffix="€" value={registration.payers.find(rPayer => rPayer.id === payer.payer.id) ? registration.payers.find(rPayer => rPayer.id === payer.payer.id).rawAmount : 0} onChange={event => onChangePayerAmount(event, payer, registration)} />
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </>
                  }
                </div>
              ))
            }
          </div>
          { registrations.length === 0 &&
            <div className="empty">
              Aucun cours ou package choisie
            </div>
          }
        </div>
        <div className="infos">
          <Select label="Département bénéficiaire" noSearch {...form.field('department')}>
            <option value="cp">Cours Publics</option>
            <option value="fnd">FND</option>
            <option value="issr">ISSR</option>
            <option value="fr">FEB</option>
            <option value="ccdej">CCDEJ</option>
            <option value="fcf">FCF</option>
            <option value="e-learning">ELEARNING</option>
          </Select>
          <br />
          <Select
            nullable
            label="Étudiant"
            load={async ({ page, search }) => {
              const response = await Api.get('/students', { page, search });
              return { items: response.data };
            }}
            {...form.field('student')}
            noSelectionMessage="Aucun étudiant"
          >
              {(student, onSelect) => (
                <div key={student.id} onClick={onSelect}>
                  {student.user.firstname} {student.user.lastname.toUpperCase()}
                  <br />
                  <span style={{ fontSize: '0.8em', color: 'grey' }}>{student.user.email}</span>
                </div>
              )}
          </Select>
          { form.data.student &&
            <>
              <br />
              <Input label="Code postal" {...form.field('zipcode')} />
            </>
          }
          <br />
          <Select label="Tarif" noSearch {...form.field('tarifType')}>
            <option value="fullPriceTtc">Tarif Institutions</option>
            <option value="individualPriceTtc">Tarif individuel</option>
            <option value="youthPriceTtc">Tarif - 30 ans</option>
            <option value="specific">Spécifique</option>
          </Select>
          <br />
          { form.data.noBillCreation === false &&
            <>
              <label>Payeur{payers.length > 1 ? 's' : ''}</label>
              {
                payers.sort(payer => payer.amount - payer.amount).map(payer => (
                  <div key={payer.payer.id} style={{ borderRadius: '8px', border: '1px solid #ccc', padding: '20px', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                      <div style={{ fontWeight: 500, fontSize: '1.1em' }}>
                        { payer.payer.type === 'company' ? (
                          <>{payer.payer.companyName}</>
                        ) : (
                          <>{payer.payer.user.firstname} {payer.payer.user.lastname.toUpperCase()}</>
                        )}
                      </div>
                      <div style={{ color: 'grey' }}>
                        {payer.payer.user.email} • {{ company: 'Institution', people: 'Personne', student: 'Étudiant' }[payer.payer.type]}
                      </div>
                    </div>
                    <div style={{ flex: '0 0 auto', textAlign: 'right' }}>
                      <span style={{ fontWeight: 500, fontSize: '1.1em' }}>{(registrations.reduce((amount, registration) => amount + (registration.payers.find(rPayer => rPayer.id === payer.payer.id) ? (registration.payers.find(rPayer => rPayer.id === payer.payer.id).amount || 0) : 0), 0)).toFixed(2)}€</span>
                    </div>
                    <svg style={{ width: '12px', cursor: 'pointer', marginLeft: '10px' }} onClick={() => onPayerRemove(payer.payer)} version="1.1" viewBox="0 0 20 20" x="0px" y="0px"><g><path d="M8.5 10L4 5.5 5.5 4 10 8.5 14.5 4 16 5.5 11.5 10l4.5 4.5-1.5 1.5-4.5-4.5L5.5 16 4 14.5 8.5 10z"></path></g></svg>
                  </div>
                ))
              }
              { payers.length > 0 &&
                <div style={{ paddingBottom: '2px', fontSize: '0.9em', paddingLeft: '4px' }}>L'étudiant paiera le reste dû :</div>
              }
              <div style={{ borderRadius: '8px', background: '#E9E9E9', cursor: 'not-allowed', border: '1px solid #ccc', padding: '20px', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                  <div style={{ fontWeight: 500, fontSize: '1.1em' }}>
                    { form.data.student !== null &&
                      <>
                        {form.data.student.user.firstname} {form.data.student.user.lastname.toUpperCase()}
                      </>
                    }
                  </div>
                  <div style={{ color: 'grey' }}>
                    Étudiant
                  </div>
                </div>
                <div style={{ flex: '0 0 auto', textAlign: 'right' }}>
                  <span style={{ fontWeight: 500, fontSize: '1.1em' }}>{(getTotalPrice() - registrations.reduce((amount, registration) => amount + registration.payers.reduce((a, rPayer) => a+ rPayer.amount || 0, 0), 0)).toFixed(2)}€</span>
                </div>
              </div>
              <button className="btn full" onClick={(event) => {event.preventDefault();modal.open(<CreatePayerModal onCreated={registration => onPayerCreated(registration)} />);}}>Ajouter un payeur</button>
              <br />
            </>
          }
          { form.data.draft === false &&
          <>
            <br />
            <Checkbox label="Ne pas générer de factures" {...form.field('noBillCreation', { type: 'checkbox' })} />
            <br />
            <Checkbox label="Ne pas envoyer d’email de confirmation à l’étudiant" {...form.field('noEmail', { type: 'checkbox' })} />
            </>
          }
        </div>
      </div>
    </form>
  );
}
