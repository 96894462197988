import { useState, useRef } from "react";
import validatejs from 'validate.js';
import _ from 'lodash';
import moment from 'moment';
import Validator from 'validator';

import './form.scss';

export default function useForm(defaultData, onSubmit) {
  const _defaultData = useRef(_.cloneDeep(defaultData)); // Used to reset data
  const [data, setData] = useState(defaultData || {});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  let validators = {};

  function setValue(key, value) {
    setData(prevData => {
      const moist = Object.assign({}, prevData);
      // Input can have multiple index like DateRangePicker
      if (Array.isArray(key)) {
        key.forEach((subKey, index) => {
          _.set(moist, subKey, typeof value[index] === 'function' ? value[index](_.get(moist, subKey)) : value[index]);
        });
      } else {
        _.set(moist, key, typeof value === 'function' ? value(_.get(moist, key)) : value);
      }
      return moist;
    });
  }

  function validate(data, validators) {
    const result = { errors: {}, data: {}, hasErrors: false };
    for (const [key, { validator, message }] of Object.entries(validators)) {
      const value = _.get(data, key);
      _.set(result.data, key, typeof value === 'string' ? Validator.trim(value) : value);
      if (!validator(typeof value === 'string' ? Validator.trim(value) : value)) {
        result.hasErrors = true;
        _.set(result.errors, key, message);
      }
    }
    return result;
  }

  return {
    reset: () => {
      setData(_defaultData.current);
    },
    errors,
    data,
    isLoading,
    setErrors,
    onSubmit: event => {
      event.preventDefault();
      setIsLoading(true);

      // Manage multi dimensional data (validatorjs is buggy with key like `array[0].value`)
      const currentErrors = Object.keys(validators)
        .map(key => ({ key, validator: validators[key] }))
        .reduce((errors, { key, validator }) => {
          const err = validatejs({ value: _.get(data, key)}, { value: validator });
          if (err !== undefined) {
            _.set(errors, key, err.value);
          }
          return errors;
        }, {});
      setErrors(currentErrors);

      if (onSubmit && Object.keys(currentErrors).length === 0) {
        onSubmit(data, validate);
      }
      setIsLoading(false);
    },
    setValue,
    field: (key, { type, validator } = {}) => { // integer & float are used to select to change from string value
      if (validator) {
        validators = {...validators, [key]: validator};
      }
      
      let value;
      if (Array.isArray(key)) {
        value = key.map(subKey => {
          let v = _.get(data, subKey);
          if (type === 'date') {
            v = moment(v).format('YYYY-MM-DD');
          }
          return v;
        });
      } else {
        value = _.get(data, key);
        if (type === 'date') {
          value = moment(value).format('YYYY-MM-DD');
        }
      }

      return {
        onChange: event => {
          if (event && event.target) {
            event.stopPropagation();
            event.preventDefault();
            let newValue = null;
            if (type === 'checkbox') {
              newValue = event.target.checked;
            } else if (type === 'date') {
              newValue = moment(event.target.value).toDate();
            } else {
              if (type === 'integer') {
                newValue = parseInt(event.target.value);
              } else if (type === 'float') {
                newValue = event.target.value.replaceAll(new RegExp(/[^0-9.,]*/, 'g'), '')
              } else {
                newValue = event.target.value;
              }
            }
            setValue(key, newValue);
          } else {
            setValue(key, event)
          }
        },
        type: type === 'integer' || type === 'float' ? undefined : type,
        value,
        errors: _.get(errors, key),
        checked: value,
        name: key,
      };
    },
  };
}