import React from 'react';

import './field.scss';

export default function Field({ label, box, children }) {
  return (
    <div className="field-component">
      <div className={`label${box ? ' box' : ''}`}>
        {label}
      </div>
      <div className="value">
        {children}
      </div>
    </div>
  );
}