import React from 'react';

import './dashboard.scss';

export default function Dashboard() {

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1, padding: '16px', minHeight: '100vh', outline: 'none' }}>
      
      </div>
    </div>
  );
};
