import React from 'react';
import { Head } from '../../components/head';
import { Input, Select, Textarea, useForm } from '../../hooks/form';
import { useNotification } from '../../hooks/notification/Notification';
import { useRouter } from '../../hooks/router';
import Api from '../../services/Api';

export default function PackageCreate() {
  const router = useRouter();
  const notification = useNotification();
  const form = useForm({
    name: '',
    description: '',
    program: null,
    firstClasseAt: null,
    lastClasseAt: null,
    number: '',
    productCode: '',
  }, (data) => {
    if (data.firstClasseAt === null || data.lastClasseAt === null) {
      return alert('Veuillez mettre une date de début et de fin de package !');
    }
    Api.post('/packages', data)
      .then(response => {
        notification.notify('Le package a bien été créé !');
        router.goTo('package', { id: response.data.id });
      }).catch(() => {
        // @todo error when email is used
        alert('Une erreur est survenue');
      });
  });

  return (
    <div className="ressource-page">
      <Head noTabs title="Nouveau package" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px', maxWidth: '450px' }}>
        <Input label="Nom du package" {...form.field('name')} />
        <br/>
        <Textarea label="Description du package" {...form.field('description')} />
        <br />
        <Input label="Numéro du package" {...form.field('number')} />
        <br />
        <Input label="Code produit" {...form.field('productCode')} />
        <br/>
        <Select noSearch {...form.field('program')}>
          <option value={null}>Choisir un département</option>
          <option value="fnd">FND</option>
          <option value="issr">ISSR</option>
          <option value="ccdej">CCDEJ</option>
          <option value="fr">FEB</option>
          <option value="other">Autre</option>
        </Select>
        <br />
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <Input label="Date du premier cours" {...form.field('firstClasseAt', { type: 'date' })} />
          </div>
          <div style={{ flex: 1, marginLeft: '20px' }}>
            <Input label="Date du dernier cours" {...form.field('lastClasseAt', { type: 'date' })} />
          </div>
        </div>
        <br/>
        <button className="btn">Enregistrer</button>
      </form>
    </div>
  );
}
