import React from 'react';

import './alert.scss';

export default function Alert({ children, type }) {
  return (
    <div className={`alert-component${type ? ` ${type}` : ''}`}>
      {children}
    </div>
  );
}