import React, { useState } from 'react';

import './fileList.scss';
import Api from '../../services/Api';
import { Loader } from '../loader';
import { File } from '../file';
import { useList } from '../../hooks/list';
import { useNotification } from '../../hooks/notification/Notification';
import { useUpload } from '../../hooks/form';

export default function FileList({ entity, entityId }) {
  const notification = useNotification();
  const { items: files, isLoading, refresh } = useList(`${entity}/${entityId}/files`, {
    limit: 5
  });
  const [uploadState, setUploadState] = useState({ uploading: false, file: null, percent: 0 });
  const { start } = useUpload({ onUpload: (file) => {
    setUploadState({ file, uploading: true, percent: 0 });
    Api.upload(`/${entity}/${entityId}/files`, file, ({ percent }) => {
      setUploadState(prevState => ({ ...prevState, percent }));
    }).then(response => {
        refresh();
        notification.notify('Le document a été ajouté !');
        setUploadState({ uploading: false, file: null, percent: 0 });
      }).catch(error => {
        console.log(error);
        setUploadState({ uploading: false, file: null, percent: 0 });
        alert('Une erreur est survenue');
      });
  } });

  function onFileDeleted() {
    refresh();
    notification.notify('Le fichier a été supprimé !');
  }

  return (
    <>
      <h3 style={{ margin: '0px', marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Document{files.length > 1 && <>s</>}
        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => start()} style={{ width: '12px', height: '12px', fill: '#520b4e', cursor: 'pointer' }} height="426.66667pt" viewBox="0 0 426.66667 426.66667" width="426.66667pt"><path d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0"/></svg>
      </h3>
      { isLoading &&
        <div style={{ marginTop: '10px', marginBottom: '20px' }}>
          <Loader />
        </div>
      }
      { uploadState.uploading &&
        <File file={uploadState.file} percent={uploadState.percent} />
      }
      {
        files.map(file => (
          <File key={file.id} file={file} onDeleted={() => onFileDeleted()} />
        ))
      }
      { files.length >= 5 && // @todo get the count of files to show this
        <button className="btn center grey" style={{ marginTop: '10px' }}>Voir plus</button>
      }
      { !isLoading && files.length === 0 &&
        <div style={{ color: 'grey', textAlign: 'center', marginTop: '10px', marginBottom: '20px' }}>
          Aucun
        </div>
      }
    </>
  )
}