import React from 'react';
import { useRouter } from './Router';
import { useModal } from '../modal/Modal';
import { queryToUrl } from './utils';

export default function Link(props) {
  const router = useRouter();
  const modal = useModal();

  function onClick(event) {
    // On MacOS metaKey is the "command" key for opening in a new tab
    // @todo see if it works on other OS (Windows & Linux)
    if (!event.metaKey) {
      event.preventDefault();
      event.stopPropagation();
      if (props.disabled) {
        return;
      }
      modal.closeAll();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      router.goTo(props.to, props.params, props.query);
    }
  };

  return (
    <a
      className={`${props.className || ''}${props.disabled ? ' disabled' : ''}`}
      style={props.style}
      onContextMenu={props.onContextMenu}
      href={router.getUrl(router.getRoute(props.to, props.params)) + queryToUrl(props.query)}
      onClick={onClick}
    >
      {props.children}
    </a>
  );
};