import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Field } from '../../../components/field';
import { Head } from '../../../components/head';
import { Loader } from '../../../components/loader';
import { Input, Select, Textarea, useForm } from '../../../hooks/form';
import { useModal } from '../../../hooks/modal/Modal';
import { useNotification } from '../../../hooks/notification/Notification';
import Api from '../../../services/Api';

function SelectShopifyProduct({ onSelected }) {
  const modal = useModal();
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    Api.get(`/shopify/products?limit=250`)
      .then((response) => {
        setProducts(response.data);
      });
  }, []);

  function onSelectedVariant(variant, product) {
    onSelected({
      shopifyVariantId: variant.id,
      shopifyProductId: product.id,
      shopifyImageUrl: product?.image?.src,
      shopifyProductTitle: product.title,
      shopifyVariantTitle: product.options.map((option, cpt) => `${option.name} : ${variant[`option${cpt + 1}`]}`).join(', '),
    });
    modal.closeAll();
  }

  return (
    <div className="shopify-product-selector">
      <form className="head">
        <Input placeholder="Rechercher..." value={search} onChange={value => setSearch(value.target.value)} />
      </form>
      <div className="products">
      { products.length === 0 &&
        <div>
          <br />
          <Loader />
          <br /><br /><br /><br /><br />
        </div>
      }
      {
        products.filter(product => {
          if (search.trim() !== '') {
            return product.title.includes(search.trim());
          }
          return true;
        }).map(product => (
          <div className="product">
            { product.image ? <img src={product.image.src} alt="" /> : <div className="fake-img"></div> }
            <div className="infos">
              <div className="title">
                {product.title}<small>&nbsp;• {product.status}</small>
              </div>
              <div className="variants">
                {
                  product.variants.map(variant => (
                    <div className="variant" onClick={() => onSelectedVariant(variant, product)}>
                      <div className="name">Variant #{variant.id}<small>&nbsp;• {product.status}</small></div>
                      {
                        product.options.map((option, cpt) => (
                          <div className="options">
                            <div className="option">
                              {option.name} : {variant[`option${cpt + 1}`]}
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        ))
      }
      </div>
    </div>
  );
}

function UpdateInformations({ product, onUpdated }) {
  const notification = useNotification();
  const modal = useModal();
  const form = useForm({ ...product.user, ...product }, (data) => {
    Api.post(`/products/${product.id}`, data)
      .then(response => {
        notification.notify('Les informations ont été mise à jour !');
        onUpdated(response.data);
        modal.close();
      })
      .catch((error) => {
        console.log(error);
        alert('Une erreur est survenue');
      });
  });

  return (
    <div className="ressource-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Modifier les informations" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        <Input label="Code" {...form.field('code')} explain="Sera utilisé sur les factures" />
        <br />
        { product.externalService !== 'etipi' &&
          <>
            <Input label="Nom" {...form.field('name')} />
            <br/>
            <Textarea label="Description" {...form.field('description')} />
            <br/>
          </>
        } 
        <Input type="number" label="Prix" {...form.field('price', { type : 'number' })} suffix="€" />
        { form.data.type === 'audio' &&
          <>
            <br/>
            <Input label="Url" {...form.field('url')} />
          </>
        }
        <br />

        <Select label="Département programmateur" noSearch {...form.field('department')}>
          <option value={null}>Aucun département</option>
          <option value="public">Cours Publics</option>
          <option value="e-learning">E-learning</option>
          <option value="fnd">FND</option>
          <option value="issr">ISSR</option>
          <option value="fr">FEB</option>
          <option value="ccdej">CCDEJ</option>
          <option value="fcf">FCF</option>
          <option value="meal">Repas</option>
          <option value="other">Autre</option>
        </Select>
        <br/>
        <button className="btn full">Enregistrer</button>
      </form>
    </div>
  );
}

export default function ProductRessourceOverview({ product, setProduct }) {
  const modal = useModal();
  const notification = useNotification();

  function onUpdated(product) {
    setProduct(product);
  }

  function onShopifyProductSelected(shopifyProduct) {
    Api.post(`/products/${product.id}/shopify-products`, shopifyProduct)
      .then(response => {
        setProduct(response.data);
        notification.notify('Le produit a été synchronisé !');
      }).catch(error => {
        alert('Une erreur est survenue');
      });
  }

  function onShopifyProductRemove(shopifyProduct) {
    Api.del(`/products/${product.id}/shopify-products/${shopifyProduct.id}`)
      .then(response => {
        setProduct(response.data);
        notification.notify('La synchronisation a été retirée !');
      }).catch(error => {
        alert('Une erreur est survenue');
      });
  }

  return (
    <div className="ressource-page" style={{ padding: '20px', display: 'flex', flexDirection: 'row', gap: '16px' }}>
      <div style={{ flex: 1 }}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '16px' }}>
          { product.externalService === 'etipi' &&
            <div style={{ border: '1px solid green', padding: '20px', borderRadius: '8px', background: '#d2f5d2', color: 'green' }}>
              Produit géré par Etipi
            </div>
          }
          
          <div style={{ padding: '16px', borderRadius: '8px', background: '#F9F9F9', border: '1px solid #E9E9E9' }}>
            <h3 style={{ display: 'flex', justifyContent: 'space-between', margin: '0px', paddingBottom: '8px', marginBottom: '16px', borderBottom: '1px solid #E9E9E9' }}><span>Informations</span><small style={{ color: '#007bff', cursor: 'pointer', fontWeight: 500 }} onClick={() => modal.open(<UpdateInformations product={product} onUpdated={product => onUpdated(product)} />)}>Modifier</small></h3>
           
            <Field label="Type">
              {{ meal: 'Repas', audio: 'Cours audio', certificate: 'Attestation', other: 'Autre' }[product.type]}
            </Field>
            { product.type === 'certificate' &&
              <>
                <Field label="Code Etipi">
                  {product.externalId}
                </Field>
                <Field label="Url d'achat">
                  {/* <a href={`https://student.bernardins.test.qdeneuve.com/campus/certificate/${product.externalId}`}>https://student.bernardins.test.qdeneuve.com/campus/certificate/{product.externalId}</a> */}
                  <a href={`https://alpha.collegedesbernardins.fr/campus/certificate/${product.externalId}`}>https://alpha.collegedesbernardins.fr/campus/certificate/{product.externalId}</a>
                </Field>
                <Field label="Url de redirection Etipi">
                  <a href={product.externalRedirectUrl}>{product.externalRedirectUrl}</a>
                </Field>
              </>
            }
            { product.externalService !== 'etipi' &&
              <Field label="Name">
                {product.name}
              </Field>
            }
            { product.type === 'audio' &&
              <>
                <Field label="Url du cours audio">
                  <a href={product.url}>{product.url}</a>
                </Field>
                <Field label="Url d'achat">
                  {/* <a href={`https://student.bernardins.test.qdeneuve.com/campus/audio/${product.id}`}>https://student.bernardins.test.qdeneuve.com/campus/audio/{product.id}</a> */}
                  <a href={`https://alpha.collegedesbernardins.fr/campus/audio/${product.id}`}>https://alpha.collegedesbernardins.fr/campus/audio/{product.id}</a>
                </Field>
              </>
            }
            <Field label="Code">
              {product.code}
            </Field>
            <Field label="Description">
                {product.description || <span style={{ color: 'grey' }}>Aucune</span>}
            </Field>
            <Field label="Prix">
              {product.price}€
            </Field>
            <Field label="Département programmateur">
              {product.department}
            </Field>
          </div>
        </div>
      </div>
      <div style={{ width: '350px', flex: '0 0 auto' }}>
        <h3 style={{ margin: '0px', marginTop: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Produit{product.shopifyProducts.length > 1 && <>s</>} Shopify
          <svg xmlns="http://www.w3.org/2000/svg" onClick={() => modal.open(<SelectShopifyProduct onSelected={onShopifyProductSelected} excludeIds={product.shopifyProducts.map(p => p.id)} />)} style={{ width: '12px', height: '12px', fill: '#520b4e', cursor: 'pointer' }} height="426.66667pt" viewBox="0 0 426.66667 426.66667" width="426.66667pt"><path d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0"/></svg>
        </h3>
        {
          product.shopifyProducts.map(shopifyProduct => (
            <div key={shopifyProduct.id} style={{ marginTop: '10px', marginBottom: '10px', borderRadius: '8px', border: '1px solid #ccc', display: 'flex', alignItems: 'center', gap: '16px', position: 'relative' }}>
              { shopifyProduct.shopifyImageUrl ? <img style={{ width: '40px', height: '60px', flex: '0 0 auto', objectFit: 'cover' }} src={shopifyProduct.shopifyImageUrl} alt="" /> : <div className="fake-img"></div> }
              <div style={{ flex: 1, padding: '10px 0px' }}>
                <a target="_blank" rel="noopener noreferrer" href={`https://college-des-bernardins.myshopify.com/admin/products/${shopifyProduct.shopifyProductId}`}>{shopifyProduct.shopifyProductTitle}</a>
                <br />
                • {shopifyProduct.shopifyVariantTitle}
              </div>
              <div style={{ padding: '10px', cursor: 'pointer', flex : '0 0 auto' }} onClick={() => onShopifyProductRemove(shopifyProduct)}>
                <svg style={{ width: '12px' }} version="1.1" viewBox="0 0 20 20" x="0px" y="0px"><g><path d="M8.5 10L4 5.5 5.5 4 10 8.5 14.5 4 16 5.5 11.5 10l4.5 4.5-1.5 1.5-4.5-4.5L5.5 16 4 14.5 8.5 10z"></path></g></svg>
              </div>
            </div>
          ))
        }
        { product.shopifyProducts.length === 0 &&
          <div style={{ color: 'grey', textAlign: 'center', marginTop: '10px', marginBottom: '20px' }}>
            Aucun
          </div>
        }
      </div>
    </div>
  );
}
