import React from 'react';
import moment from 'moment';

import { Link } from '../../hooks/router';
import { Input, Select } from '../../hooks/form';

import { Head } from '../../components/head';
import { List, useList } from '../../hooks/list';

export default function PackagesList() {
  const { filters, items, isLoading, load } = useList('packages', {
    search: '',
    year: 2023,
    period: null,
    program: null
  });

  return (
    <div className="list-page">
      <Head title="Packages">
        <Link to="createPackage" className="btn">Nouveau</Link>
      </Head>
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
        <div className="filter" style={{ minWidth: '220px' }}>
          <Select noSearch {...filters.field('program')}>
            <option value={null}>Tous les départements</option>
            <option value="ccdej">CCDEJ</option>
            <option value="fnd">FND</option>
            <option value="fr">FEB</option>
            <option value="issr">ISSR</option>
            <option value="other">Autre</option>
          </Select>
        </div>
        <div className="filter" style={{ minWidth: '120px' }}>
          <Select noSearch {...filters.field('year')}>
            <option value={null}>Toutes années</option>
            <option value={2021}>2021 - 2022</option>
            <option value={2022}>2022 - 2023</option>
            <option value={2023}>2023 - 2024</option>
            <option value={2024}>2024 - 2025</option>
            <option value={2025}>2025 - 2026</option>
            <option value={2026}>2026 - 2027</option>
            <option value={2027}>2027 - 2028</option>
            <option value={2028}>2028 - 2029</option>
            <option value={2029}>2029 - 2030</option>
            <option value={2030}>2030 - 2031</option>
            <option value={2031}>2031 - 2032</option>
          </Select>
        </div>
        <div className="filter" style={{ minWidth: '120px' }}>
          <Select noSearch {...filters.field('period')}>
            <option value={null}>Toutes périodes</option>
            <option value="annual">Annuel</option>
            <option value="first-semester">1er semestre</option>
            <option value="second-semester">2nd semestre</option>
          </Select>
        </div>
      </form>
      <List onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Nom', sort: 'name' }, { name: 'Créée le', sort: 'createdAt' }, { name: 'Programme' }, { name: 'Séances' }]}>
        { items.map(classePackage => (
          <Link key={classePackage.id} className="item" to="package" params={{ id: classePackage.id }}>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '10px', borderRadius: '10px', background: 'green', width: '10px', height: '10px', flex: '0 0 auto' }}></div>
              <span>
                {classePackage.name}
              </span>
            </div>
            <div className="prop">
              {moment(classePackage.createdAt).format('DD/MM/YYYY HH:mm:ss')}
            </div>
            <div className="prop">
              {classePackage.program ? classePackage.program.toUpperCase() : 'n/a'}
            </div>
            <div className="prop">
              {classePackage.period ? { annual: 'Annuel', 'first-semester': '1er semestre', 'second-semester':'2nd semestre'}[classePackage.period] : ''} {classePackage.period && classePackage.year && <>•</>} {classePackage.year ? `${classePackage.year} - ${classePackage.year+1}` : ''}
            </div>
          </Link>
        ))}
      </List>
    </div>
  );
};