import React from 'react';

import { Link, Route, useRouter } from '../../hooks/router';

import { Tabs, Tab } from '../../components/tabs';
import { Head } from '../../components/head';
import { Loader } from '../../components/loader';
import useRessource from '../../hooks/ressource/useRessource';
import { useModal } from '../../hooks/modal/Modal';
import Api from '../../services/Api';
import { useNotification } from '../../hooks/notification/Notification';

function DeleteStudent({ student }) {
  const modal = useModal();
  const notification = useNotification();
  const router = useRouter();

  function onDelete() {
    Api.del(`/students/${student.id}`)
      .then(response => {
        notification.notify('L\'étudiant a été supprimé !');
        router.goTo('students');
        modal.close();
      }).catch(error => {
        alert('An error occured');
      })
  }

  return (
    <div className="list-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Êtes-vous certains de supprimer cet étudiant ?" />
      <div>
        <div style={{ fontSize: '0.9em', color: 'red', padding: '20px' }}>
          <div>• Il ne pourra plus se connecter</div>
          <div>• Il n'apparaitra plus dans les listes d'étudiants</div>
          <div>• Les inscriptions validées ou en attente seront annulées</div>
          <div>• Ses documents ne seront plus accessibles</div>
        </div>
      </div>
      <div style={{ padding: '20px', display: 'flex', justifyContent: 'flex-end', borderTop: '1px solid #ccc', borderRadius: '0px 0px 8px 8px' }}>
        <button className="btn" onClick={() => modal.close()} style={{ marginRight: '20px' }}>Annuler</button>
        <button className="btn red" onClick={() => onDelete()}>Supprimer</button>
      </div>
    </div>
  );
}

export default function StudentRessource({ route }) {
  const modal = useModal();
  const { student, isLoading, set } = useRessource('student', route.params.id);

  return (
    <div className="ressource-page">
      <Head prefix="Étudiant" title={`${student.user ? student.user.firstname : ''} ${student.user ? student.user.lastname.toUpperCase() : ''}`} isLoading={isLoading}>
        { student && student.status !== 'deleted' &&
          <>
            <Link className="btn" to="createRegistration" query={{ studentId: route.params.id }}>Nouvelle inscription et/ou achat de produit</Link>
            <button className="btn red" onClick={() => modal.open(<DeleteStudent student={student} />)}>Supprimer</button>
          </>
        }
      </Head>
      <Tabs route={route.subRoute} params={{ id: route.params.id }}>
        <Tab to="student" routeName="_default">Aperçu</Tab>
        <Tab to="student/classes" routeName="classes">Cours</Tab>
        <Tab to="student/registrations" routeName="registrations">Inscriptions</Tab>
        <Tab to="student/products" routeName="products">Produits</Tab>
      </Tabs>
      { isLoading ? <Loader /> : <Route route={route.subRoute} student={student} setStudent={set} /> }
    </div>
  );
}
