import React from 'react';
import moment from 'moment';

import './file.scss';
import Api from '../../services/Api';

export default function File({ file, onDeleted, percent }) {
  function onRemove() {
    Api.del(`/files/${file.id}`)
      .then(response => {
        onDeleted();
      })
      .catch(error => {
        alert('Une erreur est survenue');
      })
  }

  function isImage(type) {
    const keys = type.split('/');
    if (keys.length === 0) {
      return false;
    }
    return keys[0] === 'image';
  }

  return (
    <div className="file-component">
      { isImage(file.type) && <div className="preview" style={{ backgroundImage: `url(${file.url})` }} /> }
      <div className="infos">
        <a target="_blank" rel="noopener noreferrer" href={file.url} className="filename" title={file.filename || file.name}>{file.filename || file.name}</a>
        <br/>
        { percent ? (
          <div style={{ borderRadius: '2px', flex: 1, background: '#F9F9F9' }}>
            <div style={{ height: '17px', width: `${percent}%`, borderRadius: '2px', background: '#D9D9D9' }}></div>
          </div>
        ) : (
          <div className="detail">Uploadé le {moment(file.createdAt).format('DD/MM/YYYY HH:mm:ss')}</div>
        )}
        <span className="detail">{Math.round(file.size/1024)}Ko • {file.type}</span>
      </div>
      <div className="actions">
        <svg style={{ width: '12px', cursor: 'pointer' }} onClick={() => onRemove()} version="1.1" viewBox="0 0 20 20" x="0px" y="0px"><g><path d="M8.5 10L4 5.5 5.5 4 10 8.5 14.5 4 16 5.5 11.5 10l4.5 4.5-1.5 1.5-4.5-4.5L5.5 16 4 14.5 8.5 10z"></path></g></svg>    
      </div>
    </div>
  )
}