import React from 'react';
import { Field } from '../../../components/field';
import { Head } from '../../../components/head';
import { Input, useForm } from '../../../hooks/form';
import { useModal } from '../../../hooks/modal/Modal';
import { useNotification } from '../../../hooks/notification/Notification';
import Api from '../../../services/Api';

function formatDefaultData(payer) {
  const d = { ...payer.user, ...payer };
  if (d.firstname === null) {
    d.firstname = payer.user.firstname;
  }
  if (d.lastname === null) {
    d.lastname = payer.user.lastname;
  }

  return d;
}

function UpdateInformations({ payer, onUpdated }) {
  const notification = useNotification();
  const modal = useModal();
  const form = useForm(formatDefaultData(payer), (data) => {
    Api.post(`/payers/${payer.id}`, data)
      .then(response => {
        notification.notify('Les informations ont été mise à jour !');
        onUpdated(response.data);
        modal.close();
      })
      .catch(() => {
        alert('Une erreur est survenue');
      });
  });

  return (
    <div className="ressource-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Modifier les informations" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        { payer.type === 'company' && <>
          <Input label="Raison sociale" {...form.field('companyName')} />
          <br/>
        </>}
        <Input label="Email" {...form.field('email')} />
        <br/>
        <Input label="Nom" {...form.field('lastname')} />
        <br/>
        <Input label="Prénom" {...form.field('firstname')} />
        <br />
        <Input label="Téléphone" {...form.field('phoneNumber')} />
        <br/>
        <Input label="Addresse" {...form.field('address')} />
        <br/>
        <Input label="Code postal" {...form.field('zipcode')} />
        <br/>
        <Input label="Ville" {...form.field('city')} />
        <br/>
        <button className="btn full">Enregistrer</button>
      </form>
    </div>
  );
}

export default function PayerRessourceOverview({ route, payer, setPayer }) {
  const modal = useModal();

  function onUpdated(payer) {
    setPayer(payer);
  }

  return (
    <div className="ressource-page" style={{ padding: '20px' }}>
      <h3 style={{ margin: '0px', marginBottom: '10px' }}>
        Informations
        <small style={{ color: '#007bff', cursor: 'pointer', fontWeight: 500 }} onClick={() => modal.open(<UpdateInformations payer={payer} onUpdated={payer => onUpdated(payer)} />)}> • modifier</small>
      </h3>
      <Field label="Type">
        {{ company: 'Institution', student: 'Étudiant' }[payer.type]}
      </Field>
      { payer.type === 'company' &&
        <Field label="Raison sociale">
          {payer.companyName}
        </Field>
      }
      <Field label="Nom">
        {payer.lastname || payer.user.lastname}
      </Field>
      <Field label="Prénom">
        {payer.firstname || payer.user.firstname}
      </Field>
      <Field label="Téléphone">
        {payer.phoneNumber}
      </Field>
      <Field label="Email">
        {payer.user.email || 'n/a'}
      </Field>
      <Field label="Adresse">
        { !payer.address && !payer.zipcode && !payer.city ? (
          <>
            <span style={{ color: 'red' }}>Le payeur n'a pas d'adresse renseignée.</span>
            <br />
            <span>En tant qu'étudiant, cela sera son addresse d'étudiant qui sera utilisée.</span>
          </>
        ) : (
          <>
            {payer.address || 'n/a'}
            <br />
            {payer.zipcode || 'n/a'}
            <br />
            {payer.city || 'n/a'}
          </>
        )}
      </Field>
    </div>
  );
}
