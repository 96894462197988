import React from 'react';

import { Route } from '../../hooks/router';

import { Tabs, Tab } from '../../components/tabs';
import { Head } from '../../components/head';
import { Loader } from '../../components/loader';
import useRessource from '../../hooks/ressource/useRessource';

export default function ProfessorRessource({ route }) {
  const { professor, isLoading, set } = useRessource('professor', route.params.id);

  return (
    <div className="ressource-page">
      <Head prefix="Professeur" title={`${professor.user ? professor.user.firstname : ''} ${professor.user ? professor.user.lastname.toUpperCase() : ''}`} isLoading={isLoading} />
      <Tabs route={route.subRoute} params={{ id: route.params.id }}>
        <Tab to="professor" routeName="_default">Aperçu</Tab>
        <Tab to="professor/classes" routeName="classes">Cours</Tab>
      </Tabs>
      { isLoading ? <Loader /> : <Route route={route.subRoute} professor={professor} setProfessor={set} /> }
    </div>
  );
}
