import React from 'react';
import { Field } from '../../../components/field';
import { Head } from '../../../components/head';
import { Input, useForm } from '../../../hooks/form';
import { useModal } from '../../../hooks/modal/Modal';
import { useNotification } from '../../../hooks/notification/Notification';
import Api from '../../../services/Api';
import { Alert } from '../../../components/alert';

function Update({ admin, onUpdated }) {
  const notification = useNotification();
  const modal = useModal();
  const form = useForm({ ...admin, ...admin.user }, (data) => {
    Api.post(`/admins/${admin.id}`, data)
      .then(response => {
        notification.notify('Les informations ont été mises à jour !');
        onUpdated(response.data);
        modal.close();
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.code === 'email-exists') {
          form.setErrors({
            email: 'L\'email est déjà utilisé'
          });
        } else {
          alert('Une erreur est survenue');
        }
      });
  });

  return (
    <div className="ressource-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Modifier les informations" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        <Input label="Prénom" {...form.field('firstname')} />
        <br/>
        <Input label="Nom" {...form.field('lastname')} />
        <br/>
        <Input label="Email" {...form.field('email')} />
        <br/>
        <button className="btn full">Enregistrer</button>
      </form>
    </div>
  );
}

function UpdatePassword({ admin, onUpdated }) {
  const notification = useNotification();
  const modal = useModal();
  const form = useForm({ password: '' }, (data) => {
    Api.post(`/admins/${admin.id}/password`, data)
      .then(response => {
        notification.notify('Le mot de passe a été mis à jour !');
        modal.close();
      })
      .catch((error) => {
        alert('Une erreur est survenue');
      });
  });

  return (
    <div className="ressource-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Modifier le mot de passe" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        <Input type="password" label="Mot de passe" {...form.field('password', { type: 'password' })} />
        <br />
        <button className="btn full">Enregistrer</button>
      </form>
    </div>
  );
}

export default function AdminRessourceOverview({ route, admin, setAdmin }) {
  const modal = useModal();
  const notification = useNotification();

  function onUpdated(admin) {
    setAdmin(admin);
  }

  if (admin && admin.status === 'deleted') {
    return (
      <div style={{ padding: '20px' }}>
        <Alert type="red">
          L'administrateur a été supprimé !
        </Alert>
      </div>
    )
  }

  function sendValidateAccountEmail() {
    if (window.confirm('Êtes vous certain d\'envoyer l\'email d\'activation de compte ?')) {
      Api.post(`/admins/${admin.id}/send-activation-email`)
        .then(() => {
          notification.notify(`L'email a bien été envoyé !`)
        })
        .catch(() => alert('Une erreur est survenue'));
    }
  }

  function sendResetPasswordEmail() {
    if (window.confirm('Êtes vous certain d\'envoyer l\'email de changement de mot de passe ?')) {
      Api.post(`/admins/${admin.id}/send-forgot-password-email`)
        .then(() => {
          notification.notify(`L'email a bien été envoyé !`)
        })
        .catch(() => alert('Une erreur est survenue'));
    }
  }

  return (
    <div className="ressource-page" style={{ padding: '20px', display: 'flex', flexDirection: 'row', gap: '16px' }}>
      <div style={{ flex: 1 }}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <div style={{ padding: '16px', borderRadius: '8px', background: '#F9F9F9', border: '1px solid #E9E9E9' }}>
            <h3 style={{ display: 'flex', justifyContent: 'space-between', margin: '0px', paddingBottom: '8px', marginBottom: '16px', borderBottom: '1px solid #E9E9E9' }}><span>Informations</span><small style={{ color: '#007bff', cursor: 'pointer', fontWeight: 500 }} onClick={() => modal.open(<Update admin={admin} onUpdated={admin => onUpdated(admin)} />)}>Modifier</small></h3>
            <Field label="Groupe">
              {admin.group || 'admin'}
            </Field>
            <Field label="Prénom">
              {admin.user.firstname || 'n/a'}
            </Field>
            <Field label="Nom">
              {admin.user.lastname || 'n/a'}
            </Field>
            <Field label="Email">
              {admin.user.email || 'n/a'}
            </Field>
          </div>
        </div>
      </div>
      <div style={{ width: '350px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginBottom: '16px' }}>
          <button className="btn full normal" onClick={() => modal.open(<UpdatePassword admin={admin} onUpdated={onUpdated} />)}>Modifier le mot de passe</button>
          <hr />
          <button className="btn full normal" onClick={() => sendResetPasswordEmail()}>Email d'oublie de mot de passe</button>
          <button className="btn full normal" onClick={() => sendValidateAccountEmail()}>Email d'activation de compte</button>
        </div>
      </div>
      
    </div>
  );
}
