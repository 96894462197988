import React from 'react';

import { Link } from '../../hooks/router';
import { Input } from '../../hooks/form';

import { Head } from '../../components/head';
import { List, useList } from '../../hooks/list';

export default function ProductsList() {
  const { filters, items, isLoading, load } = useList('products', {
    search: '',
  });

  return (
    <div className="list-page">
      <Head title="Produits">
        <div style={{ fontSize: '0.8em', color: "grey" }}>Nb: les attestations et cours audios sont créées dans Etipi</div>
        <Link to="createProduct" className="btn">Nouveau</Link>
      </Head>
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
      </form>
      <List onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Type', sort: 'type' }, { name: 'Code', sort: 'code' }, { name: 'Nom', sort: 'name' }, { name: 'Prix' }, { name: 'Département' }]}>
        { items.map(product => (
          <Link key={product.id} className="item" to="product" params={{ id: product.id }}>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              {{ meal: 'Repas', audio: 'Cours audio', certificate: 'Attestation', other: 'Autre' }[product.type]}
            </div>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              {product.code}
            </div>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              {product.name}
            </div>
            <div className="prop">
              {product.price}€
            </div>
            <div className="prop">
              {product.department}
            </div>
          </Link>
        ))}
      </List>
    </div>
  );
};