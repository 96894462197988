import React from 'react';
import { Loader } from '../loader';

import './head.scss';

export default function Head({ title, sub, children, isLoading, noTabs, prefix }) {
  return (
    <div className={`head-component${sub ? ' sub' : ''}${noTabs ? ' no-tabs' : ''}`}>
      { isLoading ? <Loader /> : (
        <h1>{prefix && <span className="prefix">{prefix}</span>} {title}</h1>
      )}
      { children &&
        <div className="actions">
          {children}
        </div>
      }
    </div>
  )
}