import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Link } from '../../hooks/router';
import { Checkbox, Input, Select, useForm } from '../../hooks/form';

import { Head } from '../../components/head';
import Api from '../../services/Api';
import { useModal } from '../../hooks/modal/Modal';
import { Loader } from '../../components/loader';
import { useNotification } from '../../hooks/notification/Notification';
import { useAuth } from '../../hooks/auth/Auth';

function CreatePaymentModal({ bill, deposit, pay, onCreated }) {
  const notification = useNotification();
  const form = useForm({
    bill,
    deposit,
    amount: bill.amount - bill.amountPaid,
    validateRegistration: false,
  }, (data) => {
    Api.post(`/bills/${bill.id}/pay`, data)
      .then(() => {
        notification.notify('Le paiement a bien été validé !');
        onCreated();
      }).catch(() => {
        alert('Une erreur est survenue');
      })
  });
  
  return (
    <div style={{ width: '100%' }}>
      <Head title={`Valider ${bill.deposit ? 'l\'accompte' : 'le paiement'} de ${bill.type === 'bill' ? 'la facture' : 'l\'avoir'} #${bill.number}`} noTabs />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        { deposit &&
          <>
            <Input suffix="€ TTC" label="Montant" {...form.field('amount', { float: true })} />
            <br/>
          </>
        }
        <Input label="Identifiant de paiement" {...form.field('externalId')} />
        <br/>
        <Select label="Mode de paiement" noSearch {...form.field('paymentMethod')}>
          <option value="web">Web</option>
          <option value="bankcard">CB</option>
          <option value="change">Espèces</option>
          <option value="check">Chèque</option>
          <option value="bank-transfer">Virement</option>
        </Select>
        <br/>
        <button className="btn full">Valider le paiement</button>
      </form>
    </div>
  )
}

function GeneratePrePaymentModal({ bill, onCreated }) {
  const notification = useNotification();
  const form = useForm({
    bill,
    amount: bill.amount - bill.amountPaid,
    validateRegistration: false,
  }, (data) => {
    Api.post(`/bills/${bill.id}/generate`, data)
      .then((response) => {
        notification.notify('Le lien de paiement a bien été généré !');
        onCreated();
      }).catch(() => {
        alert('Une erreur est survenue');
      })
  });
  
  return (
    <div style={{ width: '100%' }}>
      <Head title={`Générer un lien de paiement de la facture #${bill.number}`} noTabs />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        <Input suffix="€ TTC" label="Montant" {...form.field('amount', { float: true })} />
        <br/>
        <button className="btn full">Générer le lien</button>
      </form>
    </div>
  )
}

function UpdatePayerModal({ bill, onUpdated }) {
  const modal = useModal();
  const notification = useNotification();
  const form = useForm({
    payer: bill.payer
  }, (data) => {
    Api.post(`/bills/${bill.id}`, data)
      .then((response) => {
        notification.notify('Le payeur à été modifié !');
        onUpdated(response.data);
        modal.close();
      }).catch(() => {
        alert('Une erreur est survenue');
      })
  });
  
  return (
    <div style={{ width: '100%' }}>
      <Head title={`Modifier le payeur de la facture #${bill.number}`} noTabs />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        <Select
          load={async ({ page, search }) => {
            const response = await Api.get('/payers' , { page, search });
            return { items: response.data };
          }}
          {...form.field('payer')}
          noSelectionMessage="Tous les étudiants"
        >
          {(payer, onSelect) => (
            <div key={payer.id} onClick={onSelect}>
              { payer.type === 'company' ? (
                <>{payer.companyName}</>
              ) : (
                <>{payer.user.firstname} {payer.user.lastname.toUpperCase()}</>
              )}
            </div>
          )}
        </Select>
        <br/>
        <button className="btn full">Modifier le payeur</button>
      </form>
    </div>
  )
}

function CreateCredit({ bill, onCreated, max }) {
  const modal = useModal();
  const notification = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm({
    amount: max || '0',
    registrations: Object.keys(bill.registrations).reduce((a, key) => ({ ...a, [bill.registrations[key].registration.id]: (bill.registrations[key].registration.payers.find(registrationPayer => registrationPayer.billId === bill.id).amount - bill.originCreditRegistrations.filter(oCR => oCR.billRegistrationId === bill.registrations[key].id).reduce((amount, oCR) => amount + oCR.amount, 0)) }), {}),
    products: Object.keys(bill.products).reduce((a, key) => ({ ...a, [bill.products[key].product.id]: (bill.products[key].productPayer.amount - bill.originCreditProducts.filter(oCR => oCR.billProductId === bill.products[key].id).reduce((amount, oCR) => amount + oCR.amount, 0)) }), {}),
    unregisters: Object.keys(bill.registrations).reduce((a, key) => ({ ...a, [bill.registrations[key].registration.id]: false }), {}),
  }, (data) => {
    setIsLoading(true);
    Api.post(`/bills/${bill.id}/credits`, { ...data, amount: Object.values(form.data.registrations).reduce((amount, a) => amount + parseFloat(a, 10), 0) + Object.values(form.data.products).reduce((amount, a) => amount + parseFloat(a, 10), 0) })
      .then((response) => {
        onCreated();
        const credit = response.data;
        notification.notify('L\'avoir a été créé !');
        modal.close();
        modal.open(<BillModal billId={credit.id} billNumber={credit.number} billType={credit.type} />);
        setIsLoading(false);
      }).catch(() => {
        alert('Une erreur est survenue');
        setIsLoading(false);
      })
  });

  return (
    <div style={{ width: '100%' }}>
      <Head title={`Créer un avoir pour la facture #${bill.number}`} noTabs />
      <form onSubmit={form.onSubmit} style={{ padding: '0px 20px' }}>
        <div>
          { bill.registrations.length > 0 &&
            <>
              <h3 style={{  }}>
                Inscription{bill.registrations.length > 1 ? 's' : ''}
              </h3>
              {
                bill.registrations.map(({ id, registration }) => (
                  <div key={id} style={{ borderRadius: '8px', border: '1px solid #ccc', marginTop: '20px', padding: '16px' }}>
                    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                      <div style={{ flex: 1 }}>
                        {registration.student.user.firstname} {(registration.student.user.lastname ? registration.student.user.lastname.toUpperCase() : '')}
                      </div>
                      <div style={{ flex: 1 }}>
                        { registration.classe &&
                          <>
                            Cours {registration.classe.name}
                            <br />
                          </>
                        }
                        { registration.package &&
                          <>
                            Package {registration.package.name}
                            <br />
                            <span style={{ color: 'grey', fontSize: '0.9em', textTransform: 'uppercase' }}>{registration.package.program}</span>
                          </>
                        }
                      </div>
                    </div>
                    { registration.payers.find(registrationPayer => registrationPayer.billId === bill.id).amount - bill.originCreditRegistrations.filter(oCR => oCR.billRegistrationId === id).reduce((amount, oCR) => amount + oCR.amount, 0) > 0 &&
                      <>
                        <div style={{ marginTop: '8px' }}>
                          <Input max={registration.payers.find(registrationPayer => registrationPayer.billId === bill.id).amount - bill.originCreditRegistrations.filter(oCR => oCR.billRegistrationId === id).reduce((amount, oCR) => amount + oCR.amount, 0)} min={0} suffix="€ TTC" {...form.field(`registrations.${registration.id}`, { float: true })} type="number" step="0.01" />
                          <div style={{ color: 'red', fontSize: '0.8em', padding: '0px 5px' }}>
                            • Vous ne pouvez faire un avoir que entre 0€ et {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(registration.payers.find(registrationPayer => registrationPayer.billId === bill.id).amount - bill.originCreditRegistrations.filter(oCR => oCR.billRegistrationId === id).reduce((amount, oCR) => amount + oCR.amount, 0))}.
                          </div>
                          { bill.originCreditRegistrations.filter(oCR => oCR.billRegistrationId === id).length > 0 &&
                            <div style={{ color: 'green', fontSize: '0.8em', padding: '0px 5px' }}>
                              • Vous avez déjà fait {bill.originCreditRegistrations.filter(oCR => oCR.billRegistrationId === id).length} avoir{bill.originCreditRegistrations.filter(oCR => oCR.billRegistrationId === id).length > 1 ? 's' : ''} pour un montant de {bill.originCreditRegistrations.filter(oCR => oCR.billRegistrationId === id).reduce((amount, oCR) => amount + oCR.amount, 0)} €.
                            </div>
                          }
                        </div>
                      <br />
                      <Checkbox confirmMessage="Attention : si cette inscription a été faite avec une facture vous devez d’abord faire un avoir. Voulez-vous confirmer la désinscription ?" {...form.field(`unregisters.${registration.id}`, { type: 'checkbox' })} label="Désinscrire l'étudiant" />
                      </>
                    }
                    { registration.payers.find(registrationPayer => registrationPayer.billId === bill.id).amount <= 0 && registration.status !== 'canceled' && // If amount paid === 0, we can unsubscribe
                      <>
                        <br />
                        <Checkbox confirmMessage="Attention : si cette inscription a été faite avec une facture vous devez d’abord faire un avoir. Voulez-vous confirmer la désinscription ?" {...form.field(`unregisters.${registration.id}`, { type: 'checkbox' })} label="Désinscrire l'étudiant" />
                      </>
                    }
                    { registration.payers.find(registrationPayer => registrationPayer.billId === bill.id).amount > 0 && registration.payers.find(registrationPayer => registrationPayer.billId === bill.id).amount - bill.originCreditRegistrations.filter(oCR => oCR.billRegistrationId === id).reduce((amount, oCR) => amount + oCR.amount, 0) <= 0 &&
                      <div style={{ marginTop: '8px' }}>
                        Le montant total de l'inscription a été remboursé:
                        { bill.originCreditRegistrations.filter(oCR => oCR.billRegistrationId === id).length > 0 &&
                          <div style={{ color: 'green', fontSize: '0.8em', padding: '0px 5px', paddingTop: '8px' }}>
                            • Vous avez déjà fait {bill.originCreditRegistrations.filter(oCR => oCR.billRegistrationId === id).length} avoir{bill.originCreditRegistrations.filter(oCR => oCR.billRegistrationId === id).length > 1 ? 's' : ''} pour un montant de {bill.originCreditRegistrations.filter(oCR => oCR.billRegistrationId === id).reduce((amount, oCR) => amount + oCR.amount, 0)} €.
                          </div>
                        }
                      </div>
                    }
                  </div>
                ))
              }
            </>
          }
          { bill.products.length > 0 &&
            <>
              <h3 style={{  }}>
                Produit{bill.products.length > 1 ? 's' : ''}
              </h3>
              {
                bill.products.map(({ id, product, productPayer, quantity }) => (
                  <div key={id} style={{ borderRadius: '8px', border: '1px solid #ccc', marginTop: '20px', display: 'flex', padding: '20px', flexDirection: 'column', gap: '8px' }}>
                    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                      {quantity} x&nbsp;
                      <div style={{ display: 'block' }}>
                        {product.name}
                      </div>
                    </div>
                    { productPayer.amount - bill.originCreditProducts.filter(oCR => oCR.billProductId === id).reduce((amount, oCR) => amount + oCR.amount, 0) > 0 &&
                      <>
                        <div style={{ marginTop: '8px' }}>
                          <Input max={productPayer.amount - bill.originCreditProducts.filter(oCR => oCR.billProductId === id).reduce((amount, oCR) => amount + oCR.amount, 0)} min={0} suffix="€ TTC" {...form.field(`products.${product.id}`, { float: true })} type="number" step="0.01" />
                          <div style={{ color: 'red', fontSize: '0.8em', padding: '0px 5px' }}>
                            • Vous ne pouvez faire un avoir que entre 0€ et {productPayer.amount - bill.originCreditProducts.filter(oCR => oCR.billProductId === id).reduce((amount, oCR) => amount + oCR.amount, 0)}€.
                          </div>
                          { bill.originCreditProducts.filter(oCR => oCR.billProductId === id).length > 0 &&
                            <div style={{ color: 'green', fontSize: '0.8em', padding: '0px 5px' }}>
                              • Vous avez déjà fait {bill.originCreditProducts.filter(oCR => oCR.billProductId === id).length} avoir{bill.originCreditProducts.filter(oCR => oCR.billProductId === id).length > 1 ? 's' : ''} pour un montant de {bill.originCreditProducts.filter(oCR => oCR.billProductId === id).reduce((amount, oCR) => amount + oCR.amount, 0)} €.
                            </div>
                          }
                        </div>
                      </>
                    }
                    { productPayer.amount - bill.originCreditProducts.filter(oCR => oCR.billProductId === id).reduce((amount, oCR) => amount + oCR.amount, 0) <= 0 &&
                      <div style={{ marginTop: '8px' }}>
                        Le montant total du produit a été remboursé:
                        { bill.originCreditProducts.filter(oCR => oCR.billProductId === id).length > 0 &&
                          <div style={{ color: 'green', fontSize: '0.8em', padding: '0px 5px', paddingTop: '8px' }}>
                            • Vous avez déjà fait {bill.originCreditProducts.filter(oCR => oCR.billProductId === id).length} avoir{bill.originCreditProducts.filter(oCR => oCR.billProductId === id).length > 1 ? 's' : ''} pour un montant de {bill.originCreditProducts.filter(oCR => oCR.billProductId === id).reduce((amount, oCR) => amount + oCR.amount, 0)} €.
                          </div>
                        }
                      </div>
                    }
                  </div>
                ))
              }
            </>
          }
        </div>
        <br />
        <button className="btn full" disabled={isLoading || (Object.values(form.data.registrations).reduce((amount, a) => amount + parseFloat(a, 10), 0) + Object.values(form.data.products).reduce((amount, a) => amount + parseFloat(a, 10), 0) <= 0)}>Valider l'Avoir de {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Object.values(form.data.registrations).reduce((amount, a) => amount + parseFloat(a, 10), 0) + Object.values(form.data.products).reduce((amount, a) => amount + parseFloat(a, 10), 0))}</button>
        <br />
        <br />
      </form>
    </div>
  );
}

export default function BillModal({ billId, billNumber, billType }) {
  const auth = useAuth();
  const modal = useModal();
  const [bill, setBill] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAttachedBill, setIsLoadingAttachedBill] = useState(true);
  const [attachedBill, setAttachedBill] = useState(true);
  const [isLoadingCredits, setIsLoadingCredits] = useState(true);
  const [credits, setCredits] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    Api.get(`/bills/${billId}`)
      .then(response => {
        setBill(response.data);
        setIsLoading(false);
      });
  }, [billId]);

  useEffect(() => {
    if (bill && bill.billId) {
      setIsLoadingAttachedBill(true);
      Api.get(`/bills/${bill.billId}`)
        .then(response => {
          setAttachedBill(response.data);
          setIsLoadingAttachedBill(false);
        });
    }

    if (bill) {
      setIsLoadingCredits(true);
      Api.get(`/bills/${bill.id}/credits`)
        .then(response => {
          setCredits(response.data);
          setIsLoadingCredits(false);
        });
    }
  }, [bill]);

  function onPaymentCreated() {
    modal.close();
    setIsLoading(true);
    Api.get(`/bills/${billId}`)
      .then(response => {
        setBill(response.data);
        setIsLoading(false);
      });
  }

  return (
    <div style={{ width: '100%' }}>
      <Head title={`${billType === 'bill' ? 'Facture' : 'Avoir'} #${billNumber}`} noTabs>
        { billType === 'bill' &&
          <button className="btn" onClick={() => modal.open(<CreateCredit onCreated={() => {
            setIsLoading(true);
            Api.get(`/bills/${billId}`)
              .then(response => {
                setBill(response.data);
                setIsLoading(false);
              });
          }} bill={bill} max={credits.length > 0 ? (bill.amount - credits.reduce((a, credit) => a + credit.amount, 0)) : bill.amount} />)}>Faire un Avoir</button>
        }
        <a className="btn" href={`${Api.API_URL}/bills/${billId}/bill.pdf?access_token=${auth.token}&t=${Date.now()}`} target="_blank" rel="noreferrer noopener">Télécharger</a>
      </Head>
      { isLoading &&
        <div style={{ padding: '80px 20px' }}>
          <Loader />
        </div>
      }
      { !isLoading &&
        <>
          <div style={{ marginTop: '0px', padding: '20px', background: '#E9E9E9', fontSize: '1.3em', borderBottom: '1px solid #ccc' }}>
            <strong>{(bill.amount - bill.amountPaid).toFixed(2)}€</strong>{billType === 'bill' && <> à payer{bill.paymentMethod ? ` en ${{
              web: 'web',
              bankcard: 'CB',
              change: 'espèces',
              check: 'chèque',
              shopify: '',
              'bank-transfer': 'virement',
            }[bill.paymentMethod]}` : ''} sur {bill.amount}€</>}
            <br/>
            <small style={{ fontSize: '0.7em' }}>Créée le {moment(bill.createdAt).format('DD/MM/YYYY HH:mm:ss')}</small>
          </div>
          { bill.threeTime &&
            <div style={{ marginTop: '0px', color: 'green', padding: '20px', fontSize: '1em', borderBottom: '1px solid #ccc' }}>
              Paiement en 3 fois
            </div>
          }
          { bill.status === 'canceled' &&
            <div style={{ marginTop: '0px', color: 'red', padding: '20px', fontSize: '1em', borderBottom: '1px solid #ccc', background: '#ffc4c4' }}>
              Facture annulée
            </div>
          }
          <div style={{ padding: '20px' }}>
            <div style={{ display: 'flex' }}>
              { bill.type === 'bill' && bill.status === 'pending' &&
                <>
                  <button className="btn" onClick={() => modal.open(<CreatePaymentModal bill={bill} onCreated={() => onPaymentCreated()} />)} style={{ marginLeft: '20px' }}>Valider le montant total</button>
                  <button className="btn" onClick={() => modal.open(<CreatePaymentModal bill={bill} deposit onCreated={() => onPaymentCreated()} />)} style={{ marginLeft: '20px' }}>Valider un accompte</button>
                  <button className="btn" onClick={() => modal.open(<GeneratePrePaymentModal bill={bill} onCreated={() => onPaymentCreated()} />)} style={{ marginLeft: '20px' }}>Générer un lien de paiement</button>
                </>
              }
              { bill.type === 'bill' && bill.status === 'paid' &&
                <>
                  <button className="btn red" disabled style={{  }}>Annuler le paiement</button>
                </>
              }
            </div>
            { billType === 'bill' && 
              <>
                <h3 style={{  }}>
                  Paiement{bill.payments.length > 1 ? 's' : ''}
                </h3>
                { bill.payments.length === 0 &&
                  <div style={{ padding: '10px', color: 'grey', fontSize: '0.9em', textAlign: 'center' }}>
                    Aucun paiement
                  </div>
                }
                {
                  bill.payments.map(payment => (
                    <div style={{ borderRadius: '8px', border: '1px solid #ccc', marginTop: '10px', padding: '20px', display: 'flex' }}>
                      <div style={{ flex: 1 }}>
                        <strong>{payment.amount}€</strong>{payment.externalId ? <>&nbsp;• <a href={`https://dashboard.stripe.com/payments/${payment.externalId}`} target="_blank" rel="noreferrer noopener">{payment.externalId}</a></> : ''}
                        <br/>
                        <small>
                          {payment.status === 'paid' ? 'Payé' : 'Créé'} le {moment(payment.createdAt).format('DD/MM/YYYY HH:mm:ss')} par&nbsp;
                          {{
                            web: 'Web',
                            bankcard: 'CB',
                            change: 'espèces',
                            check: 'Chèque',
                            'bank-transfer': 'Virement',
                          }[payment.paymentMethod]}
                        </small>
                      </div>
                      { payment.status === 'pending' &&
                        <a className="btn" href={`https://alpha.collegedesbernardins.fr/pay/${payment.identifier}`} target="_blank" rel="noreferrer noopener">Page de paiement</a>
                      }
                    </div>
                  ))
                }
              </>
            }
            <h3 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              Payeur
              { bill.payments.length === 0 &&
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => modal.open(<UpdatePayerModal bill={bill} onUpdated={(bill) => setBill(bill)}/>)} style={{ width: '12px', height: '12px', fill: '#520b4e', cursor: 'pointer' }} height="492pt" viewBox="0 0 492.49284 492" width="492pt"><path d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0"/><path d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0"/></svg>
              }
            </h3>
            <Link to="payer" params={{ id: bill.payer.id }} style={{ display: 'block', padding: '20px', borderRadius: '8px', border: '1px solid #ccc' }}>
              { bill.payer.type === 'company' ? (
                <>{bill.payer.companyName}</>
              ) : (
                <>{bill.payer.user.firstname} {bill.payer.user.lastname.toUpperCase()}</>
              )}
              { bill.payer.type &&
                <>&nbsp;• {{ company: 'Institution', people: 'Personne', student: 'Étudiant' }[bill.payer.type]}</>
              }
              <br/>
              {bill.payer.user.email}
            </Link>
            { billType === 'credit' && 
              <>
                <h3>Facture attachée</h3>
                { isLoadingAttachedBill &&
                  <Loader />
                }
                { !isLoadingAttachedBill && attachedBill &&
                  <div onClick={() => modal.open(<BillModal billId={attachedBill.id} billNumber={attachedBill.number} billType={attachedBill.type} />)} style={{ borderRadius: '8px', cursor: 'pointer', border: '1px solid #ccc', marginTop: '20px', padding: '20px' }}>
                    #{attachedBill.number} • {attachedBill.amount}€
                  </div>
                }
              </>
            }
            { billType === 'bill' && 
              <>
                <h3 style={{  }}>
                  Inscription{bill.registrations.length > 1 ? 's' : ''}
                </h3>
                { bill.registrations.length === 0 &&
                  <div style={{ padding: '20px', paddingTop: '5px', color: 'grey', textAlign: 'center' }}>
                    Aucune inscription
                  </div>
                }
                {
                  bill.registrations.map(({ id, registration }) => (
                    <div key={id} style={{ borderRadius: '8px', border: '1px solid #ccc', marginTop: '20px' }}>
                      <Link to="registration" params={{ id: registration.id }} style={{ display: 'block', padding: '20px' }}>
                        {registration.student.user.firstname} {(registration.student.user.lastname ? registration.student.user.lastname.toUpperCase() : '')}
                        <br/>
                        { registration.classe &&
                          <>
                            Cours {registration.classe.name}
                            <br />
                          </>
                        }
                        { registration.package &&
                          <>
                            Package {registration.package.name}
                            <br />
                            <span style={{ color: 'grey', fontSize: '0.9em', textTransform: 'uppercase' }}>{registration.package.program}</span>
                          </>
                        }
                      </Link>
                      { registration.payers.filter(registrationPayer => registrationPayer.billId !== billId).length > 0 &&
                        <>
                          <div style={{ padding: '10px 20px', background: '#ccc', borderLeft: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            Autre{registration.payers.filter(registrationPayer => registrationPayer.billId !== billId).length > 1 ? 's' : ''} facture{registration.payers.filter(registrationPayer => registrationPayer.billId !== billId).length > 1 ? 's' : ''} :
                          </div>
                          {
                            registration.payers.filter(registrationPayer => registrationPayer.billId !== billId).map((registrationPayer) => (
                              <div key={registrationPayer.id} style={{ borderTop: '1px solid #ccc', padding: '20px', display: 'flex', alignItems: 'center' }}>
                                <div style={{ flex: 1 }}>
                                  { registrationPayer.bill && /* overkill test for production */
                                    <div>
                                      Facture #{registrationPayer.bill.number}&nbsp;•&nbsp;
                                      {{
                                        'pending': 'En attente',
                                        'paid': 'Payée',
                                        'canceled': 'Annulée',
                                      }[registrationPayer.bill.status]}
                                    </div>
                                  }
                                  <div style={{ fontWeight: 500, fontSize: '1.1em' }}>
                                    { registrationPayer.payer.type === 'company' ? (
                                      <>{registrationPayer.payer.companyName}</>
                                    ) : (
                                      <>{registrationPayer.payer.user.firstname} {registrationPayer.payer.user.lastname.toUpperCase()}</>
                                    )}
                                  </div>
                                  <div style={{ color: 'grey' }}>
                                    {registrationPayer.payer.user.email} • {{ company: 'Institution', people: 'Personne', student: 'Étudiant' }[registrationPayer.payer.type]}
                                  </div>
                                </div>
                                <div style={{ flex: '0 0 auto', textAlign: 'right' }}>
                                  <span style={{ fontWeight: 500, fontSize: '1.1em' }}>{registrationPayer.amount}€</span>
                                  { registrationPayer.paymentMethod &&
                                    <>
                                      <br/>
                                      {{
                                        web: 'Web',
                                        bankcard: 'CB',
                                        change: 'espèces',
                                        check: 'Chèque',
                                        'bank-transfer': 'Virement',
                                      }[registrationPayer.paymentMethod]}
                                    </>
                                  }
                                </div>
                              </div>
                            ))
                          }
                        </>
                      }
                    </div>
                  ))
                }
                <h3 style={{  }}>
                  Produit{bill.products.length > 1 ? 's' : ''}
                </h3>
                { bill.products.length === 0 &&
                  <div style={{ padding: '20px', paddingTop: '5px', color: 'grey', textAlign: 'center' }}>
                    Aucun produit
                  </div>
                }
                {
                  bill.products.map(( product ) => (
                    <div key={product.id} style={{ borderRadius: '8px', border: '1px solid #ccc', marginTop: '20px', display: 'flex', padding: '20px' }}>
                      {product.quantity} x&nbsp;
                      <Link to="product" params={{ id: product.product.id }} style={{ display: 'block' }}>
                        {product.product.name}
                      </Link>
                      {/* @todo show others bill associated to the payment of this product */}
                    </div>
                  ))
                }

                <h3 style={{  }}>
                  Avoir{credits.length > 1 ? 's' : ''}
                </h3>
                { isLoadingCredits &&
                  <Loader />
                }
                { isLoadingCredits === false && credits.length === 0 &&
                  <div style={{ padding: '20px', paddingTop: '5px', color: 'grey', textAlign: 'center' }}>
                    Aucun avoirs
                  </div>
                }
                {
                  credits.map((credit) => (
                    <div onClick={() => modal.open(<BillModal billId={credit.id} billNumber={credit.number} billType={credit.type} />)} style={{ borderRadius: '8px', cursor: 'pointer', border: '1px solid #ccc', marginTop: '20px', padding: '20px' }}>
                      #{credit.number} • {credit.amount}€
                    </div>
                  ))
                }
              </>
            }
            { billType === 'credit' && 
              <>
                <h3 style={{  }}>
                  Détails par inscription{bill.creditRegistrations.length > 1 ? 's' : ''}
                </h3>
                { bill.creditRegistrations.length === 0 &&
                  <div style={{ padding: '20px', paddingTop: '5px', color: 'grey', textAlign: 'center' }}>
                    Aucune inscription
                  </div>
                }
                {
                  bill.creditRegistrations.map(({ amount, billRegistration }) => (
                    <div key={billRegistration.id} style={{ borderRadius: '8px', border: '1px solid #ccc', marginTop: '20px', display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <Link to="registration" params={{ id: billRegistration.registration.id }} style={{ display: 'block', padding: '20px', flex: 1 }}>
                        { billRegistration.registration.classe &&
                          <>
                            Cours {billRegistration.registration.classe.name}
                            <br />
                          </>
                        }
                        { billRegistration.registration.package &&
                          <>
                            Package {billRegistration.registration.package.name}
                            <br />
                            <span style={{ color: 'grey', fontSize: '0.9em', textTransform: 'uppercase' }}>{billRegistration.registration.package.program}</span>
                          </>
                        }
                      </Link>
                      <div style={{ padding: '20px', textAlign: 'right' }}>
                        <span style={{ fontWeight: '900' }}>{amount} €</span>
                        <br />
                        <small style={{ color: 'grey' }}>Sur {billRegistration.registrationPayer.amount} €</small>
                      </div>
                    </div>
                  ))
                }
                <h3 style={{  }}>
                  Détails par produit{bill.creditProducts.length > 1 ? 's' : ''}
                </h3>
                { bill.creditProducts.length === 0 &&
                  <div style={{ padding: '20px', paddingTop: '5px', color: 'grey', textAlign: 'center' }}>
                    Aucun produit
                  </div>
                }
                {
                  bill.creditProducts.map(({ amount, billProduct }) => (
                    <div style={{ borderRadius: '8px', border: '1px solid #ccc', marginTop: '20px', display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <div  style={{ display: 'block', padding: '20px', flex: 1 }}>
                        {billProduct.quantity} x&nbsp;
                        <Link to="product" params={{ id: billProduct.product.id }} style={{ display: 'block' }}>
                          {billProduct.product.name}
                        </Link>
                      </div>
                      <div style={{ padding: '20px', textAlign: 'right' }}>
                        <span style={{ fontWeight: '900' }}>{amount} €</span>
                        <br />
                        <small style={{ color: 'grey' }}>Sur {billProduct.productPayer.amount} €</small>
                      </div>
                    </div>
                  ))
                }
              </>
            }
          </div>
        </>
      }
    </div>
  )
}