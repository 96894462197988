import React from 'react';

import { Route } from '../../hooks/router';

import { Tabs, Tab } from '../../components/tabs';
import { Head } from '../../components/head';
import { Loader } from '../../components/loader';
import useRessource from '../../hooks/ressource/useRessource';

export default function TutorRessource({ route }) {
  const { tutor, isLoading, set } = useRessource('tutor', route.params.id);

  return (
    <div className="ressource-page">
      <Head prefix="Tuteur" title={`${tutor.user ? tutor.user.firstname : ''} ${tutor.user ? tutor.user.lastname.toUpperCase() : ''}`} isLoading={isLoading} />
      <Tabs route={route.subRoute} params={{ id: route.params.id }}>
        <Tab to="tutor" routeName="_default">Aperçu</Tab>
      </Tabs>
      { isLoading ? <Loader /> : <Route route={route.subRoute} tutor={tutor} setTutor={set} /> }
    </div>
  );
}
