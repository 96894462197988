import React from 'react';

import { Link, queryToUrl } from '../../../hooks/router';
import { Input } from '../../../hooks/form';

import { List, useList } from '../../../hooks/list';
import { useAuth } from '../../../hooks/auth/Auth';
import Api from '../../../services/Api';

export default function PackageRessourceStudents({ classePackage }) {
  const auth = useAuth();
  const { filters, items, isLoading, load } = useList(`packages/${classePackage.id}/students`, {
    search: '',
  });

  return (
    <div className="list-page">
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
        <div className="filter">
          <a href={`${Api.API_URL}/packages/${classePackage.id}/students.xlsx${queryToUrl({ access_token: auth.token })}`} target="_blank" rel="noreferrer noopener" className="btn" style={{ display: 'block' }}>Export XLSX</a>
        </div>
      </form>
      <List onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Étudiant', sort: 'lastname' }, { name: 'Email' }]}>
        { items.map(student => (
          <Link key={student.id} className="item" to="student" params={{ id: student.id }}>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              {student.user.firstname} {student.user.lastname.toUpperCase()}
            </div>
            <div className="prop">
              {student.user.email}
            </div>
          </Link>
        ))}
      </List>
    </div>
  );
};