import React from 'react';

import { Route, useRouter } from '../../hooks/router';

import { Tabs, Tab } from '../../components/tabs';
import { Head } from '../../components/head';
import { Loader } from '../../components/loader';
import useRessource from '../../hooks/ressource/useRessource';
import { useModal } from '../../hooks/modal/Modal';
import Api from '../../services/Api';
import { useNotification } from '../../hooks/notification/Notification';

function DeleteAdmin({ admin }) {
  const modal = useModal();
  const notification = useNotification();
  const router = useRouter();

  function onDelete() {
    Api.del(`/admins/${admin.id}`)
      .then(response => {
        notification.notify('L\'administrateur a été supprimé !');
        router.goTo('admins');
        modal.close();
      }).catch(error => {
        alert('An error occured');
      })
  }

  return (
    <div className="list-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Êtes-vous certains de supprimer cet administrateur ?" />
      <div>
        <div style={{ fontSize: '0.9em', color: 'red', padding: '20px' }}>
          <div>• Il ne pourra plus se connecter</div>
        </div>
      </div>
      <div style={{ padding: '20px', display: 'flex', justifyContent: 'flex-end', borderTop: '1px solid #ccc', borderRadius: '0px 0px 8px 8px' }}>
        <button className="btn" onClick={() => modal.close()} style={{ marginRight: '20px' }}>Annuler</button>
        <button className="btn red" onClick={() => onDelete()}>Supprimer</button>
      </div>
    </div>
  );
}

export default function AdminRessource({ route }) {
  const modal = useModal();
  const { admin, isLoading, set } = useRessource('admin', route.params.id);

  return (
    <div className="ressource-page">
      <Head prefix="Administrateur" title={`${admin.user ? admin.user.firstname : ''} ${admin.user ? admin.user.lastname.toUpperCase() : ''}`} isLoading={isLoading}>
        { admin && admin.status !== 'deleted' &&
          <>
            <button className="btn red" onClick={() => modal.open(<DeleteAdmin admin={admin} />)}>Supprimer</button>
          </>
        }
      </Head>
      <Tabs route={route.subRoute} params={{ id: route.params.id }}>
        <Tab to="admin" routeName="_default">Aperçu</Tab>
      </Tabs>
      { isLoading ? <Loader /> : <Route route={route.subRoute} admin={admin} setAdmin={set} /> }
    </div>
  );
}
