import React, { useRef } from 'react';

import './checkbox.scss';

export default function Checkbox({ label, fullLabel, disabled, disabledLabel, inline, name, errors, checked, switchable, onChange, alignCenter, ghost, confirmMessage, ...fieldProps }) {
  const inputRef = useRef();

  function onClick(event) {
    if (ghost) {
      return;
    }
    
    event.preventDefault();
    event.stopPropagation();

    if (checked !== true && confirmMessage && window.confirm(confirmMessage)) {
      inputRef.current.click();
    } else {
      inputRef.current.click();
    }
  }

  return (
    <>
      <div className={`checkbox-component${alignCenter ? ' align-center' : ''}${label ? '' : ' no-label'}${switchable ? ' switchable' : ''}${disabled ? ' disabled' : ''}${inline ? ' inline' : ''}${ghost ? ' ghost' : ''}`} onClick={onClick}>
        <input type="checkbox" disabled={disabled} readOnly={ghost} checked={checked} ref={inputRef} {...fieldProps} onChange={onChange} style={{ display: 'none' }} />
        {checked ? (
          <div className="input checked">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
              <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0    c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7    C514.5,101.703,514.499,85.494,504.502,75.496z"/>
            </svg>
          </div>
        ) : (
          <div className="input" />
        )}
        { label &&
          <div className="label" title={fullLabel}>
            { disabledLabel ? (<>{disabledLabel}</>) : (<>{label}</>)}
          </div>
        }
      </div>
      {errors && <div className="error-message">{errors.join('<br>')}</div>}
    </>
  )
};
