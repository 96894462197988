import React from 'react';

import { Route } from '../../hooks/router';

import { Tabs, Tab } from '../../components/tabs';
import { Head } from '../../components/head';
import { Loader } from '../../components/loader';
import useRessource from '../../hooks/ressource/useRessource';

export default function PackageRessource({ route }) {
  const { classePackage, isLoading, set } = useRessource('package', route.params.id, { entityReturnName: 'classePackage' });

  return (
    <div className="ressource-page">
      <Head prefix="Package" title={`${classePackage.name}`} isLoading={isLoading} />
      <Tabs route={route.subRoute} params={{ id: route.params.id }}>
        <Tab to="package" routeName="_default">Aperçu</Tab>
        <Tab to="package/students" routeName="students">Étudiants</Tab>
        <Tab to="package/registrations" routeName="registrations">Inscriptions</Tab>
      </Tabs>
      { isLoading ? <Loader /> : <Route route={route.subRoute} classePackage={classePackage} setClassePackage={set} /> }
    </div>
  );
}
