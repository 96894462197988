import React from 'react';

import './loader.scss';

export default function Loader({ style, color = '#520b4e' }) {
  return (
    <div className="loader-component" style={style}>
      <svg height="100%" viewBox="0 0 32 32" width="100%">
        <circle cx="16" cy="16" fill="none" r="14" strokeWidth="4" style={{ stroke: color, opacity: '0.2' }} />
        <circle cx="16" cy="16" fill="none" r="14" strokeWidth="4" style={{ stroke: color, strokeDasharray: '80px', strokeDashoffset: '60px' }} />
      </svg>
    </div>
  );
}