import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Field } from '../../../components/field';
import { Head } from '../../../components/head';
import { Loader } from '../../../components/loader';
import { Input, Select, Checkbox, useForm, Textarea } from '../../../hooks/form';
import { List, useList } from '../../../hooks/list';
import { useModal } from '../../../hooks/modal/Modal';
import { useNotification } from '../../../hooks/notification/Notification';
import { Link } from '../../../hooks/router';
import Api from '../../../services/Api';
import { FileList } from '../../../components/fileList';

function nl2br (str, is_xhtml) {
  if (typeof str === 'undefined' || str === null) {
      return '';
  }
  var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

function SelectProfessorModal({ onSelected, excludeIds }) {
  const modal = useModal();
  const { filters, items, isLoading, load } = useList('professors', {
    search: '',
    excludeIds,
  });

  function select(professor) {
    onSelected(professor);
    modal.close();
  }

  return (
    <div className="list-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Choisissez un professeur" />
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
      </form>
      <br/>
      <List onLoadMore={load} isLoading={isLoading}>
        { items.map(professor => (
          <div onClick={() => select(professor)} key={professor.id} className="item" to="professor" params={{ id: professor.id }}>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '10px', borderRadius: '10px', background: 'green', width: '10px', height: '10px', flex: '0 0 auto' }}></div>
              <span>
                {professor.user.firstname} {professor.user.lastname.toUpperCase()}
              </span>
            </div>
            <div className="prop">
              {moment(professor.createdAt).format('DD/MM/YYYY HH:mm:ss')}
            </div>
            <div className="prop">
              {professor.status}
            </div>
          </div>
        ))}
      </List>
    </div>
  );
}

function UpdateInformations({ classe, onUpdated }) {
  const notification = useNotification();
  const modal = useModal();
  const form = useForm(classe, (data) => {
    Api.post(`/classes/${classe.id}/informations`, data)
      .then(response => {
        onUpdated(response.data);
        notification.notify('Les informations ont été mises à jour !');
        modal.close();
      })
      .catch(() => {
        alert('Une erreur est survenue');
      });
  });

  return (
    <div className="ressource-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Modifier les informations" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        <Input label="Titre du cours" {...form.field('name')} />
        <br />
        <Select label="Département programmateur" noSearch {...form.field('department')}>
          <option value="cp">Cours Publics</option>
          <option value="fnd">FND</option>
          <option value="issr">ISSR</option>
          <option value="fr">FEB</option>
          <option value="ccdej">CCDEJ</option>
          <option value="fcf">FCF</option>
          <option value="e-learning">ELEARNING</option>
          <option value="other">Autre</option>
        </Select>
        <br/>
        <Input label="Sous-titre du cours" {...form.field('subName')} />
        <br/>
        <Input label="Sur-titre" {...form.field('surtitle')} />
        <br/>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <Input label="Numéro" {...form.field('number')} />
          </div>
          <div style={{ flex: 1, marginLeft: '20px' }}>
            <Input label="Code produit" {...form.field('productCode')} />
          </div>
        </div>
        <br/>
        <Textarea label="Description" {...form.field('description')} />
        <br/>
        <Input label="Niveau requis" {...form.field('studentLevel')} />
        <br/>
        <Checkbox label="Inscriptions ouvertes" {...form.field('registrationOpen', { type: 'checkbox' })} />
        <br/>
        { form.data.registrationOpen === true &&
          <>
            <Input label="Date début d'inscription" {...form.field('registrationOpenStartAt', { type: 'date' })} />
            <br/>
            <Input label="Date limite d'inscription" {...form.field('registrationOpenEndAt', { type: 'date' })} />
            <br/>
          </>
        }
        <Select label="Template email à l'inscription" noSearch {...form.field('emailTemplateId')}>
          <option disabled value={null}>Aucun</option>
          <option value="4851475">Mail juin 2023 - Distanciel replay</option>
          <option value="4851441">Mail juin 2023 - présentiel</option>
          <option value="4851465">Mail juin 2023 - Distanciel</option>
          <option value="4807785">Mail juin 2023 - MOOC</option>
        </Select>
        <br/>
        <Checkbox label="MOOC" {...form.field('mooc', { type: 'checkbox' })} />
        <br />
        <Checkbox label="Replay" {...form.field('replay', { type: 'checkbox' })} />
        <br />
        { form.data.replay === true &&
          <>
            <Input label="Date début du replay" {...form.field('replayStartAt', { type: 'date' })} />
            <br/>
          </>
        }
        
        <button className="btn full">Enregistrer</button>
      </form>
    </div>
  );
}

function UpdateMeeting({ classe, onUpdated }) {
  const notification = useNotification();
  const modal = useModal();
  const form = useForm(classe, (data) => {
    Api.post(`/classes/${classe.id}/meeting`, data)
      .then(response => {
        onUpdated(response.data);
        notification.notify('Les informations de séances ont été mises à jour !');
        modal.close();
      })
      .catch(() => {
        alert('Une erreur est survenue');
      });
  });

  return (
    <div className="ressource-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Modifier les séances" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        { form.data.channel === 'distanciel' &&
          <>
            <Textarea label="Explications par email" {...form.field('distancielExplication')} />
            <br />
          </>
        }
        { form.data.channel === 'presentiel' &&
          <>
            <Input label="Limite d'étudiant" {...form.field('capacity')} />
            <br/>
          </>
        }
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <Select label="Période" noSearch {...form.field('period')}>
              <option value={null}>Non définie</option>
              <option value="annual">Annuel</option>
              <option value="first-semester">1er semestre</option>
              <option value="second-semester">2nd semestre</option>
            </Select>
          </div>
          <div style={{ flex: 1, marginLeft: '20px' }}>
            <Select label="Année" noSearch {...form.field('year')}>
              <option value={null}>Non définie</option>
              <option value={2021}>2021 - 2022</option>
              <option value={2022}>2022 - 2023</option>
              <option value={2023}>2023 - 2024</option>
              <option value={2024}>2024 - 2025</option>
              <option value={2025}>2025 - 2026</option>
              <option value={2026}>2026 - 2027</option>
              <option value={2027}>2027 - 2028</option>
              <option value={2028}>2028 - 2029</option>
              <option value={2029}>2029 - 2030</option>
              <option value={2030}>2030 - 2031</option>
              <option value={2031}>2031 - 2032</option>
            </Select>
          </div>
        </div>
        <br/>

        { form.data.channel === 'presentiel' &&
          <>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <Input label="Durée totale des cours" {...form.field('duration')} />
              </div>
              <div style={{ flex: 1, marginLeft: '20px' }}>
                <Input label="Nombre de semaine de cours" {...form.field('weekNumber')} />
              </div>
            </div>
            <br/>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <Input label="Date du premier cours" {...form.field('firstClasseAt', { type: 'date' })} />
              </div>
              <div style={{ flex: 1, marginLeft: '20px' }}>
                <Input label="Date du dernier cours" {...form.field('lastClasseAt', { type: 'date' })} />
              </div>
            </div>
            <br/>
          </>
        }
        <button className="btn full">Enregistrer</button>
      </form>
    </div>
  );
}

function UpdateEtipiInformations({ classe, onUpdated }) {
  const notification = useNotification();
  const modal = useModal();
  const form = useForm(classe, (data) => {
    Api.post(`/classes/${classe.id}/meeting`, data)
      .then(response => {
        onUpdated(response.data);
        notification.notify('Les informations de séances ont été mises à jour !');
        modal.close();
      })
      .catch(() => {
        alert('Une erreur est survenue');
      });
  });

  return (
    <div className="ressource-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Modifier les séances" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        <Textarea label="Explications par email" {...form.field('distancielExplication')} />
        <br />
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <Select label="Période" noSearch {...form.field('period')}>
              <option value={null}>Non définie</option>
              <option value="annual">Annuel</option>
              <option value="first-semester">1er semestre</option>
              <option value="second-semester">2nd semestre</option>
            </Select>
          </div>
          <div style={{ flex: 1, marginLeft: '20px' }}>
            <Select label="Année" noSearch {...form.field('year')}>
              <option value={null}>Non définie</option>
              <option value={2021}>2021 - 2022</option>
              <option value={2022}>2022 - 2023</option>
              <option value={2023}>2023 - 2024</option>
              <option value={2024}>2024 - 2025</option>
              <option value={2025}>2025 - 2026</option>
              <option value={2026}>2026 - 2027</option>
              <option value={2027}>2027 - 2028</option>
              <option value={2028}>2028 - 2029</option>
              <option value={2029}>2029 - 2030</option>
              <option value={2030}>2030 - 2031</option>
              <option value={2031}>2031 - 2032</option>
            </Select>
          </div>
        </div>
        <br/>
        <Select
          label="Cours en présentiel associé"
          nullable
          key={form.data.type}
          load={async ({ page, search }) => {
            const response = await Api.get('/classes' , { page, search });
            return { items: response.data };
          }}
          {...form.field('presentielClasse')}
          noSelectionMessage="Aucun cours"
        >
          {(classe, onSelect) => (
            <div key={classe.id} onClick={onSelect}>
              {classe.name} • {classe.number}
            </div>
          )}
        </Select>
        <br />
        <Input label="Code produit" {...form.field('productCode')} />
        <br />
        <Select label="Template email à l'inscription" noSearch {...form.field('emailTemplateId')}>
          <option disabled value={null}>Aucun</option>
          <option value="4851475">Mail juin 2023 - Distanciel replay</option>
          <option value="4851441">Mail juin 2023 - présentiel</option>
          <option value="4851465">Mail juin 2023 - Distanciel</option>
          <option value="4807785">Mail juin 2023 - MOOC</option>
        </Select>
        <br/>
        <Checkbox label="MOOC" {...form.field('mooc', { type: 'checkbox' })} />
        <br/>
        <Checkbox label="Replay" {...form.field('replay', { type: 'checkbox' })} />
        <br />
        { form.data.replay === true &&
          <>
            <Input label="Date début du replay" {...form.field('replayStartAt', { type: 'date' })} />
            <br/>
          </>
        }
        <br />

        <Checkbox label="Inscriptions ouvertes" {...form.field('registrationOpen', { type: 'checkbox' })} />
        <br/>
        { form.data.registrationOpen === true &&
          <>
            <Input label="Date début d'inscription" {...form.field('registrationOpenStartAt', { type: 'date' })} />
            <br/>
            <Input label="Date limite d'inscription" {...form.field('registrationOpenEndAt', { type: 'date' })} />
            <br/>
          </>
        }
        <br />
        <button className="btn full">Enregistrer</button>
      </form>
    </div>
  );
}

function UpdatePrices({ classe, onUpdated }) {
  const notification = useNotification();
  const modal = useModal();
  const form = useForm(classe, (data) => {
    Api.post(`/classes/${classe.id}/prices`, data)
      .then(response => {
        onUpdated(response.data);
        notification.notify('Les prix ont été mis à jour !');
        modal.close();
      })
      .catch(() => {
        alert('Une erreur est survenue');
      });
  });

  return (
    <div className="ressource-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Modifier les tarifs" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        <Input suffix="€ TTC" label="Tarif Institutions" {...form.field('fullPriceTtc', { type: 'float' })} />
        <br/>
        <Input suffix="€ TTC" label="Tarif individuel" {...form.field('individualPriceTtc', { type: 'float' })} />
        <br/>
        <Input suffix="€ TTC" label="Tarif jeune - 30 ans" {...form.field('youthPriceTtc', { type: 'float' })} />
        <br/>
        <button className="btn full">Enregistrer</button>
      </form>
    </div>
  );
}

/**
 * Selector for Shopify product to bind
 * @param {*} param0 
 * @returns 
 */
function SelectShopifyProduct({ onSelected }) {
  const modal = useModal();
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    Api.get(`/shopify/active-and-draft-products${search.trim() !== '' ? `?search=${search}` : ''}`)
      .then((response) => {
        setProducts(response.data);
      });
  }, [search]);

  function onSelectedVariant(variant, product) {
    onSelected({
      shopifyVariantId: variant.id,
      shopifyProductId: product.id,
      shopifyImageUrl: product?.image?.src,
      shopifyProductTitle: product.title,
      shopifyVariantTitle: product.options.map((option, cpt) => `${option.name} : ${variant[`option${cpt + 1}`]}`).join(', '),
    });
    modal.closeAll();
  }

  return (
    <div className="shopify-product-selector">
      <form className="head">
        <Input placeholder="Rechercher..." value={search} onChange={value => setSearch(value.target.value)} />
      </form>
      <div className="products">
      { products.length === 0 &&
        <div>
          <br />
          <Loader />
          <br /><br /><br /><br /><br />
        </div>
      }
      {
        products.filter(product => {
          if (search.trim() !== '') {
            return product.title.includes(search.trim());
          }
          return true;
        }).map(product => (
          <div className="product">
            { product.image ? <img src={product.image.src} alt="" /> : <div className="fake-img"></div> }
            <div className="infos">
              <div className="title">
                {product.title}
              </div>
              <div className="variants">
                {
                  product.variants.map(variant => (
                    <div className="variant" onClick={() => onSelectedVariant(variant, product)}>
                      <div className="name">Variant #{variant.id}</div>
                      {
                        product.options.map((option, cpt) => (
                          <div className="options">
                            <div className="option">
                              {option.name} : {variant[`option${cpt + 1}`]}
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        ))
      }
      </div>
    </div>
  );
}

/**
 * Selector for Campus (WooCommerce) product to bind
 * @param {*} param0 
 * @returns 
 */
function SelectCampusProduct({ onSelected }) {
  const modal = useModal();
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    Api.get(`/campus/products${search.trim() !== '' ? `?search=${search}` : ''}`)
    //Api.get(`/shopify/active-and-draft-products${search.trim() !== '' ? `?search=${search}` : ''}`)
      .then((response) => {
        setProducts(response.data);
      });
  }, [search]);

  function onSelectedProduct(product) {
    onSelected({
      campusProductId: product.id,
      campusImageUrl: (product.images ? product.images[0].src : null),
      campusProductTitle: product.name,
      campusVariantTitle: "Campus",
    });
    modal.closeAll();
  }

  return (
    <div className="shopify-product-selector">
      <form className="head">
        <Input placeholder="Rechercher..." value={search} onChange={value => setSearch(value.target.value)} />
      </form>
      <div className="products">
      { products.length === 0 &&
        <div>
          <br />
          <Loader />
          <br /><br /><br /><br /><br />
        </div>
      }
      {
        products.filter(product => {
          if (search.trim() !== '') {
            return product.name.includes(search.trim());
          }
          return true;
        }).map(product => (
          <div className="product">
            { product.images ? <img src={decodeURIComponent(product.images[0].src)} alt="" /> : <div className="fake-img"></div> }
            <div className="infos">
              <div className="title">
                {product.name}
              </div>
              <div className="variants">
                <div className="variant" onClick={() => onSelectedProduct(product)}>
                  <div className="name">Variant #{product.id}</div>
                  <div className="options">
                    <div className="option">
                      Campus
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      }
      </div>
    </div>
  );
}

export default function ClasseRessourceOverview({ classe, setClasse }) {
  const modal = useModal();
  const notification = useNotification();
  const [gauge, setGauge] = useState(null);

  useEffect(() => {
    Api.get(`/classes/${classe.id}/gauge`)
      .then((response) => {
        setGauge(response.data);
      });
  }, [classe]);

  function onProfessorSelected(professor) {
    Api.post(`/classes/${classe.id}/professors`, professor)
      .then(response => {
        setClasse(response.data);
        notification.notify('Le professeur a été ajouté !');
      }).catch(error => {
        console.log(error);
        alert('Une erreur est survenue');
      });
  }

  function onShopifyProductSelected(shopifyProduct) {
    Api.post(`/classes/${classe.id}/shopify-products`, shopifyProduct)
      .then(response => {
        setClasse(response.data);
        notification.notify('Le produit a été synchronisé !');
      }).catch(error => {
        alert('Une erreur est survenue');
      });
  }

  function onCampusProductSelected(campusProduct) {
    Api.post(`/classes/${classe.id}/campus-products`, campusProduct)
      .then(response => {
        setClasse(response.data);
        notification.notify('Le produit a été synchronisé !');
      }).catch(error => {
        alert('Une erreur est survenue');
      });
  }

  function onProfessorRemove(professor) {
    Api.del(`/classes/${classe.id}/professors/${professor.id}`)
      .then(response => {
        setClasse(response.data);
        notification.notify('Le professeur a été retiré !');
      }).catch(error => {
        console.log(error);
        alert('Une erreur est survenue');
      });
  }

  function onShopifyProductRemove(shopifyProduct) {
    Api.del(`/classes/${classe.id}/shopify-products/${shopifyProduct.id}`)
      .then(response => {
        setClasse(response.data);
        notification.notify('La synchronisation a été retirée !');
      }).catch(error => {
        alert('Une erreur est survenue');
      });
  }

  function onCampusProductRemove(campusProduct) {
    Api.del(`/classes/${classe.id}/campus-products/${campusProduct.id}`)
      .then(response => {
        setClasse(response.data);
        notification.notify('La synchronisation a été retirée !');
      }).catch(error => {
        alert('Une erreur est survenue');
      });
  }

  function onUpdated(classe) {
    setClasse(classe);
  }

  return (
    <div className="ressource-page" style={{ padding: '20px', display: 'flex', flexDirection: 'row' }}>
      <div style={{ flex: 1, marginRight: '20px' }}>
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          { gauge === null ? (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80px' }}>
              <Loader />
            </div>
          ) : (
            <>
              <div style={{ flex: 1, background: 'rgb(249, 249, 249)', border: '1px solid #E9E9E9', borderRadius: '8px', padding: '20px' }}>
                <div style={{ fontWeight: 700, fontSize: '1.2em' }}>{gauge.studentCount}</div>
                <div>Étudiant{parseInt(gauge.studentCount, 10) > 1 ? 's' : ''}</div>
              </div>
              <div style={{ flex: 1, marginLeft: '20px', background: 'rgb(249, 249, 249)', border: '1px solid #E9E9E9', borderRadius: '8px', padding: '20px' }}>
                <div style={{ fontWeight: 700, fontSize: '1.2em' }}>{gauge.registrationCount}</div>
                <div>Inscriptions</div>
              </div>
              { classe.initial !== 0 ? (
                <>
                  <div style={{ flex: 1, marginLeft: '20px', background: gauge.remaining < 0 ? 'red' : 'rgb(249, 249, 249)', border: '1px solid #E9E9E9', borderRadius: '8px', padding: '20px', color: gauge.remaining < 0 ? 'white' : 'black' }}>
                    <div style={{ fontWeight: 700, fontSize: '1.2em' }}>{gauge.remaining}</div>
                    <div>Jauge disponible</div>
                  </div>
                  <div style={{ flex: 1, marginLeft: '20px', background: 'rgb(249, 249, 249)', border: '1px solid #E9E9E9', borderRadius: '8px', padding: '20px' }}>
                    <div style={{ fontWeight: 700, fontSize: '1.2em' }}>{gauge.initial}</div>
                    <div>Jauge initiale</div>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ flex: 2 }}></div>
                </>
              )}
            </>
          )}
        </div>

        { classe.distancielService === 'etipi' &&
          <div style={{ border: '1px solid green', padding: '20px', borderRadius: '8px', marginBottom: '20px', background: '#d2f5d2', color: 'green' }}>
            Cours géré par Etipi
          </div>
        }

        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          { classe.distancielService !== 'etipi' ? (
            <>
              <div style={{ padding: '16px', borderRadius: '8px', background: '#F9F9F9', border: '1px solid #E9E9E9' }}>
                <h3 style={{ display: 'flex', justifyContent: 'space-between', margin: '0px', paddingBottom: '8px', marginBottom: '16px', borderBottom: '1px solid #E9E9E9' }}><span>Informations</span><small style={{ color: '#007bff', cursor: 'pointer', fontWeight: 500 }} onClick={() => modal.open(<UpdateInformations classe={classe} onUpdated={classe => onUpdated(classe)} />)}>Modifier</small></h3>
                <Field label="Titre du cours">
                  {classe.name}
                </Field>
                <Field label="Département programmateur">
                  {classe.department ? ({
                    'cp': 'Cours Publics',
                    'fnd': 'FND',
                    'issr': 'ISSR',
                    'fr': 'FEB',
                    'ccdej': 'CCDEJ',
                    'fcf': 'FCF',
                    'e-learning': 'ELEARNING',
                    'other': 'Autre',
                  }[classe.department]) :  (<div style={{ color: 'red', display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.9522 16.3536L10.2152 5.85658C10.9531 4.38481 13.0539 4.3852 13.7913 5.85723L19.0495 16.3543C19.7156 17.6841 18.7487 19.25 17.2613 19.25H6.74007C5.25234 19.25 4.2854 17.6835 4.9522 16.3536Z"></path>
                      <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10V12"></path>
                      <circle cx="12" cy="16" r="1" fill="currentColor"></circle>
                    </svg>
                    Aucun
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.9522 16.3536L10.2152 5.85658C10.9531 4.38481 13.0539 4.3852 13.7913 5.85723L19.0495 16.3543C19.7156 17.6841 18.7487 19.25 17.2613 19.25H6.74007C5.25234 19.25 4.2854 17.6835 4.9522 16.3536Z"></path>
                      <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10V12"></path>
                      <circle cx="12" cy="16" r="1" fill="currentColor"></circle>
                    </svg>
                  </div>)}
                </Field>
                <Field label="Sous-titre du cours">
                  {classe.subName}
                </Field>
                <Field label="Sur-titre">
                  {classe.surtitle}
                </Field>
                <Field label="Numéro">
                  {classe.number}
                </Field>
                <Field label="Code produit">
                  {classe.productCode}
                </Field>
                <Field label="Description" box>
                  <div style={{ borderRadius: '8px', border: '1px solid #E9E9E9', padding: '10px', fontFamily: 'Courier New' }} dangerouslySetInnerHTML={{ __html: nl2br(classe.description) }} />
                </Field>
                <Field label="Inscriptions">
                  { classe.registrationOpen || classe.distancielService === 'etipi' ? (
                    <>
                      Ouvertes
                      { classe.registrationOpenStartAt &&
                        <> à partir du <strong>{moment(classe.registrationOpenStartAt).format('DD/MM/YYYY')}</strong> à 00h00:01</>
                      }
                      { classe.registrationOpenEndAt &&
                        <> jusqu'au <strong>{moment(classe.registrationOpenEndAt).format('DD/MM/YYYY')}</strong> à 23h59:59</>
                      }
                    </>
                  ) : (
                    <>
                      Fermées
                    </>
                  )}
                </Field>
                <Field label="Template email à l'inscription">
                  { classe.emailTemplateId ? (
                    <>
                      {{
                        '4851475': 'Mail juin 2023 - Distanciel replay',
                        '4851441': 'Mail juin 2023 - présentiel',
                        '4851465': 'Mail juin 2023 - Distanciel',
                        '4807785': 'Mail juin 2023 - MOOC',
                      }[classe.emailTemplateId]}
                    </>
                  ) : (
                    <>
                      Aucun
                    </>
                  )}
                </Field>
                <Field label="MOOC">
                  {classe.mooc ? 'Oui' : 'Non'}
                </Field>
                <Field label="Replay">
                  {classe.replay ? `Oui${classe.replayStartAt ? ` à partir du ${moment(classe.replayStartAt).format('DD/MM/YYYY')}`: ''}` : 'Non'}
                </Field>
              </div>
              <div style={{ padding: '16px', borderRadius: '8px', background: '#F9F9F9', border: '1px solid #E9E9E9' }}>
                <h3 style={{ display: 'flex', justifyContent: 'space-between', margin: '0px', paddingBottom: '8px', marginBottom: '16px', borderBottom: '1px solid #E9E9E9' }}>
                  Séances
                  <small style={{ color: '#007bff', cursor: 'pointer', fontWeight: 500 }} onClick={() => modal.open(<UpdateMeeting classe={classe} onUpdated={classe => onUpdated(classe)} />)}>Modifier</small>
                </h3>
                { classe.channel === 'distanciel' &&
                  <Field label="Cours associée en présentiel">
                    { classe.presentielClasse === null ? (
                      <>Aucun cours associé</>
                    ) : (
                      <Link to="classe" params={{ id: classe.presentielClasse.id }}>{classe.presentielClasse.number} • {classe.presentielClasse.name}</Link>
                    )}
                  </Field>
                }
                <Field label="Période">
                  {classe.period ? { annual: 'Annuel', 'first-semester': '1er semestre', 'second-semester':'2nd semestre'}[classe.period] : 'n/a'}
                </Field>
                <Field label="Année">
                  {classe.year ? `${classe.year} - ${classe.year+1}` : 'n/a'}
                </Field>
                { classe.channel === 'presentiel' &&
                  <Field label="Capacité">
                    { classe.capacity === null ? (
                      <>Aucune limite</>
                    ) : (
                      <>{classe.capacity || 'n/a'} places</>
                    )}
                  </Field>
                }
                <Field label="Durée">
                  {classe.duration || 'n/a'} heures de cours au total
                  <br/>
                  {classe.weekNumber || 'n/a'} semaines de cours au total
                </Field>
                <Field label="Date du premier cours">
                  { classe.firstClasseAt ? (
                    <> Le premier cours aura lieu le {moment(classe.firstClasseAt).format('DD/MM/YYYY')}</>
                  ) : (
                    <>n/a</>
                  )}
                </Field>
                <Field label="Date du dernier cours">
                  { classe.lastClasseAt ? (
                    <> Le dernier cours aura lieu le {moment(classe.lastClasseAt).format('DD/MM/YYYY')}</>
                  ) : (
                    <>n/a</>
                  )}
                </Field>
              </div>
            </>
          ) : (
            <>
              <div style={{ padding: '16px', borderRadius: '8px', background: '#F9F9F9', border: '1px solid #E9E9E9' }}>
                <h3 style={{ display: 'flex', justifyContent: 'space-between', margin: '0px', paddingBottom: '8px', marginBottom: '16px', borderBottom: '1px solid #E9E9E9' }}><span>Informations Etipi</span></h3>
                <Field label="Titre du cours">
                  {classe.name}
                </Field>
                <Field label="Etipi ID">
                  {classe.distancielId}
                </Field>
                <Field label="Lien du cours sur Etipi">
                  {classe.distancielLink}
                </Field>
                <Field label="Date du premier cours">
                  { classe.firstClasseAt ? (
                    <> Le premier cours aura lieu le {moment(classe.firstClasseAt).format('DD/MM/YYYY')}</>
                  ) : (
                    <>n/a</>
                  )}
                </Field>
                <Field label="Date du dernier cours">
                  { classe.lastClasseAt ? (
                    <> Le dernier cours aura lieu le {moment(classe.lastClasseAt).format('DD/MM/YYYY')}</>
                  ) : (
                    <>n/a</>
                  )}
                </Field>
                <Field label="Lien d'inscription">
                  {/* <a target="_blank" href={`https://student.bernardins.test.qdeneuve.com/campus/classe/${classe.distancielId}`}>https://student.bernardins.test.qdeneuve.com/campus/classe/{classe.distancielId}</a> */}
                  <a target="_blank" href={`https://alpha.collegedesbernardins.fr/campus/classe/${classe.distancielId}`}>https://alpha.collegedesbernardins.fr/campus/classe/{classe.distancielId}</a>
                </Field>
                <Field label="Description" box>
                  <div style={{ borderRadius: '8px', border: '1px solid #E9E9E9', padding: '10px', fontFamily: 'Courier New' }} dangerouslySetInnerHTML={{ __html: nl2br(classe.description) }} />
                </Field>
              </div>
              <div style={{ padding: '16px', borderRadius: '8px', background: '#F9F9F9', border: '1px solid #E9E9E9' }}>
                <h3 style={{ display: 'flex', justifyContent: 'space-between', margin: '0px', paddingBottom: '8px', marginBottom: '16px', borderBottom: '1px solid #E9E9E9' }}>
                  Autres informations
                  <small style={{ color: '#007bff', cursor: 'pointer', fontWeight: 500 }} onClick={() => modal.open(<UpdateEtipiInformations classe={classe} onUpdated={classe => onUpdated(classe)} />)}>Modifier</small>
                </h3>
                <Field label="Département programmateur">
                  {classe.department ? ({
                    'cp': 'Cours Publics',
                    'fnd': 'FND',
                    'issr': 'ISSR',
                    'fr': 'FEB',
                    'ccdej': 'CCDEJ',
                    'fcf': 'FCF',
                    'e-learning': 'ELEARNING',
                    'other': 'Autre',
                  }[classe.department]) :  (<div style={{ color: 'red', display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.9522 16.3536L10.2152 5.85658C10.9531 4.38481 13.0539 4.3852 13.7913 5.85723L19.0495 16.3543C19.7156 17.6841 18.7487 19.25 17.2613 19.25H6.74007C5.25234 19.25 4.2854 17.6835 4.9522 16.3536Z"></path>
                      <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10V12"></path>
                      <circle cx="12" cy="16" r="1" fill="currentColor"></circle>
                    </svg>
                    Aucun
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.9522 16.3536L10.2152 5.85658C10.9531 4.38481 13.0539 4.3852 13.7913 5.85723L19.0495 16.3543C19.7156 17.6841 18.7487 19.25 17.2613 19.25H6.74007C5.25234 19.25 4.2854 17.6835 4.9522 16.3536Z"></path>
                      <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10V12"></path>
                      <circle cx="12" cy="16" r="1" fill="currentColor"></circle>
                    </svg>
                  </div>)}
                </Field>
                <Field label="Code produit">
                  {classe.productCode}
                </Field>
                <Field label="Cours associée en présentiel">
                  { classe.presentielClasse === null ? (
                    <>Aucun cours associé</>
                  ) : (
                    <Link to="classe" params={{ id: classe.presentielClasse.id }}>{classe.presentielClasse.number} • {classe.presentielClasse.name}</Link>
                  )}
                </Field>
                <Field label="Période">
                  {classe.period ? { annual: 'Annuel', 'first-semester': '1er semestre', 'second-semester':'2nd semestre'}[classe.period] : 'n/a'}
                </Field>
                <Field label="Année">
                  {classe.year ? `${classe.year} - ${classe.year+1}` : 'n/a'}
                </Field>
                <Field label="Template email à l'inscription">
                  { classe.emailTemplateId ? (
                    <>
                      {{
                        '4851475': 'Mail juin 2023 - Distanciel replay',
                        '4851441': 'Mail juin 2023 - présentiel',
                        '4851465': 'Mail juin 2023 - Distanciel',
                        '4807785': 'Mail juin 2023 - MOOC',
                      }[classe.emailTemplateId]}
                    </>
                  ) : (
                    <>
                      Aucun
                    </>
                  )}
                </Field>
                <Field label="MOOC">
                  {classe.mooc ? 'Oui' : 'Non'}
                </Field>
                <Field label="Replay">
                  {classe.replay ? `Oui${classe.replayStartAt ? ` à partir du ${moment(classe.replayStartAt).format('DD/MM/YYYY')}`: ''}` : 'Non'}
                </Field>
                <Field label="Inscriptions">
                  { classe.registrationOpen ? (
                    <>
                      Ouvertes
                      { classe.registrationOpenStartAt &&
                        <> à partir du <strong>{moment(classe.registrationOpenStartAt).format('DD/MM/YYYY')}</strong> à 00h00:01</>
                      }
                      { classe.registrationOpenEndAt &&
                        <> jusqu'au <strong>{moment(classe.registrationOpenEndAt).format('DD/MM/YYYY')}</strong> à 23h59:59</>
                      }
                    </>
                  ) : (
                    <>
                      Fermées
                    </>
                  )}
                </Field>
              </div>
            </>
          )}

          
          <div style={{ padding: '16px', borderRadius: '8px', background: '#F9F9F9', border: '1px solid #E9E9E9' }}>
            <h3 style={{ display: 'flex', justifyContent: 'space-between', margin: '0px', paddingBottom: '8px', marginBottom: '16px', borderBottom: '1px solid #E9E9E9' }}>
              Tarifs  
              <small style={{ color: '#007bff', cursor: 'pointer', fontWeight: 500 }} onClick={() => modal.open(<UpdatePrices classe={classe} onUpdated={classe => onUpdated(classe)} />)}>Modifier</small>
            </h3>
            <Field label="Tarif Institutions">
              {classe.fullPriceTtc || 'n/a '}€
            </Field>
            <Field label="Tarif individuel">
              {classe.individualPriceTtc || 'n/a '}€
            </Field>
            <Field label="Tarif jeune - 30 ans">
              {classe.youthPriceTtc || 'n/a '}€
            </Field>
          </div>
        </div>
      </div>
      <div style={{ width: '350px' }}>
        { classe.packages && classe.packages.length > 0 &&
          <>
            <h3 style={{ margin: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              Package{classe.packages.length > 1 && <>s</>}
            </h3>
            {
              classe.packages.map(({ package: classePackage }) => (
                <div key={classePackage.id} style={{ marginTop: '10px', marginBottom: '10px', borderRadius: '8px', border: '1px solid #ccc', padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Link to="package" params={{ id: classePackage.id }}>{classePackage.name}</Link>
                </div>
              ))
            }
          </>
        }
        <h3 style={{ margin: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Professeur{classe.professors.length > 1 && <>s</>}
          <svg xmlns="http://www.w3.org/2000/svg" onClick={() => modal.open(<SelectProfessorModal onSelected={onProfessorSelected} excludeIds={classe.professors.map(p => p.professor.id)} />)} style={{ width: '12px', height: '12px', fill: '#520b4e', cursor: 'pointer' }} height="426.66667pt" viewBox="0 0 426.66667 426.66667" width="426.66667pt"><path d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0"/></svg>
        </h3>
        {
          classe.professors.sort((a, b) => {
            if (a.professor.user.firstname.toLowerCase() > b.professor.user.firstname.toLowerCase()) {
              return 1;
            }
            if (a.professor.user.firstname.toLowerCase() < b.professor.user.firstname.toLowerCase()) {
              return -1;
            }
            return 0;
          }).map(({ professor }) => (
            <div key={professor.id} style={{ marginTop: '10px', marginBottom: '10px', borderRadius: '8px', border: '1px solid #ccc', padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Link to="professor" params={{ id: professor.id }}>{professor.user.firstname} {professor.user.lastname.toUpperCase()}</Link>
              <svg style={{ width: '12px', cursor: 'pointer' }} onClick={() => onProfessorRemove(professor)} version="1.1" viewBox="0 0 20 20" x="0px" y="0px"><g><path d="M8.5 10L4 5.5 5.5 4 10 8.5 14.5 4 16 5.5 11.5 10l4.5 4.5-1.5 1.5-4.5-4.5L5.5 16 4 14.5 8.5 10z"></path></g></svg>
            </div>
          ))
        }
        { classe.professors.length === 0 &&
          <div style={{ color: 'grey', textAlign: 'center', marginTop: '10px', marginBottom: '20px' }}>
            Aucun
          </div>
        }

        <FileList entity="classes" entityId={classe.id} />

        <h3 style={{ margin: '0px', marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Produit{classe.shopifyProducts.length > 1 && <>s</>} Shopify
          <svg xmlns="http://www.w3.org/2000/svg" onClick={() => modal.open(<SelectShopifyProduct onSelected={onShopifyProductSelected} excludeIds={classe.shopifyProducts.map(p => p.id)} />)} style={{ width: '12px', height: '12px', fill: '#520b4e', cursor: 'pointer' }} height="426.66667pt" viewBox="0 0 426.66667 426.66667" width="426.66667pt"><path d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0"/></svg>
        </h3>
        {
          classe.shopifyProducts.map(shopifyProduct => (
            <div key={shopifyProduct.id} style={{ marginTop: '10px', marginBottom: '10px', borderRadius: '8px', border: '1px solid #ccc', display: 'flex', alignItems: 'center', gap: '16px', position: 'relative' }}>
              { shopifyProduct.shopifyImageUrl ? <img style={{ width: '40px', height: '60px', flex: '0 0 auto', objectFit: 'cover' }} src={shopifyProduct.shopifyImageUrl} alt="" /> : <div className="fake-img"></div> }
              <div style={{ flex: 1, padding: '10px 0px' }}>
                <a target="_blank" href={`${Api.SHOPIFY_ADMIN_URL}/admin/products/${shopifyProduct.shopifyProductId}`}>{shopifyProduct.shopifyProductTitle}</a>
                <br />
                • {shopifyProduct.shopifyVariantTitle}
              </div>
              <div style={{ padding: '10px', cursor: 'pointer', flex : '0 0 auto' }} onClick={() => onShopifyProductRemove(shopifyProduct)}>
                <svg style={{ width: '12px' }} version="1.1" viewBox="0 0 20 20" x="0px" y="0px"><g><path d="M8.5 10L4 5.5 5.5 4 10 8.5 14.5 4 16 5.5 11.5 10l4.5 4.5-1.5 1.5-4.5-4.5L5.5 16 4 14.5 8.5 10z"></path></g></svg>
              </div>
            </div>
          ))
        }
        { classe.shopifyProducts.length === 0 &&
          <div style={{ color: 'grey', textAlign: 'center', marginTop: '10px', marginBottom: '20px' }}>
            Aucun
          </div>
        }

        <h3 style={{ margin: '0px', marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Produit{classe.campusProducts.length > 1 && <>s</>} Campus
          <svg xmlns="http://www.w3.org/2000/svg" onClick={() => modal.open(<SelectCampusProduct onSelected={onCampusProductSelected} excludeIds={classe.campusProducts.map(p => p.id)} />)} style={{ width: '12px', height: '12px', fill: '#520b4e', cursor: 'pointer' }} height="426.66667pt" viewBox="0 0 426.66667 426.66667" width="426.66667pt"><path d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0"/></svg>
        </h3>
        {
          classe.campusProducts.map(campusProduct => (
            <div key={campusProduct.id} style={{ marginTop: '10px', marginBottom: '10px', borderRadius: '8px', border: '1px solid #ccc', display: 'flex', alignItems: 'center', gap: '16px', position: 'relative' }}>
              { campusProduct.campusImageUrl ? <img style={{ width: '40px', height: '60px', flex: '0 0 auto', objectFit: 'cover' }} src={campusProduct.campusImageUrl} alt="" /> : <div className="fake-img"></div> }
              <div style={{ flex: 1, padding: '10px 0px' }}>
                <a target='_blank' href={`${Api.CAMPUS_ADMIN_URL}/post.php?post=${campusProduct.campusProductId}&action=edit`}>{campusProduct.campusProductTitle}</a>
                <br />
                • {campusProduct.campusVariantTitle}
              </div>
              <div style={{ padding: '10px', cursor: 'pointer', flex : '0 0 auto' }} onClick={() => onCampusProductRemove(campusProduct)}>
                <svg style={{ width: '12px' }} version="1.1" viewBox="0 0 20 20" x="0px" y="0px"><g><path d="M8.5 10L4 5.5 5.5 4 10 8.5 14.5 4 16 5.5 11.5 10l4.5 4.5-1.5 1.5-4.5-4.5L5.5 16 4 14.5 8.5 10z"></path></g></svg>
              </div>
            </div>
          ))
        }
        { classe.campusProducts.length === 0 &&
          <div style={{ color: 'grey', textAlign: 'center', marginTop: '10px', marginBottom: '20px' }}>
            Aucun
          </div>
        }
      </div>
    </div>
  );
}
