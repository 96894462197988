import React, { useState, useContext, createContext } from "react";
import { v4 as uuidv4 } from 'uuid';

import './modal.scss';

const modalContext = createContext();
modalContext.displayName = 'Modal';

export function ProvideModal({ children }) {
  const modal = useProvideModal();

  return (
    <modalContext.Provider value={modal}>
      { 
        modal.components.map(({ content, id, options }) => (
          <div key={id} className={`component-modal${options.size ? ` size-${options.size}` : ''}${options.closable === false ? ' not-closable' : ''}`} onClick={() => modal.close(id)}>
            <div className="wrapper" onClick={event => event.stopPropagation()}>
              {content}
            </div>
          </div>
        ))
      }
      {children}
    </modalContext.Provider>
  );
}

export const useModal = () => {
  return useContext(modalContext);
};

function useProvideModal() {
  const [components, setComponents] = useState([]);
  
  return {
    components,
    open: (content, options = {}, closeCallback = null) => {
      setComponents(prevComponents => ([ ...prevComponents, {
        id: uuidv4(),
        content,
        options,
        closeCallback
      }]));
    },
    close: (id = null) => {
      let component = components[components.length - 1];
      if (id !== null) {
        component = components.find(cp => cp.id === id);
      }
      if (component === null || component === undefined) {
        throw new Error ('Modal not found');
      }

      if (component.options.closable !== false) {
        if (component.closeCallback) {
          component.closeCallback();
        }
        setComponents(prevComponents => {
          const newComponents = [...prevComponents];
          const index = components.findIndex(cp => cp.id === id);
          newComponents.splice(index, 1);
          return newComponents;
        });
      }
    },
    closeAll: () => {
      components.forEach(({ closeCallback }) => {
        if (closeCallback) closeCallback();
      });
      setComponents([]);
    }
  };
};
