import Dashboard from "../pages/Dashboard";

import Statistics from "../pages/Statistics";

import StudentsList from "../pages/students/List";
import StudentRessource from "../pages/students/Ressource";
import StudentCreate from "../pages/students/Create";
import StudentRessourceOverview from "../pages/students/ressource/Overview";
import StudentRessourceClasses from "../pages/students/ressource/Classes";
import StudentRessourceRegistrations from "../pages/students/ressource/Registrations";
import StudentRessourceProducts from "../pages/students/ressource/Products";

import ClassesList from "../pages/classes/List";
import ClasseRessource from "../pages/classes/Ressource";
import ClasseCreate from "../pages/classes/Create";
import ClasseRessourceOverview from "../pages/classes/ressource/Overview";
import ClasseRessourceRegistrations from "../pages/classes/ressource/Registrations";
import ClasseRessourceStudents from "../pages/classes/ressource/Students";

import PackagesList from "../pages/packages/List";
import PackageRessource from "../pages/packages/Ressource";
import PackageCreate from "../pages/packages/Create";
import PackageRessourceOverview from "../pages/packages/ressource/Overview";
import PackageRessourceRegistrations from "../pages/packages/ressource/Registrations";
import PackageRessourceStudents from "../pages/packages/ressource/Students";

import ProfessorsList from "../pages/professors/List";
import ProfessorRessource from "../pages/professors/Ressource";
import ProfessorCreate from "../pages/professors/Create";
import ProfessorRessourceOverview from "../pages/professors/ressource/Overview";
import ProfessorRessourceClasses from "../pages/professors/ressource/Classes";

import PayersList from "../pages/payers/List";
import PayerRessource from "../pages/payers/Ressource";
import PayerCreate from "../pages/payers/Create";
import PayerRessourceOverview from "../pages/payers/ressource/Overview";
import PayerRessourceBills from "../pages/payers/ressource/Bills";

import ProductsList from "../pages/products/List";
import ProductRessource from "../pages/products/Ressource";
import ProductCreate from "../pages/products/Create";
import ProductRessourceOverview from "../pages/products/ressource/Overview";
import ProductRessourceBills from "../pages/products/ressource/Bills";

import TutorsList from "../pages/tutors/List";
import TutorRessource from "../pages/tutors/Ressource";
import TutorCreate from "../pages/tutors/Create";
import TutorRessourceOverview from "../pages/tutors/ressource/Overview";

import ObserversList from "../pages/observers/List";
import ObserverRessource from "../pages/observers/Ressource";
import ObserverCreate from "../pages/observers/Create";
import ObserverRessourceOverview from "../pages/observers/ressource/Overview";

import RegistrationsList from "../pages/registrations/List";
import RegistrationRessource from "../pages/registrations/Ressource";
import RegistrationCreate from "../pages/registrations/Create";
import RegistrationRessourceOverview from "../pages/registrations/ressource/Overview";

import BillsList from "../pages/bills/List";

import DonationsList from "../pages/donations/List";

import AdminsList from "../pages/admins/List";
import AdminRessource from "../pages/admins/Ressource";
import AdminCreate from "../pages/admins/Create";
import AdminRessourceOverview from "../pages/admins/ressource/Overview";
import Activate from "../pages/activate/Activate";
import ResetPassword from "../pages/resetPassword/ResetPassword";

export default [
  {
    name: 'dashboard',
    url: '/',
    component: Dashboard,
    acl: 'user',
  },
  {
    name: 'bills',
    url: '/bills',
    component: BillsList,
    acl: 'user',
  },
  {
    name: 'donations',
    url: '/donations',
    component: DonationsList,
    acl: 'user',
  },
  {
    name: 'admins',
    url: '/admins',
    component: AdminsList,
    acl: 'admin',
  },
  {
    name: 'createAdmin',
    url: '/admins/create',
    component: AdminCreate,
    acl: 'admin',
  },
  {
    name: 'admin',
    url: '/admin/:id',
    component: AdminRessource,
    acl: 'admin',
    children: [
      {
        name: '_default',
        url: '',
        component: AdminRessourceOverview,
      },
    ]
  },
  {
    name: 'statistics',
    url: '/statistics',
    component: Statistics,
    acl: 'user',
  },
  {
    name: 'students',
    url: '/students',
    component: StudentsList,
    acl: 'user',
  },
  {
    name: 'createStudent',
    url: '/students/create',
    component: StudentCreate,
    acl: 'user',
  },
  {
    name: 'student',
    url: '/student/:id',
    component: StudentRessource,
    acl: 'user',
    children: [
      {
        name: '_default',
        url: '',
        component: StudentRessourceOverview,
      },
      {
        name: 'classes',
        url: '/classes',
        component: StudentRessourceClasses,
      },
      {
        name: 'registrations',
        url: '/registrations',
        component: StudentRessourceRegistrations,
      },
      {
        name: 'products',
        url: '/products',
        component: StudentRessourceProducts,
      },
    ]
  },
  {
    name: 'classes',
    url: '/classes',
    component: ClassesList,
    acl: 'user',
  },
  {
    name: 'createClasse',
    url: '/classes/create',
    component: ClasseCreate,
    acl: 'user',
  },
  {
    name: 'classe',
    url: '/classe/:id',
    component: ClasseRessource,
    acl: 'user',
    children: [
      {
        name: '_default',
        url: '',
        component: ClasseRessourceOverview,
      },
      {
        name: 'students',
        url: '/students',
        component: ClasseRessourceStudents,
      },
      {
        name: 'registrations',
        url: '/registrations',
        component: ClasseRessourceRegistrations,
      },
    ]
  },
  {
    name: 'packages',
    url: '/packages',
    component: PackagesList,
    acl: 'user',
  },
  {
    name: 'createPackage',
    url: '/packages/create',
    component: PackageCreate,
    acl: 'user',
  },
  {
    name: 'package',
    url: '/package/:id',
    component: PackageRessource,
    acl: 'user',
    children: [
      {
        name: '_default',
        url: '',
        component: PackageRessourceOverview,
      },
      {
        name: 'registrations',
        url: '/registrations',
        component: PackageRessourceRegistrations,
      },
      {
        name: 'students',
        url: '/students',
        component: PackageRessourceStudents,
      },
    ]
  },
  {
    name: 'professors',
    url: '/professors',
    component: ProfessorsList,
    acl: 'user',
  },
  {
    name: 'createProfessor',
    url: '/professors/create',
    component: ProfessorCreate,
    acl: 'user',
  },
  {
    name: 'professor',
    url: '/professor/:id',
    component: ProfessorRessource,
    acl: 'user',
    children: [
      {
        name: '_default',
        url: '',
        component: ProfessorRessourceOverview,
      },
      {
        name: 'classes',
        url: '/classes',
        component: ProfessorRessourceClasses,
      },
    ]
  },
  {
    name: 'payers',
    url: '/payers',
    component: PayersList,
    acl: 'user',
  },
  {
    name: 'createPayer',
    url: '/payers/create',
    component: PayerCreate,
    acl: 'user',
  },
  {
    name: 'payer',
    url: '/payer/:id',
    component: PayerRessource,
    acl: 'user',
    children: [
      {
        name: '_default',
        url: '',
        component: PayerRessourceOverview,
      },
      {
        name: 'bills',
        url: '/bills',
        component: PayerRessourceBills,
      },
    ]
  },
  {
    name: 'products',
    url: '/products',
    component: ProductsList,
    acl: 'user',
  },
  {
    name: 'createProduct',
    url: '/products/create',
    component: ProductCreate,
    acl: 'user',
  },
  {
    name: 'product',
    url: '/product/:id',
    component: ProductRessource,
    acl: 'user',
    children: [
      {
        name: '_default',
        url: '',
        component: ProductRessourceOverview,
      },
      {
        name: 'bills',
        url: '/bills',
        component: ProductRessourceBills,
      },
    ]
  },
  {
    name: 'observers',
    url: '/observers',
    component: ObserversList,
    acl: 'user',
  },
  {
    name: 'createObserver',
    url: '/observers/create',
    component: ObserverCreate,
    acl: 'user',
  },
  {
    name: 'observer',
    url: '/observer/:id',
    component: ObserverRessource,
    acl: 'user',
    children: [
      {
        name: '_default',
        url: '',
        component: ObserverRessourceOverview,
      },
    ]
  },
  {
    name: 'tutors',
    url: '/tutors',
    component: TutorsList,
    acl: 'user',
  },
  {
    name: 'createTutor',
    url: '/tutors/create',
    component: TutorCreate,
    acl: 'user',
  },
  {
    name: 'tutor',
    url: '/tutor/:id',
    component: TutorRessource,
    acl: 'user',
    children: [
      {
        name: '_default',
        url: '',
        component: TutorRessourceOverview,
      },
    ]
  },
  {
    name: 'registrations',
    url: '/registrations',
    component: RegistrationsList,
    acl: 'user',
  },
  {
    name: 'createRegistration',
    url: '/registrations/create',
    component: RegistrationCreate,
    acl: 'user',
  },
  {
    name: 'registration',
    url: '/registration/:id',
    component: RegistrationRessource,
    acl: 'user',
    children: [
      {
        name: '_default',
        url: '',
        component: RegistrationRessourceOverview,
      },
    ]
  },

  // Guest pages
  {
    name: 'reset-password',
    url: '/reset-password/:code',
    component: ResetPassword,
    acl: 'guest',
  },
  {
    name: 'activate',
    url: '/activate/:code',
    component: Activate,
    acl: 'guest',
  }
];