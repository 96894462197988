import React from 'react';

import { useNotification } from '../../hooks/notification/Notification';
import { useModal } from '../../hooks/modal/Modal';
import Api from '../../services/Api';
import { Head } from '../../components/head';
import { Input, Select, useForm } from '../../hooks/form';

export default function UpdatePayerModal({ registration, payer, onUpdated }) {
  const notification = useNotification();
  const modal = useModal();
  const form = useForm(payer, (data) => {
    if (registration) {
      Api.post(`/registrations/${registration.id}/payers/${payer.payer.id}`, data)
        .then(response => {
          onUpdated(response.data);
          notification.notify('Le montant a été modifié !');
          modal.close();
        })
        .catch(error => {
          alert('Une erreur est survenue');
        });
    } else {
      onUpdated(data);
      modal.close();
    }
  })
  return (
    <div className="list-page" style={{ flex: 1 }}>
      <Head sub noTabs title={`Modifier le montant de ${payer.payer.user.firstname} ${payer.payer.user.lastname.toUpperCase()}`} />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        <Input suffix="€ TTC" label="Montant" {...form.field('amount', { float: true })} />
        <br/>
        <Select label="Mode de paiement" noSearch {...form.field('paymentMethod')}>
          <option value="web">Web</option>
          <option value="bankcard">CB</option>
          <option value="change">espèces</option>
          <option value="check">Chèque</option>
          <option value="bank-transfer">Virement</option>
        </Select>
        <br/>
        <button className="btn full">Enregistrer</button>
      </form>
    </div>
  );
}