import React from 'react';

import { Head } from '../../components/head';
import { Input, useForm } from '../../hooks/form';
import { useNotification } from '../../hooks/notification/Notification';
import { useRouter } from '../../hooks/router';
import Api from '../../services/Api';

export default function ProfessorCreate() {
  const router = useRouter();
  const notification = useNotification();
  const form = useForm({
    firstname: '',
    lastname: '',
    email: ''
  }, (data) => {
    Api.post('/professors', data)
      .then(response => {
        notification.notify('Le professeur a bien été créé !');
        router.goTo('professor', { id: response.data.id });
      }).catch(() => {
        // @todo error when email is used
        alert('Une erreur est survenue');
      })
  });

  return (
    <div className="ressource-page">
      <Head noTabs title="Nouveau professeur" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px', maxWidth: '450px' }}>
        <Input label="Prénom" {...form.field('firstname')} />
        <br/>
        <Input label="Nom" {...form.field('lastname')} />
        <br/>
        <Input label="Email" {...form.field('email')} />
        <br/>
        <button className="btn">Enregistrer</button>
      </form>
    </div>
  );
}
