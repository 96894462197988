import React from 'react';

import { Link } from '../../hooks/router';
import { Input } from '../../hooks/form';

import { Head } from '../../components/head';
import { List, useList } from '../../hooks/list';

export default function AdminsList() {
  const { filters, items, isLoading, load } = useList('admins', {
    search: '',
    group: null,
  });

  return (
    <div className="list-page">
      <Head title="Administrateurs">
        <Link to="createAdmin" className="btn">Nouveau</Link>
      </Head>
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
      </form>
      <List onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Prénom' }, { name: 'Nom' }, { name: 'Email' }, { name: 'Groupe' }]}>
        { items.map(admin => (
          <Link key={admin.id} className="item" to="admin" params={{ id: admin.id }}>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              {admin.user.firstname}
            </div>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              {admin.user.lastname}
            </div>
            <div className="prop">
              {admin.user.email}
            </div>
            <div className="prop">
              {admin.group || 'Administrateur'}
            </div>
          </Link>
        ))}
      </List>
    </div>
  );
};