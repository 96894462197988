import React from 'react';

import { Head } from '../../components/head';
import { Input, useForm } from '../../hooks/form';
import { useNotification } from '../../hooks/notification/Notification';
import { useRouter } from '../../hooks/router';
import Api from '../../services/Api';

export default function PayerCreate() {
  const router = useRouter();
  const notification = useNotification();
  const form = useForm({
    type: 'company',
    firstname: '',
    lastname: '',
    email: ''
  }, (data) => {
    Api.post('/payers', data)
      .then(response => {
        notification.notify('Le payeur a bien été créé !');
        router.goTo('payer', { id: response.data.id });
      }).catch((error) => {
        // @todo error when email is used
        if (error && error.response && error.response.data && error.response.data.code) {
          form.setErrors({
            email: 'L\'email existe déjà pour un payeur'
          })
        } else {
          alert('Une erreur est survenue');
        }
      })
  });

  return (
    <div className="ressource-page">
      <Head noTabs title="Nouveau payeur institution" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px', maxWidth: '450px' }}>
        <Input label="Raison sociale" {...form.field('companyName')} />
        <br/>
        <Input label="Email" {...form.field('email')} />
        <br />
        <Input label="Nom" {...form.field('lastname')} />
        <br/>
        <Input label="Prénom" {...form.field('firstname')} />
        <br />
        <Input label="Téléphone" {...form.field('phoneNumber')} />
        <br/>
        <Input label="Adresse" {...form.field('address')} />
        <br/>
        <Input label="Code postal" {...form.field('zipcode')} />
        <br/>
        <Input label="Ville" {...form.field('city')} />
        <br />
        <button className="btn">Enregistrer</button>
      </form>
    </div>
  );
}
