import React from 'react';

import { Link } from '../../hooks/router';
import { Input } from '../../hooks/form';

import { Head } from '../../components/head';
import { List, useList } from '../../hooks/list';

export default function ObserversList() {
  const { filters, items, isLoading, load } = useList('observers', {
    search: '',
  });

  return (
    <div className="list-page">
      <Head title="Observateurs">
        <Link to="createObserver" className="btn">Nouveau</Link>
      </Head>
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
      </form>
      <List onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Étudiant', sort: 'lastname' }, { name: 'Email' }, { name: 'Téléphone' }, { name: 'Civilité' }]}>
        { items.map(observer => (
          <Link key={observer.id} className="item" to="observer" params={{ id: observer.id }}>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '10px', borderRadius: '10px', background: 'green', width: '10px', height: '10px', flex: '0 0 auto' }}></div>
              <span>
                {observer.user.firstname} {observer.user.lastname.toUpperCase()}
              </span>
            </div>
            <div className="prop">
              {observer.user.email}
            </div>
            <div className="prop">
              {observer.phoneNumber}
            </div>
            <div className="prop">
              {observer.civility ? {
                'mr': 'M.',
                'mrs': 'Mme',
                'father': 'Père',
                'sister': 'Soeur',
                'brother': 'Frère',
                'mgr': 'Mgr',
              }[observer.civility] : 'n/a'}
            </div>
          </Link>
        ))}
      </List>
    </div>
  );
};