import React, { useState } from 'react';

import './profilePicture.scss';
import Api from '../../services/Api';
import { Loader } from '../loader';
import { useUpload } from '../../hooks/form';
import { useNotification } from '../../hooks/notification/Notification';

export default function ProfilePicture({ profilePicture, onUploaded, url }) {
  const notification = useNotification();
  const [uploadstate, setUploadstate] = useState({ uploading: false, percent: 0 });
  const { start } = useUpload({ onUpload: (file) => {
    setUploadstate({ uploading: true, percent: 0 });
    Api.upload(url, file, ({ percent }) => {
      setUploadstate(prevState => ({ ...prevState, percent }));
    }).then(response => {
        onUploaded(response.data);
        notification.notify('L\'image a été ajoutée !');
        setUploadstate({ uploading: false, percent: 0 });
      }).catch(error => {
        console.log(error);
        setUploadstate({ uploading: false, percent: 0 });
        alert('Une erreur est survenue');
      });
  }, types: ['image/png', 'image/jpg', 'image/jpeg']});

  function remove() {
    if (window.confirm('Êtes vous certains de supprimer l\'image ?')) {
      Api.del(url).then(response => {
        onUploaded(response.data);
        notification.notify('L\'image a été supprimée !');
      }).catch(error => {
        console.log(error);
        alert('Une erreur est survenue');
      });
    }
  }

  return (
    <div className="profilePicture-component">
      { profilePicture ? (
        <img src={profilePicture.url} alt="Profile" />
      ) : (
          <svg className="default" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
            <path d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148    C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962    c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216    h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40    c59.551,0,108,48.448,108,108S315.551,256,256,256z"/>
          </svg>
      )}
      <div className="upload" onClick={() => start()} >
        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4.75 14.75V16.25C4.75 17.9069 6.09315 19.25 7.75 19.25H16.25C17.9069 19.25 19.25 17.9069 19.25 16.25V14.75"></path>
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 14.25L12 5"></path>
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M8.75 8.25L12 4.75L15.25 8.25"></path>
        </svg>
      </div>
      { profilePicture &&
        <div className="delete" onClick={() => remove()}>
          <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M17.25 6.75L6.75 17.25"></path>
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M6.75 6.75L17.25 17.25"></path>
          </svg>
        </div>
      }
      { uploadstate.uploading &&
        <Loader style={{ position: 'absolute' }} />
      }
    </div>
  )
}