import React from 'react';

import './tabs.scss';

function injectToTab(route, children, params = {}) {
  return React.Children.map(children, child => {
    // checking isValidElement is the safe way and avoids a typescript error too
    if (child === null) {
      return null;
    } else if (child.type === (<></>).type) {
      return React.cloneElement(child, { children: injectToTab(route, child.children, params) });
    } else if (React.isValidElement(child)) {
      return React.cloneElement(child, { route, params });
    }
    return child;
  });
}

export default function Tabs({ route, sub, children, params = {}, noPadding }) {
  const tabWithRoute = injectToTab(route, children, params);

  return (
    <div className={`tabs-component${sub ? ' sub' : ''}${noPadding ? ' no-padding' : ''}`}>
      {tabWithRoute}
    </div>
  )
}