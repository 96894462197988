import React from 'react';
import { useAuth } from '../auth/Auth';
import { useRouter } from './Router';

export default function Route({ deep, notAuthorized, route, layout, ...props }) {
  const auth = useAuth();
  const router = useRouter();

  let renderedRoute = route;
  if (renderedRoute === undefined) {
    const tempRoute = router.route;
    if (tempRoute === false) {
      // If false, router didn't has time to run componentDidMount
      // Waiting for it
      return null;
    }
    renderedRoute = tempRoute;
    Array.from(Array(deep || 0)).forEach(() => {
      renderedRoute = renderedRoute.subRoute
    });
  }

  if (renderedRoute) {
    // Super admin
    // @todo redo this with group
    if (
      renderedRoute.acl === 'admin'
    ) {
      if (
        !auth.isAuthenticated
        || (
          auth?.user?.email !== 'geoffroydeboissieu@collegedesbernardins.fr'
          &&
          auth?.user?.email !== 'qdeneuve@icloud.com'
        )
      ) {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', color: 'red', height: '100vh', fontWeight: 'bold', fontSize: '2em' }}>Not Authorized</div>
        );
      }
    }
    if (
      renderedRoute.acl !== 'guest'
      // Only check "isAuthenticated", it is a synchronous state, user infos will be loaded after (API call) => faster rendering
      && !auth.isAuthenticated
    ) {
      if (notAuthorized) {
        const Component = notAuthorized;
        return <Component />
      } else {
        return (
          <div>Not authorized</div>
        );
      }
    } else {
      const RouteComponent = renderedRoute.component;
      const Layout = layout;
      if (Layout && auth.isAuthenticated) {
        return (
          <Layout>
            <RouteComponent {...props} route={renderedRoute} />
          </Layout>
        );
      } else {
        return (
          <RouteComponent {...props} route={renderedRoute} />
        );
      }
    }
  } else {
    if (props.notFound) {
      const Component = props.notFound;
      return <Component />
    } else {
      return (
        <div>NOT FOUND</div>
      );
    }
  }
};
