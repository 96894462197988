import React, { useState } from 'react';
import moment from 'moment';

import { Field } from '../../../components/field';
import { Head } from '../../../components/head';
import { Input, Select, useForm } from '../../../hooks/form';
import { List, useList } from '../../../hooks/list';
import { useModal } from '../../../hooks/modal/Modal';
import { Link } from '../../../hooks/router';
import { useNotification } from '../../../hooks/notification/Notification';
import Api from '../../../services/Api';
import { ProfilePicture } from '../../../components/profilePicture';
import { FileList } from '../../../components/fileList';
import { Alert } from '../../../components/alert';
import Validator from 'validator';

function SelectTutorModal({ onSelected }) {
  const modal = useModal();
  const [type, setType] = useState('professor');
  const { filters, items, isLoading, load } = useList(`${type}s`, {
    search: '',
    type: 'tutor'
  });

  function select(tutor) {
    onSelected(tutor, type);
    modal.close();
  }

  return (
    <div className="list-page" style={{ flex: 1 }}>
      <Head sub noTabs title={`Choisissez un tuteur parmis les ${type === 'tutor' ? 'tuteurs' : 'professeurs'}`} />
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
        <div className="filter">
          <button className="btn" onClick={() => setType(prevType => prevType === 'tutor' ? 'professor' : 'tutor')}>Choisir parmis les {type === 'tutor' ? 'professeurs' : 'tuteurs'}</button>
        </div>
      </form>
      <br/>
      <List onLoadMore={load} isLoading={isLoading}>
        { items.map(tutor => (
          <div onClick={() => select(tutor)} key={tutor.id} className="item" to="tutor" params={{ id: tutor.id }}>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '10px', borderRadius: '10px', background: 'green', width: '10px', height: '10px', flex: '0 0 auto' }}></div>
              <span>
                {tutor.user.firstname} {tutor.user.lastname.toUpperCase()}
              </span>
            </div>
            <div className="prop">
              {moment(tutor.createdAt).format('DD/MM/YYYY HH:mm:ss')}
            </div>
            <div className="prop">
              {tutor.status}
            </div>
          </div>
        ))}
      </List>
    </div>
  );
}

function UpdateInformations({ student, onUpdated }) {
  const notification = useNotification();
  const modal = useModal();
  const form = useForm(student, (data) => {
    Api.post(`/students/${student.id}/informations`, data)
      .then(response => {
        notification.notify('Les informations ont été mises à jour !');
        onUpdated(response.data);
        modal.close();
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.code === 'email-exists') {
          form.setErrors({
            email: 'L\'email est déjà utilisé'
          });
        } else {
          alert('Une erreur est survenue');
        }
      });
  });

  return (
    <div className="ressource-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Modifier les informations" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        <Select label="Civilité" noSearch {...form.field('civility')}>
          <option value={null}>Non définie</option>
          <option value="mr">M.</option>
          <option value="mrs">Mme</option>
          <option value="father">Père</option>
          <option value="sister">Soeur</option>
          <option value="brother">Frère</option>
          <option value="mgr">Mgr</option>
        </Select>
        <br/>
        <Input label="Numéro de téléphone" {...form.field('mobilePhoneNumber')} />
        <br/>
        <Input label="Nationalité" {...form.field('nationality')} />
        <br/>
        <Input label="Date de naissance" {...form.field('birthDate', { type: 'date' })} />
        <br/>
        <label htmlFor="">Centres d'interêts</label>
        <br />
        <button className="btn full">Enregistrer</button>
      </form>
    </div>
  );
}


function UpdatePrivacy({ student, onUpdated }) {
  const notification = useNotification();
  const modal = useModal();
  const form = useForm({ ...student.user, status: student.status }, (data) => {
    if (!Validator.isEmail(data.email)) {
      form.setErrors({
        email: 'Veuillez utiliser un email valide !'
      })

      return;
    }

    Api.post(`/students/${student.id}/privacy`, data)
      .then(response => {
        notification.notify('Les informations ont été mises à jour !');
        onUpdated(response.data);
        modal.close();
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.code === 'email-exists') {
          form.setErrors({
            email: 'L\'email est déjà utilisé'
          });
        } else {
          alert('Une erreur est survenue');
        }
      });
  });

  return (
    <div className="ressource-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Modifier les informations" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        <Input label="Prénom" {...form.field('firstname')} />
        <br/>
        <Input label="Nom" {...form.field('lastname')} />
        <br/>
        <Input label="Email" {...form.field('email')} />
        <br />
        <Select noSearch label="Statut de compte" {...form.field('status')}>
          <option value="pending">En attente de validation</option>
          <option value="activated">Compte activé</option>
        </Select>
        <br /><br />
        <button className="btn full">Enregistrer</button>
      </form>
    </div>
  );
}

function UpdateAddress({ student, onUpdated }) {
  const notification = useNotification();
  const modal = useModal();
  const form = useForm(student, (data) => {
    Api.post(`/students/${student.id}/address`, data)
      .then(response => {
        notification.notify('L\'adresse a été mise à jour !');
        onUpdated(response.data);
        modal.close();
      })
      .catch(() => {
        alert('Une erreur est survenue');
      });
  });

  return (
    <div className="ressource-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Modifier l'adresse" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        <Input label="Adresse" {...form.field('address')} />
        <br/>
        <Input label="Code postal" {...form.field('zipcode')} />
        <br/>
        <Input label="Ville" {...form.field('city')} />
        <br/>
        <Input label="Pays" {...form.field('country')} />
        <br/>
        <button className="btn full">Enregistrer</button>
      </form>
    </div>
  );
}

export default function StudentRessourceOverview({ student, setStudent }) {
  const modal = useModal();
  const notification = useNotification();

  function onTutorSelected(tutor, type) {
    Api.post(`/students/${student.id}/tutors`, { id: tutor.id, type })
      .then(response => {
        setStudent(response.data);
        notification.notify('Le tuteur a été ajouté !');
      }).catch(error => {
        console.log(error);
        alert('Une erreur est survenue');
      });
  }

  function onTutorRemove(tutor) {
    Api.del(`/students/${student.id}/tutors/${tutor.id}`)
      .then(response => {
        setStudent(response.data);
        notification.notify('Le tuteur a été retiré !');
      }).catch(error => {
        console.log(error);
        alert('Une erreur est survenue');
      });
  }

  function onUpdated(student) {
    setStudent(student);
  }

  function sendValidateAccountEmail() {
    if (window.confirm('Êtes vous certain d\'envoyer l\'email d\'activation de compte ?')) {
      Api.post(`/students/${student.id}/send-validation-email`)
        .then(() => {
          notification.notify(`L'email a bien été envoyé !`)
        })
        .catch(() => alert('Une erreur est survenue'));
    }
  }

  function sendResetPasswordEmail() {
    if (window.confirm('Êtes vous certain d\'envoyer l\'email de changement de mot de passe ?')) {
      Api.post(`/students/${student.id}/send-reset-password-email`)
        .then(() => {
          notification.notify(`L'email a bien été envoyé !`)
        })
        .catch(() => alert('Une erreur est survenue'));
    }
  }

  if (student && student.status === 'deleted') {
    return (
      <div style={{ padding: '20px' }}>
        <Alert type="red">
          L'utilisateur a été supprimé !
        </Alert>
      </div>
    )
  }

  return (
    <div className="ressource-page" style={{ padding: '20px', display: 'flex', flexDirection: 'row', gap: '16px' }}>
      <div style={{ flex: 1 }}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '16px' }}>
          
          <div style={{ padding: '16px', borderRadius: '8px', background: '#F9F9F9', border: '1px solid #E9E9E9' }}>
            
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ProfilePicture profilePicture={student.profilePicture} onUploaded={student => setStudent(student)} url={`/students/${student.id}/profile-picture`} />
              <div style={{ flex: 1, marginLeft: '20px' }}>
                <span style={{ fontWeight: '600' }}>{student.user.firstname} {student.user.lastname.toUpperCase()}</span>
                <br/>
                {student.user.email}
                <br />
                {{
                  pending: 'Ne s’est jamais connecté à son compte Alpha',
                  validated: 's’est déjà connecté à son compte Alpha',
                  activated: 'S’est déjà connecté à son compte Alpha',
                }[student.status]}
              </div>
              <div><button className="btn" onClick={() => modal.open(<UpdatePrivacy student={student} onUpdated={student => onUpdated(student)} />)}>Modifier</button></div>
            </div>
          </div>
          <div style={{ padding: '16px', borderRadius: '8px', background: '#F9F9F9', border: '1px solid #E9E9E9' }}>
            <h3 style={{ display: 'flex', justifyContent: 'space-between', margin: '0px', paddingBottom: '8px', marginBottom: '16px', borderBottom: '1px solid #E9E9E9' }}><span>Informations</span><small style={{ color: '#007bff', cursor: 'pointer', fontWeight: 500 }} onClick={() => modal.open(<UpdateInformations student={student} onUpdated={student => onUpdated(student)} />)}>Modifier</small></h3>
            <Field label="Civilité">
              {student.civility ? {
                'mr': 'M.',
                'mrs': 'Mme',
                'father': 'Père',
                'sister': 'Soeur',
                'brother': 'Frère',
                'mgr': 'Mgr',
              }[student.civility] : 'n/a'}
            </Field>
            <Field label="Numéro de tel">
              {student.mobilePhoneNumber || 'n/a'}
            </Field>
            <Field label="Nationalité">
              {student.nationality || 'n/a'}
            </Field>
            <Field label="Date de naissance">
              {student.birthDate ? moment(student.birthDate).format('DD/MM/YYYY') : 'n/a'}
            </Field>
          </div>
          <div style={{ padding: '16px', borderRadius: '8px', background: '#F9F9F9', border: '1px solid #E9E9E9' }}>
            <h3 style={{ display: 'flex', justifyContent: 'space-between', margin: '0px', paddingBottom: '8px', marginBottom: '16px', borderBottom: '1px solid #E9E9E9' }}><span>Adresse</span><small style={{ color: '#007bff', cursor: 'pointer', fontWeight: 500 }} onClick={() => modal.open(<UpdateAddress student={student} onUpdated={student => onUpdated(student)} />)}>Modifier</small></h3>
            <Field label="Adresse">
              {student.address || 'n/a'}
            </Field>
            <Field label="Code postal">
              {student.zipcode || 'n/a'}
            </Field>
            <Field label="Ville">
              {student.city || 'n/a'}
            </Field>
            <Field label="Pays">
              {student.country || 'n/a'}
            </Field>
          </div>
        </div>
      </div>
      <div style={{ width: '350px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginBottom: '16px' }}>
          { (student.status === 'activated' || student.status === 'validated') &&
            <button className="btn full normal" onClick={() => sendResetPasswordEmail()}>Email d'oublie de mot de passe</button>
          }
          { student.status === 'pending' &&
            <button className="btn full normal" onClick={() => sendValidateAccountEmail()}>Email d’activation de compte Alpha</button>
          }
        </div>
        <h3 style={{ margin: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Tuteur{student.tutors.length > 1 && <>s</>}
          <svg xmlns="http://www.w3.org/2000/svg" onClick={() => modal.open(<SelectTutorModal onSelected={onTutorSelected} excludeIds={student.tutors.map(p => p.tutor.id)} />)} style={{ width: '12px', height: '12px', fill: '#520b4e', cursor: 'pointer' }} height="426.66667pt" viewBox="0 0 426.66667 426.66667" width="426.66667pt"><path d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0"/></svg>
        </h3>
        {
          student.tutors.map(({ tutor }) => (
            <div key={tutor.id} style={{ marginTop: '10px', marginBottom: '10px', borderRadius: '8px', border: '1px solid #ccc', padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Link to="tutor" params={{ id: tutor.id }}>{tutor.user.firstname} {tutor.user.lastname.toUpperCase()}</Link>
              <svg style={{ width: '12px', cursor: 'pointer' }} onClick={() => onTutorRemove(tutor)} version="1.1" viewBox="0 0 20 20" x="0px" y="0px"><g><path d="M8.5 10L4 5.5 5.5 4 10 8.5 14.5 4 16 5.5 11.5 10l4.5 4.5-1.5 1.5-4.5-4.5L5.5 16 4 14.5 8.5 10z"></path></g></svg>
            </div>
          ))
        }
        { student.tutors.length === 0 &&
          <div style={{ color: 'grey', textAlign: 'center', marginTop: '10px', marginBottom: '20px' }}>
            Aucun
          </div>
        }
        <FileList entity="students" entityId={student.id} />
      </div>
      
    </div>
  );
}
