import React, { useState } from 'react';

import { Link } from '../../hooks/router';
import { Input, Select, useUpload } from '../../hooks/form';

import { Head } from '../../components/head';
import { List, useList } from '../../hooks/list';
import Api from '../../services/Api';
import { useAuth } from '../../hooks/auth/Auth';

export default function RegistrationsList() {
  const auth = useAuth();
  const { filters, items, isLoading, load, refresh } = useList('registrations', {
    search: '',
    program: null,
  });
  const [uploadState, setUploadState] = useState({ uploading: false, file: null, percent: 0 });
  const { start } = useUpload({ onUpload: (file) => {
    setUploadState({ file, uploading: true, percent: 0 });
    Api.upload(`/registrations-file`, file, ({ percent }) => {
      setUploadState(prevState => ({ ...prevState, percent }));
    }).then(response => {
        setUploadState({ uploading: false, file: null, percent: 0 });
        refresh();
        alert('Importé !')
      }).catch(error => {
        console.log(error);
        setUploadState({ uploading: false, file: null, percent: 0 });
        alert('Une erreur est survenue : ' + error.message);
      });
  } });

  return (
    <div className="list-page">
      <Head title="Inscriptions">

        { 
        (
          auth?.user?.email === 'geoffroydeboissieu@collegedesbernardins.fr'
          ||
          auth?.user?.email === 'qdeneuve@icloud.com'
        ) &&
          <button onClick={() => start()} className="btn primary">/!\ IMPORT EXCEL /!\</button>
        }
        <Link to="createRegistration" className="btn">Nouvelle inscription et/ou achat de produit</Link>
      </Head>
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
        <div className="filter" style={{ minWidth: '160px' }}>
          <Select
            nullable
            load={async ({ page, search }) => {
              const response = await Api.get('/students' , { page, search });
              return { items: response.data };
            }}
            {...filters.field('student')}
            noSelectionMessage="Tous les étudiants"
          >
              {(student, onSelect) => (
                <div key={student.id} onClick={onSelect}>
                  {student.user.firstname} {student.user.lastname.toUpperCase()}
                </div>
              )}
          </Select>
        </div>
        <div className="filter" style={{ minWidth: '320px' }}>
          <Select noSearch {...filters.field('program')}>
            <option value={null}>Tous les départements bénéficiaires</option>
            <option value="cp">Cours Publics</option>
            <option value="e-learning">E-learning</option>
            <option value="fnd">FND</option>
            <option value="issr">ISSR</option>
            <option value="fr">FEB</option>
            <option value="ccdej">CCDEJ</option>
            <option value="fcf">FCF</option>
            <option value="other">Autre</option>
          </Select>
        </div>
      </form>
      <List onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Étudiant' }, { name: 'Statut' }, { name: 'Cours/Package' }]}>
        { items.map(registration => (
          <Link key={registration.id} className="item" to="registration" params={{ id: registration.id }}>
            <div className="prop">
              {registration.student ? `${registration.student.user.firstname} ${(registration.student.user.lastname ? registration.student.user.lastname.toUpperCase() : '')}` : 'n/a'}
            </div>
            <div className="prop">
              {{
                'pending': 'En attente',
                'validated': 'Validée',
                'pendingPayment': 'En attente du paiement',
                'canceled': 'Annulée',
                'draft': 'Broullion',
              }[registration.status]}
            </div>
            <div className="prop">
              { registration.classe &&
                <>
                  {registration.classe.number} • {registration.classe.name}
                </>
              }
              { registration.package &&
                <>
                  {registration.package.name}
                </>
              }
            </div>
          </Link>
        ))}
      </List>
    </div>
  );
};