import React, { useState } from 'react';

import './user.scss';
import { Link, useRouter } from '../hooks/router';
import { useAuth } from '../hooks/auth/Auth';

export default function UserLayout({ children }) {
  const auth = useAuth();
  const router = useRouter();
  const [showAccountMenu, setShowAccountMenu] = useState(false);

  const isTest = window && ( window.location.host.indexOf('preprod.collegedesbernardins.fr') != -1 || window.location.host.indexOf('localhost') != -1);
  
  return (
    <div className={`user-layout${isTest ? ' is-test' : ''}`}>
      { isTest &&
        <div style={{ padding: '16px', textAlign: 'center', background: '#cbf1cb', color: 'green', height: '51px', fontWeight: 700 }}>
          PLATEFORME DE TEST
        </div>
      }
      <div className="wrapper">
        <div className="corp">
          <div className="menu">
            <Link to="dashboard" className="logo">
              <svg viewBox="0 0 188 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_153_1062)">
                <mask id="mask0_153_1062" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="188" height="55">
                <path d="M188 0H0V55H188V0Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_153_1062)">
                <path d="M70.6257 36.2334V36.3032C70.6257 41.7049 68.3716 43.5943 64.1373 43.5943H58.4766L58.5148 41.0582V21.087L58.4766 18.551H63.9972C68.1997 18.551 70.3647 21.0363 70.3647 24.9672V25.0369C70.3647 27.8012 69.7279 29.8363 66.9644 30.7113V30.8571C69.6006 31.453 70.6066 33.4819 70.6066 36.2525M63.2012 28.6951H63.647C65.3534 28.6951 65.8374 27.2623 65.8374 25.4363V25.3602C65.8374 23.6104 65.3471 22.5199 63.5451 22.5199H63.2012V28.6951ZM65.8692 36.1383C65.8692 34.2046 65.2325 32.9683 63.5833 32.9683H63.2012V39.4605H63.5132C65.3152 39.4605 65.8692 38.5793 65.8692 36.2334V36.1383Z" fill="currentColor"/>
                <path d="M76.9753 33.1458V39.283H82.0438V43.5942H72.2188L72.2506 41.0392V21.087L72.2188 18.532H81.9419V22.7735H76.9753V28.3781H80.9677V33.1458H76.9753Z" fill="currentColor"/>
                <path d="M96.312 43.5943H91.3199L88.6137 35.4029H88.2571V41.0392V43.5752H83.4688L83.5006 41.0392V21.087L83.4688 18.551H89.4033C94.7201 18.551 95.5415 22.0444 95.5479 26.4254V27.4588C95.5479 30.4894 95.0958 33.0761 93.1855 34.4329L96.312 43.5943ZM90.8614 26.4761C90.8614 24.0922 90.4412 22.7608 88.4991 22.7608H88.2571V31.1931H88.5054C90.7277 31.1931 90.8678 29.3735 90.8678 27.4778L90.8614 26.4761Z" fill="currentColor"/>
                <path d="M110.103 41.0392L110.141 43.5942H104.652L101.908 30.147V41.0392L101.94 43.5942H97.5781L97.61 41.0392V21.087L97.5781 18.532H103.067L105.811 31.9792V21.087L105.773 18.532H110.141L110.103 21.087V41.0392Z" fill="currentColor"/>
                <path d="M120.542 40.7539H116.085L115.722 43.5942H111.207L114.817 18.532H122.006L125.687 43.5942H120.892L120.542 40.7539ZM120.026 36.5821L118.396 23.268H118.288L116.607 36.5821H120.026Z" fill="currentColor"/>
                <path d="M139.548 43.5943H134.556L131.85 35.4029H131.487V41.0392L131.525 43.5752H126.711L126.749 41.0392V21.087L126.711 18.551H132.652C137.962 18.551 138.784 22.0444 138.796 26.4254V27.4588C138.796 30.4894 138.344 33.0761 136.428 34.4329L139.548 43.5943ZM134.091 26.4761C134.091 24.0922 133.677 22.7608 131.735 22.7608H131.487V31.1931H131.735C133.957 31.1931 134.091 29.3735 134.091 27.4778V26.4761Z" fill="currentColor"/>
                <path d="M152.958 26.977V35.1494C152.958 38.8266 152.194 40.9315 150.806 42.1615C149.591 43.1746 148.033 43.6872 146.451 43.5943H140.828L140.86 41.0583V21.0871L140.828 18.5511H146.451C148.039 18.4533 149.605 18.9662 150.825 19.984C152.213 21.2139 152.977 23.3188 152.977 26.9961M148.221 26.5776C148.221 24.8214 148.221 22.7736 146.177 22.7736H145.585V39.3909H146.177C148.119 39.3909 148.221 37.5332 148.221 35.5868V26.5776Z" fill="currentColor"/>
                <path d="M159.53 41.0392L159.562 43.5942H154.773L154.812 41.0392V21.087L154.773 18.532H159.562L159.53 21.087V41.0392Z" fill="currentColor"/>
                <path d="M174.195 41.0392L174.227 43.5942H168.738L165.994 30.147V41.0392L166.032 43.5942H161.664L161.696 41.0392V21.087L161.664 18.532H167.153L169.897 31.9792V21.087L169.859 18.532H174.227L174.195 21.087V41.0392Z" fill="currentColor"/>
                <path d="M187.996 36.7218C187.996 41.629 185.532 44.0128 181.813 44.0128C178.095 44.0128 175.669 41.6987 175.777 36.1258V35.2192H180.47V36.1385C180.47 38.5604 180.954 39.5748 182.03 39.5748C182.762 39.5748 183.303 39.0866 183.303 37.5396C183.326 36.7556 183.154 35.9782 182.803 35.276C182.452 34.5738 181.933 33.9685 181.291 33.5137L179.693 32.2837C177.146 30.3183 175.942 28.2514 175.942 25.0244C175.942 20.5039 178.407 18.0503 182.157 18.0503C185.767 18.0503 187.952 20.1552 187.85 25.7281V26.8186H183.303V25.7154C183.303 23.2935 182.851 22.4883 181.985 22.4883C181.119 22.4883 180.559 23.2935 180.559 24.5932C180.559 26.1022 181.253 27.1863 182.571 28.169L184.348 29.5004C186.392 31.0411 187.99 32.7973 187.99 36.7218" fill="currentColor"/>
                <path d="M59.7883 7.60811C59.4539 7.59254 59.1268 7.7087 58.8777 7.93145C58.5912 8.21676 58.4766 8.762 58.4766 9.83347V11.4946C58.4766 12.547 58.5912 13.0986 58.8777 13.3966C59.0024 13.508 59.1486 13.593 59.3073 13.6464C59.466 13.6998 59.634 13.7205 59.801 13.7072C59.9652 13.7225 60.1309 13.7027 60.2868 13.6491C60.4427 13.5956 60.5854 13.5095 60.7052 13.3966C60.9535 13.1366 61.1191 12.7182 61.1191 11.4946H60.3804C60.3804 12.5343 60.3804 13.0542 59.8201 13.0542C59.2598 13.0542 59.2534 12.5026 59.2534 11.7418V9.57353C59.2534 8.83808 59.2534 8.26114 59.8201 8.26114C60.3868 8.26114 60.3677 8.77468 60.3677 9.52915H61.1254C61.2018 8.96293 61.0633 8.38872 60.737 7.91877C60.4736 7.69591 60.1331 7.58442 59.7883 7.60811Z" fill="currentColor"/>
                <path d="M73.2235 7.60818C73.0516 7.59492 72.8787 7.61553 72.7148 7.66884C72.5509 7.72214 72.3992 7.8071 72.2683 7.91884C72.02 8.19146 71.8672 8.58455 71.8672 9.82086V11.482C71.8672 12.7056 72.02 13.0987 72.2747 13.384C72.5507 13.5888 72.8856 13.6994 73.2298 13.6994C73.574 13.6994 73.909 13.5888 74.185 13.384C74.446 13.1177 74.5988 12.7183 74.5988 11.482V9.82086C74.5988 8.61625 74.446 8.21682 74.185 7.91884C74.0545 7.80445 73.9018 7.71788 73.7365 7.66445C73.5711 7.61102 73.3965 7.59187 73.2235 7.60818ZM73.2235 13.0289C72.644 13.0289 72.644 12.3949 72.644 11.7165V9.57359C72.644 8.86985 72.644 8.2612 73.2235 8.2612C73.8029 8.2612 73.8029 8.92691 73.8029 9.57359V11.7292C73.8029 12.3632 73.8029 13.0416 73.2235 13.0416" fill="currentColor"/>
                <path d="M85.4492 13.574H87.4741V12.9274H86.2133L86.2197 7.72852H85.4492L85.4556 8.14696L85.4492 13.574Z" fill="currentColor"/>
                <path d="M98.0195 13.574H100.044V12.9274H98.7773L98.7836 7.72852H98.0195V8.14696V13.574Z" fill="currentColor"/>
                <path d="M111.475 7.22769L110.609 6.7395H111.698L112.214 7.22769H111.475Z" fill="currentColor"/>
                <path d="M111.475 7.22769L110.609 6.7395H111.698L112.214 7.22769H111.475Z" fill="currentColor"/>
                <path d="M110.582 13.555H112.613V12.9083H111.346V10.9366H112.346V10.1757H111.346V8.33713H112.588V7.70312H110.582L110.588 8.12791L110.582 13.555Z" fill="currentColor"/>
                <path d="M124.642 7.60813C124.475 7.59874 124.307 7.62247 124.148 7.67796C123.99 7.73346 123.844 7.81961 123.719 7.93148C123.439 8.21678 123.324 8.76202 123.324 9.83349V11.4946C123.324 12.547 123.439 13.0986 123.719 13.3966C123.968 13.6132 124.293 13.7246 124.623 13.7073C124.781 13.7171 124.94 13.6946 125.088 13.6412C125.237 13.5878 125.374 13.5045 125.489 13.3966L125.546 13.3395H125.674L125.922 13.5361H125.967V10.7338H124.801V11.3107H125.203V11.9447C125.203 12.5217 125.203 13.0669 124.642 13.0669C124.082 13.0669 124.076 12.4329 124.076 11.7545V9.57355C124.076 8.81274 124.076 8.26116 124.642 8.26116C125.209 8.26116 125.19 8.743 125.19 9.52917H125.929C125.929 8.43868 125.737 8.11534 125.534 7.93148C125.413 7.81555 125.268 7.72629 125.111 7.66949C124.953 7.61269 124.784 7.58962 124.617 7.60179" fill="currentColor"/>
                <path d="M136.859 13.574H138.891V12.9274H137.623V10.9619H138.623V10.1948H137.623V8.35618H138.865V7.72852H136.859L136.866 8.14696L136.859 13.574Z" fill="currentColor"/>
                <path d="M157.368 13.5804H158.546C158.893 13.6031 159.235 13.4918 159.501 13.2698C159.687 13.0519 159.825 12.7978 159.906 12.5237C159.987 12.2497 160.01 11.9617 159.973 11.6784V9.63058C160.01 9.34675 159.987 9.05836 159.908 8.78344C159.828 8.50852 159.691 8.25301 159.508 8.03288C159.238 7.81269 158.894 7.70378 158.546 7.72856H157.355V13.5804H157.368ZM158.489 12.9464H158.132V8.34989H158.489C159.221 8.34989 159.221 9.08533 159.221 9.5228V11.7799C159.221 12.192 159.221 12.9528 158.489 12.9528" fill="currentColor"/>
                <path d="M170.852 13.574H172.883V12.9274H171.616V10.9619H172.615V10.1948H171.616V8.35618H172.857V7.72852H170.852L170.858 8.14696L170.852 13.574Z" fill="currentColor"/>
                <path d="M183.517 11.9067C183.458 12.4191 183.604 12.934 183.924 13.3396C184.043 13.4537 184.184 13.5419 184.338 13.5987C184.493 13.6555 184.658 13.6795 184.822 13.6692C185.682 13.6692 186.153 13.0986 186.153 12.0589C186.177 11.7371 186.113 11.4147 185.967 11.1265C185.821 10.8383 185.599 10.5951 185.325 10.4231L184.886 10.0871C184.704 9.97272 184.555 9.8145 184.451 9.62716C184.348 9.43983 184.293 9.22947 184.293 9.01565C184.293 8.4704 184.599 8.27386 184.86 8.27386C185.408 8.27386 185.408 8.99029 185.408 9.30095V9.35801H186.121V9.30095C186.184 8.79905 186.049 8.29244 185.745 7.88712C185.634 7.78083 185.502 7.69928 185.357 7.64789C185.212 7.59651 185.058 7.57648 184.905 7.58913C184.052 7.58913 183.561 8.14706 183.561 9.12343C183.547 9.44904 183.619 9.7726 183.77 10.0618C183.921 10.3511 184.145 10.5959 184.421 10.7718L184.816 11.0762C185.005 11.2099 185.158 11.3881 185.261 11.5949C185.365 11.8018 185.415 12.0308 185.408 12.2618C185.408 12.8958 185.032 12.9845 184.873 12.9845C184.268 12.9845 184.268 12.2047 184.268 11.9067H183.517Z" fill="currentColor"/>
                </g>
                <mask id="mask1_153_1062" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="50" height="55">
                <path d="M0 0V55H33.2574C36.7493 55.0123 40.1553 53.922 42.9857 51.8857C45.8162 49.8494 47.9253 46.972 49.01 43.667C50.0948 40.3621 50.0992 36.7998 49.0226 33.4922C47.9461 30.1846 45.8441 27.302 43.0187 25.2588C42.9162 25.1833 42.8314 25.0865 42.7703 24.975C42.7091 24.8636 42.6731 24.7403 42.6646 24.6136C42.6561 24.487 42.6754 24.36 42.7212 24.2414C42.7669 24.1229 42.8381 24.0158 42.9296 23.9274C44.9151 21.997 46.2801 19.5229 46.8514 16.8189C47.4226 14.115 47.1744 11.303 46.1383 8.73969C45.1021 6.17638 43.3246 3.97727 41.0314 2.42128C38.7381 0.865304 36.0324 0.0225764 33.2574 0L0 0Z" fill="white"/>
                </mask>
                <g mask="url(#mask1_153_1062)">
                <path d="M49.8319 0H0V54.9937H49.8319V0Z" fill="url(#paint0_linear_153_1062)"/>
                </g>
                </g>
                <defs>
                  <linearGradient id="paint0_linear_153_1062" x1="-0.660181" y1="26.9121" x2="49.1718" y2="26.9121" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0"/>
                    <stop offset="0.03" stopColor="currentColor" stopOpacity="0"/>
                    <stop offset="0.63" stopColor="currentColor"/>
                    <stop offset="1" stopColor="currentColor"/>
                  </linearGradient>
                  <clipPath id="clip0_153_1062">
                  <rect width="188" height="55" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </Link>
            <Link to="classes" className={router.route.url.match(/^\/classe/) ? ' active' : ''}>
              <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4.75 6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V14.25C19.25 15.3546 18.3546 16.25 17.25 16.25H6.75C5.64543 16.25 4.75 15.3546 4.75 14.25V6.75Z"></path>
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M15.25 19.25L12 17.25L8.75 19.25"></path>
              </svg>
              Cours
            </Link>
            <Link to="products" className={router.route.url.match(/^\/product/) ? ' active' : ''}>
              <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M7.75 7.75H19.25L17.6128 14.7081C17.4002 15.6115 16.5941 16.25 15.666 16.25H11.5395C10.632 16.25 9.83827 15.639 9.60606 14.7618L7.75 7.75ZM7.75 7.75L7 4.75H4.75"></path>
                <circle cx="10" cy="19" r="1" fill="currentColor"></circle>
                <circle cx="17" cy="19" r="1" fill="currentColor"></circle>
              </svg>
              Produits
            </Link>
            <Link to="packages" className={router.route.url.match(/^\/package/) ? ' active' : ''}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5.75 8.87 12 12.25M5.75 8.87v7L12 19.25M5.75 8.87l3.125-1.56m9.375 1.56L12 5.75 8.875 7.31m9.375 1.56v7L12 19.25m6.25-10.38-3.125 1.69M12 12.25v7m0-7 3.125-1.69m0 0-6.25-3.25"></path>
              </svg>
              Packages
            </Link>
            <Link to="students" className={router.route.url.match(/^\/student/) ? ' active' : ''}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.25 7C14.25 8.24264 13.2426 9.25 12 9.25C10.7574 9.25 9.75 8.24264 9.75 7C9.75 5.75736 10.7574 4.75 12 4.75C13.2426 4.75 14.25 5.75736 14.25 7Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M12 9.75C8.6 9.75 7.75 11.5 7.75 14.25H9.75V17.25C9.75 18.3546 10.6454 19.25 11.75 19.25H12.25C13.3546 19.25 14.25 18.3546 14.25 17.25V14.25H16.25C16.25 11.5 15.4 9.75 12 9.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
              Étudiants
            </Link>
            <Link to="registrations" className={router.route.url.match(/^\/registration/) ? ' active' : ''}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 6C6.5 6.27614 6.27614 6.5 6 6.5C5.72386 6.5 5.5 6.27614 5.5 6C5.5 5.72386 5.72386 5.5 6 5.5C6.27614 5.5 6.5 5.72386 6.5 6Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M6.5 12C6.5 12.2761 6.27614 12.5 6 12.5C5.72386 12.5 5.5 12.2761 5.5 12C5.5 11.7239 5.72386 11.5 6 11.5C6.27614 11.5 6.5 11.7239 6.5 12Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M6.5 18C6.5 18.2761 6.27614 18.5 6 18.5C5.72386 18.5 5.5 18.2761 5.5 18C5.5 17.7239 5.72386 17.5 6 17.5C6.27614 17.5 6.5 17.7239 6.5 18Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M9.75 6H18.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M9.75 12H18.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M9.75 18H18.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
              Inscriptions
            </Link>
            <div className="category">
              Comptabilité
            </div>
            <Link to="bills" className={router.route.url.match(/^\/bill/) ? ' active' : ''}>
              Factures
            </Link>
            <Link to="donations" className={router.route.url.match(/^\/donation/) ? ' active' : ''}>
              Dons
            </Link>
            <Link to="payers" className={router.route.url.match(/^\/payer/) ? ' active' : ''}>
              Payeurs
            </Link>
            <div className="category">
              Autres
            </div>
            <Link to="professors" className={router.route.url.match(/^\/professor/) ? ' active' : ''}>
              Professeurs
            </Link>
            <Link to="tutors" className={router.route.url.match(/^\/tutor/) ? ' active' : ''}>
              Tuteurs
            </Link>
            <div className="separator" />
            <Link style={{ }} to="statistics" className={router.route.url.match(/^\/statistics/) ? ' active' : ''}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.25 8.75H5.75C5.19772 8.75 4.75 9.19772 4.75 9.75V15.25C4.75 15.8023 5.19772 16.25 5.75 16.25H6.25C6.80228 16.25 7.25 15.8023 7.25 15.25V9.75C7.25 9.19772 6.80228 8.75 6.25 8.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M12.25 4.75H11.75C11.1977 4.75 10.75 5.19772 10.75 5.75V15.25C10.75 15.8023 11.1977 16.25 11.75 16.25H12.25C12.8023 16.25 13.25 15.8023 13.25 15.25V5.75C13.25 5.19772 12.8023 4.75 12.25 4.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M4.75 19.25H19.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M18.25 8.75H17.75C17.1977 8.75 16.75 9.19772 16.75 9.75V15.25C16.75 15.8023 17.1977 16.25 17.75 16.25H18.25C18.8023 16.25 19.25 15.8023 19.25 15.25V9.75C19.25 9.19772 18.8023 8.75 18.25 8.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
              Statistiques
            </Link>
            { 
              (
                auth?.user?.email === 'geoffroydeboissieu@collegedesbernardins.fr'
                ||
                auth?.user?.email === 'qdeneuve@icloud.com'
              ) &&
              <Link style={{ marginBottom: '8px' }} to="admins" className={router.route.url.match(/^\/admins/) ? ' active' : ''}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.75 16.25V5.75L9 11.25L12 5.75L15 11.25L19.25 5.75V16.25C19.25 16.25 18 18.25 12 18.25C6 18.25 4.75 16.25 4.75 16.25Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
                Admins
              </Link>
            }
            <button className="account" onClick={() => setShowAccountMenu(prevShowAccountMenu => !prevShowAccountMenu)} style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {auth?.user?.firstname} {auth?.user?.lastname}
              { showAccountMenu &&
                <div className="sub">
                  <button onClick={() => auth.signout()}>
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M15.75 8.75L19.25 12L15.75 15.25"></path>
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M19 12H10.75"></path>
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M15.25 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H15.25"></path>
                    </svg>
                    Déconnexion
                  </button>
                </div>
              }
            </button>
          </div>
          <div className="page">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
