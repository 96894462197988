import React from 'react';

import Link from '../../hooks/router/Link';

import './errorPage.scss';

export default function ErrorPage({ code, message }) {
  return (
    <div className="not-found">
      <div className="content">
        <div className="error">{code || 404}</div>
        <div className="message">{message || 'Page non trouvée'}</div>
        <Link to="dashboard">Retourner à l'accueil</Link>
      </div>
    </div>
  );
};
