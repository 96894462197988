import React from 'react';
import moment from 'moment';

import { Link } from '../../../hooks/router';
import { Input } from '../../../hooks/form';

import { List, useList } from '../../../hooks/list';

export default function StudentRessourceRegistrations({ student }) {
  const { filters, items, isLoading, load } = useList(`students/${student.id}/registrations`, {
    search: '',
  });

  return (
    <div className="list-page">
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
      </form>
      <List onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Numéro', sort: 'number' }, { name: 'Créée le', sort: 'createdAt' }, { name: 'Statut' }, { name: 'Cours/Package' }]}>
        { items.map(registration => (
          <Link key={registration.id} className="item" to="registration" params={{ id: registration.id }}>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              <span>
                #{registration.number}
              </span>
            </div>
            <div className="prop">
              {moment(registration.createdAt).format('DD/MM/YYYY HH:mm')}
            </div>
            <div className="prop">
              {{
                'pending': 'En attente',
                'validated': 'Validée',
                'pendingPayment': 'En attente du paiement',
                'canceled': 'Annulée',
                'draft': 'Broullion',
              }[registration.status]}
            </div>
            { registration.classe &&
              <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
                <span>
                  {registration.classe.number} • {registration.classe.name}
                </span>
              </div>
            }
            { registration.package &&
              <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
                <span>
                  {registration.package.name}
                </span>
              </div>
            }
          </Link>
        ))}
      </List>
    </div>
  );
};