import React from 'react';
import moment from 'moment';

import { Link } from '../../hooks/router';

import { Head } from '../../components/head';

export default function DonationModal({ donation }) {
  return (
    <div style={{ width: '100%' }}>
      <Head title={`Dons #${donation.id}`} noTabs />
      <div style={{ marginTop: '0px', padding: '20px', background: '#E9E9E9', fontSize: '1.3em', borderBottom: '1px solid #ccc' }}>
        <strong>{(donation.amount).toFixed(2)}€</strong>
        <br/>
        <small style={{ fontSize: '0.7em' }}>Créée le {moment(donation.createdAt).format('DD/MM/YYYY HH:mm:ss')}</small>
      </div>
      <div style={{ padding: '20px' }}>
        <h3 style={{ marginTop: '0px' }}>
          Paiement
        </h3>
        <div style={{ borderRadius: '8px', border: '1px solid #ccc', marginTop: '10px', padding: '20px', display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <strong>{donation.amount}€</strong>{donation.stripePaymentIntentId ? <>&nbsp;• <a href={`https://dashboard.stripe.com/payments/${donation.stripePaymentIntentId}`} target="_blank" rel="noreferrer noopener">{donation.stripePaymentIntentId}</a></> : ''}
            <br/>
            <small>
              {donation.status === 'paid' ? 'Payé' : 'Créé'} le {moment(donation.createdAt).format('DD/MM/YYYY HH:mm:ss')} par&nbsp; Stripe
            </small>
          </div>
        </div>
        <h3 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Étudiant
        </h3>
        <Link to="student" params={{ id: donation.student.id }} style={{ display: 'block', padding: '20px', borderRadius: '8px', border: '1px solid #ccc' }}>
          { donation.student.type === 'company' ? (
            <>{donation.student.companyName}</>
          ) : (
            <>{donation.student.user.firstname} {donation.student.user.lastname.toUpperCase()}</>
          )}
          <br/>
          {donation.student.user.email}
        </Link>
        <h3 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Adresse
        </h3>
        <div>
          {donation.address}
          <br />
          {donation.city}
          <br />
          {donation.zipcode}
          <br />
          {donation.country}
          <br />
          {donation.mobilePhoneNumber}
        </div>
      </div>
    </div>
  )
}