import React, { useEffect, useState } from 'react';

import { Head } from '../../components/head';
import { Input, Select } from '../../hooks/form';
import { useList, List } from '../../hooks/list';
import { useModal } from '../../hooks/modal/Modal';

export default function AddClasseModal({ onAdded, many, excludeIds, excludeMooc = false }) {
  const modal = useModal();
  const { filters, items, isLoading, load } = useList('classes', {
    search: '',
    year: 2023,
    period: null,
    program: 'e-learning',
    excludeIds,
    excludeMooc,
  }, true);
  const [selectedClasses, setSelectedClasses] = useState([]);

  function select(classe) {
    setSelectedClasses(prevClasses => [...prevClasses, classe]);
  }

  useEffect(() => {
    if ((many === 'two' && selectedClasses.length === 1)  || (many === 'three' && selectedClasses.length === 2)) {
      onAdded(selectedClasses);
      modal.close();
    }
  }, [selectedClasses]);

  return (
    <div className="list-page" style={{ width: '100%', overflow: 'hidden' }}>
      <Head title="Choisissez un cours" />
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
        <div className="filter" style={{ minWidth: '120px' }}>
          <Select noSearch {...filters.field('year')}>
            <option value={null}>Toutes années</option>
            <option value={2021}>2021 - 2022</option>
            <option value={2022}>2022 - 2023</option>
            <option value={2023}>2023 - 2024</option>
            <option value={2024}>2024 - 2025</option>
            <option value={2025}>2025 - 2026</option>
            <option value={2026}>2026 - 2027</option>
            <option value={2027}>2027 - 2028</option>
            <option value={2028}>2028 - 2029</option>
            <option value={2029}>2029 - 2030</option>
            <option value={2030}>2030 - 2031</option>
            <option value={2031}>2031 - 2032</option>
          </Select>
        </div>
        <div className="filter" style={{ minWidth: '120px' }}>
          <Select noSearch {...filters.field('period')}>
            <option value={null}>Toutes périodes</option>
            <option value="annual">Annuel</option>
            <option value="first-semester">1er semestre</option>
            <option value="second-semester">2nd semestre</option>
          </Select>
        </div>
      </form>
      <List scrollableClassName=".component-modal" onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Nom', sort: 'name' }, { name: 'Séances' }]}>
        { items.map(classe => (
          <div key={classe.id} className={`item${selectedClasses.findIndex(sClasse => sClasse.id === classe.id) !== -1 ? ' selected' : ''}`} onClick={() => select(classe)} >
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              <span>
                {classe.number} • {classe.name}
              </span>
            </div>
            <div className="prop">
              {classe.period ? { annual: 'Annuel', 'first-semester': '1er semestre', 'second-semester':'2nd semestre'}[classe.period] : ''} {classe.period && classe.year && <>•</>} {classe.year ? `${classe.year} - ${classe.year+1}` : ''}
            </div>
          </div>
        ))}
      </List>
    </div>
  )
}