import React, { useEffect, useState } from 'react';

import { Route } from '../../hooks/router';

import { Head } from '../../components/head';
import { Loader } from '../../components/loader';
import useRessource from '../../hooks/ressource/useRessource';
import Api from '../../services/Api';
import { useNotification } from '../../hooks/notification/Notification';

export default function RegistrationRessource({ route }) {
  const notification = useNotification();
  const { registration, isLoading, set } = useRessource('registration', route.params.id);
  const [isClasseJoin, setIsClasseJoin] = useState(null);

  useEffect(() => {
    Api.get(`/registrations/${route.params.id}/joined`)
      .then(response => {
        setIsClasseJoin(response.data.joined);
      })
      .catch(error => {
        console.log(error);
        alert('Une erreur est survenue');
      });
  }, [route.params.id])

  function onValidate() {
    if (window.confirm('Êtes vous certains de valider l\'inscription ? La facture sera éditée et envoyée à l\'étudiant.')) {
      Api.post(`/registrations/${registration.id}/validate`)
        .then(response => {
          set(response.data);
          if (registration.classe && registration.classe.type === 'public') {
            notification.notify('L\'inscription a été validée ! L\'étudiant a été ajouté à sa classe.');
          } else {
            notification.notify('L\'inscription a été validée ! Le paiement est en attente.');
          }
        })
        .catch(error => {
          console.log(error);
          alert('Une erreur est survenue');
        });
    }
  }

  function onCancel() {
    if (window.confirm('Êtes vous certains d\'annuler l\'inscription ?')) {
      Api.post(`/registrations/${registration.id}/cancel`)
        .then(response => {
          notification.notify('L\'inscription a été annulée !');
          set(response.data);
        })
        .catch(error => {
          alert('Une erreur est survenue');
        });
    }
  }

  function onJoinClasse() {
    if (window.confirm('Êtes vous certains d\'ajouter l\'étudiant sans valider le paiement ?')) {
      Api.post(`/registrations/${registration.id}/join`)
        .then(response => {
          notification.notify('L\'étudiant a été ajouté aux cours !');
          setIsClasseJoin(true);
        })
        .catch(error => {
          alert('Une erreur est survenue');
        });
    }
  }

  function onCreateBill() {
    if (window.confirm('Êtes vous certains de générer les factures ?')) {
      Api.post(`/registrations/${registration.id}/generate-bills`)
        .then(response => {
          notification.notify('Les factures ont été générées !');
          set(response.data);
        })
        .catch(error => {
          alert('Une erreur est survenue');
        });
    }
  }

  return (
    <div className="ressource-page">
      <Head prefix="Inscription" title={`#${registration.number}`} isLoading={isLoading} noTabs>
        { isClasseJoin === false &&
          <button className="btn" onClick={() => onJoinClasse()}>Ajouter l'étudiant au{registration.classe ? '' : 'x'} cours</button>
        }
        { (registration.status === 'pending' || registration.status === 'draft') &&
          <button className="btn" onClick={() => onValidate()}>Valider l'inscription</button>
        }
        { registration.status !== 'canceled' &&
          <button className="btn red" onClick={() => onCancel()}>Annuler l'inscription</button>
        }
        { registration.noBillCreation === true &&
          <button className="btn" onClick={() => onCreateBill()}>Générer les factures</button>
        }
      </Head>
      { isLoading ? <Loader /> : <Route route={route.subRoute} registration={registration} setRegistration={set} isClasseJoin={isClasseJoin} /> }
    </div>
  );
}
